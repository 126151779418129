import React from 'react';
import styles from './button.module.scss';

interface IButtonProps {
	onClick: () => void;
	title: string;
	disabled?: boolean;
}
export const Button = ({ onClick, title }: IButtonProps) => {
	return (
		<div className={styles.button} onClick={onClick}>
			<p>{title}</p>
		</div>
	);
};
