import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import styles from '../eventsAll.module.scss';
import scs from './newEvents.module.scss';
import { EventsList } from '../../EventsList';
import { PromoCard } from '../../EventsItems';

interface INewEventsProps {
	title?: string;
}
export const NewEvents = ({ title }: INewEventsProps) => {
	const events = useSelector((state: IStoreState) => state.events.events);

	return (
		<Fragment>
			{title && (
				<p className={styles.heading}>
					{title} ({events.length})
				</p>
			)}

			{events.length > 0 ? (
				<EventsList>
					{events.map((event, index) => (
						<PromoCard key={index} {...event} />
					))}
				</EventsList>
			) : (
				<div className={scs.empty}>{title} отсутствуют</div>
			)}
		</Fragment>
	);
};
