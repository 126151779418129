import { createSlice } from '@reduxjs/toolkit';
import { ICheckOffline } from './interfaces';

const initialState: ICheckOffline = {
	isOffline: !navigator.onLine,
};

const errors = createSlice({
	name: 'errors',
	initialState,
	reducers: {
		setOffline: (state) => {
			state.isOffline = true;
		},
		setOnline: (state) => {
			state.isOffline = false;
			window.location.reload();
		},
	},
});

export const { setOffline, setOnline } = errors.actions;
export default errors.reducer;
