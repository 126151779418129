import React from 'react';
import styles from './settingButton.module.scss';
import { DropButton } from 'components/Common/DropButton';
import { ReactComponent as DesktopSVG } from 'assets/images/setting/settingDesktop.svg';
import { ReactComponent as MobileSVG } from 'assets/images/setting/settingMobile.svg';
import classNames from 'classnames';

interface ISettingButtonProps {
	isMobile?: boolean;
	onClick?: () => void;
	isOpen?: boolean;
}

export const SettingButton = ({ isMobile, onClick = () => {}, isOpen }: ISettingButtonProps) => {
	return (
		<DropButton
			icon={isMobile ? <MobileSVG /> : <DesktopSVG />}
			mix={classNames(styles.button, {
				[styles.button__opened]: isOpen,
			})}
			onClick={onClick}
		/>
	);
};
