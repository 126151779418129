import { request } from 'services/utils/request';
import { TDownTimesUpdateResult, TUpdateDownTimesPayload } from 'services/store/interfaces/downTimes';

export const updateDataDownTimes = async (payload: TUpdateDownTimesPayload): Promise<TDownTimesUpdateResult[]> => {
	return await request.post(
		'SupplierPortal.UpdateDowntimes',
		{
			...payload,
		},
		localStorage.token
	);
};

export const removeDataDownTimes = async (payload: TUpdateDownTimesPayload): Promise<TDownTimesUpdateResult[]> => {
	return await request.post(
		'SupplierPortal.UpdateDowntimes',
		{
			...payload,
			ActionType: '3',
		},
		localStorage.token
	);
};
