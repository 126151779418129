import React, { useState, useEffect } from 'react';
import { block1, block2 } from '../cookies';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';
import styles from './actions.module.scss';
import classNames from 'classnames';
import { IStoreState } from 'services/store/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { setDateCookie, setSettings } from 'services/store/cookies';

export const Actions = () => {
	const { settings } = useSelector((state: IStoreState) => state.cookies);
	const [params, setParams] = useState(settings);
	const dispatch = useDispatch();

	const setSetting = (value: '0' | '1') => {
		return () => setParams({ ...params, analytical: value });
	};

	const setAllParams = () => {
		dispatch(setSettings(params));
		dispatch(setDateCookie());

		window.scroll({ top: 0, behavior: 'smooth' });

		setTimeout(() => location.reload(), 1100);
	};

	useEffect(() => setParams(settings), [settings.analytical]);

	return (
		<div className={styles.actions}>
			<p dangerouslySetInnerHTML={{ __html: block1 }} />

			<div className={styles.actions_block}>
				<InputCheckbox isActive={true} mix={styles.actions_check} disabled />

				<div className={classNames(styles.actions_text, styles.actions_text__disable)}>Принять</div>

				<InputCheckbox isActive={false} mix={styles.actions_check} disabled />
				<div className={classNames(styles.actions_text, styles.actions_text__disable)}>Отклонить</div>
			</div>

			<p dangerouslySetInnerHTML={{ __html: block2 }} />

			<div className={styles.actions_block}>
				<InputCheckbox
					onChange={setSetting('1')}
					isActive={params.analytical === '1'}
					mix={styles.actions_check}
					isCookies
				/>
				<div className={styles.actions_text}>Принять</div>

				<InputCheckbox
					onChange={setSetting('0')}
					isActive={params.analytical === '0'}
					mix={styles.actions_check}
					isCookies
				/>
				<div className={styles.actions_text}>Отклонить</div>
			</div>

			<div className={styles.actions_save} onClick={setAllParams}>
				Подтвердить выбор настроек cookies
			</div>
		</div>
	);
};
