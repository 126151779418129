import React, { FC, PropsWithChildren } from 'react';
import styles from './callto.module.scss';

interface ICalltoProps {
	phone?: string;
	email?: string;
	type?: 'phone' | 'email';
}
export const Callto: FC<PropsWithChildren<ICalltoProps>> = ({ type = 'phone', email, phone, children }) => {
	return (
		<p className={styles.phone}>
			{type === 'phone' ? <a href={`tel:${phone}`}>{children}</a> : <a href={`mailto:${email}`}>{children}</a>}
		</p>
	);
};
