import { IStoreState, TFilterTypes } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import { AppDispatch } from 'services/store';
import {
	hideEventsFilter,
	hideGoodsFilter,
	hideMultiOrdersFilter,
	hideOrderFilter,
	hideOrdersFilter,
	hideReportsAllGoodsFilter,
	hideReportsCurrentFilter,
	hideReportsPlanningFilter,
	hideReportsQualityFilter,
	showEventsFilter,
	showGoodsFilter,
	showMultiOrdersFilter,
	showOrderFilter,
	showOrdersFilter,
	showReportsAllGoods,
	showReportsCurrent,
	showReportsPlanning,
	showReportsQuality,
} from 'services/store/filter';

export const mapSetTitle = {
	goods: 'товаров',
	orders: 'заказов',
	order: 'товаров',
	events: 'событий',
	multiOrders: 'товаров',
	allGoods: 'заказов',
	quality: 'заказов',
	current: 'заказов',
	planning: '',
	notifications: '',
	divide: '',
	downTimes: '',
};

export const mapIsShow = (type: TFilterTypes | 'downTimes') => {
	const {
		isShowOrders,
		isShowOrder,
		isShowEvents,
		isShowMultiOrders,
		isShowGoods,
		isShowReportsQuality,
		isShowReportsCurrent,
		isShowReportsAllGoods,
		isShowReportsPlanning,
	} = useSelector((state: IStoreState) => state.filter);

	return {
		orders: isShowOrders,
		order: isShowOrder,
		goods: isShowGoods,
		events: isShowEvents,
		multiOrders: isShowMultiOrders,
		notifications: isShowEvents,
		divide: isShowMultiOrders,
		allGoods: isShowReportsAllGoods,
		quality: isShowReportsQuality,
		current: isShowReportsCurrent,
		planning: isShowReportsPlanning,
		downTimes: false,
	}[type];
};

export const mapShow = (dispatch: AppDispatch) => ({
	orders: () => dispatch(showOrdersFilter()),
	order: () => dispatch(showOrderFilter()),
	goods: () => dispatch(showGoodsFilter()),
	events: () => dispatch(showEventsFilter()),
	multiOrders: () => dispatch(showMultiOrdersFilter()),
	notifications: () => dispatch(showEventsFilter()),
	allGoods: () => dispatch(showReportsAllGoods()),
	quality: () => dispatch(showReportsQuality()),
	current: () => dispatch(showReportsCurrent()),
	planning: () => dispatch(showReportsPlanning()),
	divide: () => {},
	downTimes: () => {},
});

export const mapHide = (dispatch: AppDispatch) => ({
	orders: () => dispatch(hideOrdersFilter()),
	order: () => dispatch(hideOrderFilter()),
	goods: () => dispatch(hideGoodsFilter()),
	events: () => dispatch(hideEventsFilter()),
	multiOrders: () => dispatch(hideMultiOrdersFilter()),
	notifications: () => dispatch(hideEventsFilter()),
	allGoods: () => dispatch(hideReportsAllGoodsFilter()),
	quality: () => dispatch(hideReportsQualityFilter()),
	current: () => dispatch(hideReportsCurrentFilter()),
	planning: () => dispatch(hideReportsPlanningFilter()),
	divide: () => {},
	downTimes: () => {},
});
