import React from 'react';
import { ReactComponent as LogoutSVG } from 'assets/images/sidebar/signout.svg';
import styles from '../userMenu.module.scss';

interface ILogoutProps {
	title: string;
	onClick: () => void;
}
export const Logout = ({ title, onClick }: ILogoutProps) => {
	return (
		<p className={styles.list__item} onClick={onClick}>
			<LogoutSVG />

			<span className={styles.list__item_text}>{title}</span>
		</p>
	);
};
