import React, { FC } from 'react';
import styles from '../infoTable.module.scss';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TContentTable } from 'services/store/interfaces';
import { useIsSticky } from 'services/hooks/useIsSticky';
import { getIsActiveCheckboxHeader } from 'services/utils/helpers/getIsActiveCheckboxHeader';
import { mapActiveTDSList, mapHeadTable, mapRemoveAllActive, mapSetAllActive } from './map';
import { ReactComponent as DragSVG } from 'assets/images/grouping/drag.svg';

interface IHeadTableProps {
	type: TContentTable;
	dragEffect: boolean;
	onDrag: (value: null | HTMLElement) => void;
}

const FieldsForGrouping = ['TTTime', 'Datex', 'Checkx', 'WarehouseId'];
const isDragProp = (prop: string) => FieldsForGrouping.includes(prop);

export const HeadTable: FC<IHeadTableProps> = ({ type, dragEffect = false, onDrag }) => {
	const { activeList, IDS } = mapActiveTDSList();

	const [isSticky, scrollHeight, isFast] = useIsSticky(type);
	const { cancelOrder, ordersList } = useSelector((state: IStoreState) => state.orders);

	const isOrder = type === 'order';
	const isOrders = type === 'orders';
	const isGoods = type === 'goods';

	const isReports = ['allGoods', 'current', 'quality', 'planning'].includes(type);
	const isStickyHead = isSticky && ['orders', 'goods', 'allGoods', 'quality', 'current', 'planning'].includes(type);
	const dispatcher = useDispatch();

	return (
		<tr
			className={classNames(styles.tr, {
				[styles.tr__sticky]: isStickyHead,
				[styles.tr__fast]: isStickyHead && isFast,
				[styles.tr__hidden]: isOrders && cancelOrder.length > 0 && cancelOrder.length === ordersList.length,
			})}
			style={isSticky ? { top: `${scrollHeight}px` } : {}}
		>
			{!isOrder && !isReports && (
				<th className={classNames(styles.th, { [styles.th__dark]: isStickyHead, [styles.reports]: isReports })}>
					<InputCheckbox
						isActive={getIsActiveCheckboxHeader(activeList[type], IDS[type])}
						onChange={
							getIsActiveCheckboxHeader(activeList[type], IDS[type])
								? mapRemoveAllActive(type, dispatcher)
								: mapSetAllActive(type, dispatcher)
						}
					/>
				</th>
			)}

			{mapHeadTable(type).map(({ text, id, isShow }) => {
				return isShow ? (
					<th
						key={id}
						className={classNames(styles.th, {
							[styles.th__reports]: isReports,
							[styles.goods]: isGoods,
						})}
					>
						{isDragProp(id) && !dragEffect ? (
							<div className={styles.th__drag}>
								{text} <DragSVG />
							</div>
						) : isDragProp(id) && dragEffect ? (
							<div
								key={id}
								data-id={id}
								data-title={text}
								onDragStart={(e) => {
									onDrag(e.target as HTMLElement);
									(e.target as Element).classList.add(styles.selected);
								}}
								onDragEnd={(e) => {
									(e.target as Element).classList.remove(styles.selected);
									onDrag(null);
								}}
								draggable={true}
								className={classNames('dropzone', styles.th__drag)}
							>
								{text} <DragSVG />
							</div>
						) : (
							text
						)}
					</th>
				) : null;
			})}
		</tr>
	);
};
