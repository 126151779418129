import React from 'react';
import styles from './desktop.module.scss';
import classNames from 'classnames';
import useBreakpoint from 'services/hooks/useBreakpoint';

interface IDesktopProps {
	children?: React.ReactNode;
	type?: string;
}

export const Desktop = ({ children, type }: IDesktopProps) => {
	const breakpoints = useBreakpoint();
	const isDesktop = !['xs'].includes(breakpoints);

	return isDesktop ? (
		<div
			className={classNames(styles.desktop, {
				[styles.order]: type === 'order',
			})}
		>
			{children}
		</div>
	) : null;
};
