import React from 'react';
import styles from './settingList.module.scss';
import { GenericList } from 'components/Common/GenericList';
import { merge } from 'services/utils/js/merge';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TListSettings, TSetSettings, TSettings } from 'services/store/interfaces';
import {
	setDivideActive,
	setDivideNotActive,
	setEventsActive,
	setEventsNotActive,
	setGoodsActive,
	setGoodsNotActive,
	setMultiOrders,
	setMultiOrdersActive,
	setMultiOrdersNotActive,
	setOrderActive,
	setOrderNotActive,
	setOrdersActive,
	setOrdersNotActive,
	setReportAllGoodsNotActive,
	setReportsAllGoodsActive,
	setReportsCurrentActive,
	setReportsCurrentNotActive,
	setReportsPlanningActive,
	setReportsPlanningNotActive,
	setReportsQualityActive,
	setReportsQualityNotActive,
	setSettingsDivide,
	setSettingsEvents,
	setSettingsGoods,
	setSettingsOrder,
	setSettingsOrders,
	setSettingsReportsAllGoods,
	setSettingsReportsCurrent,
	setSettingsReportsPlanning,
	setSettingsReportsQuality,
} from 'services/store/setting';
import { Toggle } from 'components/Common/Toggle';

interface ISettingListProps {
	type?:
		| 'goods'
		| 'orders'
		| 'events'
		| 'order'
		| 'multiOrders'
		| 'notifications'
		| 'divide'
		| 'allGoods'
		| 'quality'
		| 'current'
		| 'planning'
		| 'downTimes';
}

const filter = (list: TSettings[]) => (prop: 'inSetting' | 'isShow') => list.filter((item) => item[prop]);

export const SettingList = ({ type = 'goods' }: ISettingListProps) => {
	const {
		listOrders,
		listGoods,
		listOrder,
		listEvents,
		listMultiOrder,
		listDivide,
		listReportsCurrent,
		listReportsQuality,
		listReportsAllGoods,
		listReportsPlanning,
	} = useSelector((state: IStoreState) => state.setting);

	const dispatcher = useDispatch();

	const orders = filter(listOrders)('inSetting');
	const order = filter(listOrder)('inSetting');
	const goods = filter(listGoods)('inSetting');
	const events = filter(listEvents)('inSetting');
	const notifications = filter(listEvents)('inSetting');
	const multiOrders = filter(listMultiOrder)('inSetting');
	const divide = filter(listDivide)('inSetting');
	const allGoods = filter(listReportsAllGoods)('inSetting');
	const current = filter(listReportsCurrent)('inSetting');
	const quality = filter(listReportsQuality)('inSetting');
	const planning = filter(listReportsPlanning)('inSetting');

	const ordersIsShow = filter(orders)('isShow');
	const orderIsShow = filter(order)('isShow');
	const goodsIsShow = filter(goods)('isShow');
	const eventsIsShow = filter(events)('isShow');
	const multiOrdersIsShow = filter(multiOrders)('isShow');
	const divideIsShow = filter(divide)('isShow');
	const allGoodsIsShow = filter(allGoods)('isShow');
	const currentIsShow = filter(current)('isShow');
	const qualityIsShow = filter(quality)('isShow');
	const planningIsShow = filter(planning)('isShow');

	const isAllActive = {
		orders: orders.length === ordersIsShow.length,
		order: order.length === orderIsShow.length,
		goods: goods.length === goodsIsShow.length,
		events: events.length === eventsIsShow.length,
		notifications: events.length === eventsIsShow.length,
		multiOrders: multiOrders.length === multiOrdersIsShow.length,
		divide: divide.length === divideIsShow.length,
		allGoods: allGoods.length === allGoodsIsShow.length,
		current: current.length === currentIsShow.length,
		quality: quality.length === qualityIsShow.length,
		planning: planning.length === planningIsShow.length,
		downTimes: false,
	};

	const getActiveSettings = (settings: TSettings[]) => (id: string) => {
		const currentSetting = settings.filter((setting) => setting.id === id)[0];
		return currentSetting.isShow;
	};

	const mapList = {
		orders,
		goods,
		order,
		events,
		multiOrders,
		notifications,
		divide,
		allGoods,
		current,
		quality,
		planning,
	};

	const mapSet = {
		orders: (id: string) => dispatcher(setSettingsOrders({ id })),
		goods: (id: string) => dispatcher(setSettingsGoods({ id })),
		order: (id: string) => dispatcher(setSettingsOrder({ id })),
		events: (id: string) => dispatcher(setSettingsEvents({ id })),
		multiOrders: (id: string) => dispatcher(setMultiOrders({ id })),
		notifications: (id: string) => dispatcher(setSettingsEvents({ id })),
		divide: (id: string) => dispatcher(setSettingsDivide({ id })),
		allGoods: (id: string) => dispatcher(setSettingsReportsAllGoods({ id })),
		current: (id: string) => dispatcher(setSettingsReportsCurrent({ id })),
		quality: (id: string) => dispatcher(setSettingsReportsQuality({ id })),
		planning: (id: string) => dispatcher(setSettingsReportsPlanning({ id })),
		downTimes: () => {},
	};

	const mapSetAllActive = {
		orders: () => dispatcher(setOrdersActive()),
		goods: () => dispatcher(setGoodsActive()),
		order: () => dispatcher(setOrderActive()),
		events: () => dispatcher(setEventsActive()),
		multiOrders: () => dispatcher(setMultiOrdersActive()),
		notifications: () => dispatcher(setEventsActive()),
		divide: () => dispatcher(setDivideActive()),
		allGoods: () => dispatcher(setReportsAllGoodsActive()),
		current: () => dispatcher(setReportsCurrentActive()),
		quality: () => dispatcher(setReportsQualityActive()),
		planning: () => dispatcher(setReportsPlanningActive()),
		downTimes: () => {},
	};

	const mapSetAllNotActive = {
		orders: () => dispatcher(setOrdersNotActive()),
		goods: () => dispatcher(setGoodsNotActive()),
		order: () => dispatcher(setOrderNotActive()),
		events: () => dispatcher(setEventsNotActive()),
		multiOrders: () => dispatcher(setMultiOrdersNotActive()),
		notifications: () => dispatcher(setEventsNotActive()),
		divide: () => dispatcher(setDivideNotActive()),
		allGoods: () => dispatcher(setReportAllGoodsNotActive()),
		current: () => dispatcher(setReportsCurrentNotActive()),
		quality: () => dispatcher(setReportsQualityNotActive()),
		planning: () => dispatcher(setReportsPlanningNotActive()),
		downTimes: () => {},
	};

	return (
		<div className={styles.settingList}>
			<div className={styles.header}>
				<p className={styles.header__title}>Настройки таблицы</p>
				<Toggle
					isOn={isAllActive[type]}
					onClick={isAllActive[type] ? mapSetAllNotActive[type] : mapSetAllActive[type]}
				/>
			</div>
			<div className={styles.body}>
				<GenericList
					list={mapList[type as keyof TListSettings].map(
						merge({
							isActive: getActiveSettings(mapList[type as keyof TListSettings]),
							className: styles.item,
							type: 'toggle',
							onClick: mapSet[type as keyof TSetSettings],
						})
					)}
				/>
			</div>
		</div>
	);
};
