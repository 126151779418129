import { request } from 'services/utils/request';
import { TDownTimesDayData, TDownTimesPayload, TDownTimesYearData } from 'services/store/interfaces/downTimes';

export const getDataDownTimesMonth = async (payload: TDownTimesPayload): Promise<TDownTimesDayData[]> => {
	return await request.post(
		'SupplierPortal.GetDowntimes',
		{
			...payload,
		},
		localStorage.token
	);
};

export const getDataDownTimesYear = async (payload: TDownTimesPayload): Promise<TDownTimesYearData[]> => {
	return await request.post(
		'SupplierPortal.GetDowntimes',
		{
			...payload,
		},
		localStorage.token
	);
};
