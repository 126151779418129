import { request } from 'services/utils/request';
import { TMessagesResponse, TMessageUpdatePayload } from 'services/store/interfaces';

export const portalUpdateCheckxMessage = async (payload: TMessageUpdatePayload): Promise<TMessagesResponse[]> => {
	return await request.post(
		'SupplierPortal.UpdateCheckxMessage',
		{
			...payload,
		},
		localStorage.token
	);
};
