export const getDateAgo = (days = 365) => {
	const date = new Date();
	const point = date.setDate(date.getDate() - days);

	const year = new Date(point).getFullYear();
	const month = new Date(point).getMonth();
	const day = new Date(point).getDate();

	return { year, month, day };
};
