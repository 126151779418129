import { useSelector } from 'react-redux';
import { IStoreState, TContentTable } from 'services/store/interfaces';
import { AppDispatch } from 'services/store';
import { removeAllActive, setAllActive } from 'services/store/orders';
import { removeAllGoodsActive, setAllGoodsActive } from 'services/store/goods';
import {
	removeReportsAllGoodsActive,
	removeReportsCurrentActive,
	removeReportsQualityActive,
	setReportsAllGoodsActive,
	setReportsCurrentActive,
	setReportsQualityActive,
} from 'services/store/reports';
import { setReportsPlanningActive } from '../../../services/store/setting';

export const mapHeadTable = (type: TContentTable) => {
	const {
		listOrder,
		listOrders,
		listGoods,
		listReportsAllGoods,
		listReportsCurrent,
		listReportsQuality,
		listReportsPlanning,
	} = useSelector((state: IStoreState) => state.setting);

	const mapHeadTable = {
		order: listOrder,
		orders: listOrders,
		goods: listGoods,
		allGoods: listReportsAllGoods,
		current: listReportsCurrent,
		quality: listReportsQuality,
		planning: listReportsPlanning,
	};

	return mapHeadTable[type];
};

export const mapSetAllActive = (type: TContentTable, dispatcher: AppDispatch) =>
	({
		orders: () => dispatcher(setAllActive()),
		goods: () => dispatcher(setAllGoodsActive()),
		allGoods: () => dispatcher(setReportsAllGoodsActive()),
		current: () => dispatcher(setReportsCurrentActive()),
		quality: () => dispatcher(setReportsQualityActive()),
		planning: () => dispatcher(setReportsPlanningActive()),
		order: () => {},
	}[type]);

export const mapRemoveAllActive = (type: TContentTable, dispatcher: AppDispatch) =>
	({
		orders: () => dispatcher(removeAllActive()),
		goods: () => dispatcher(removeAllGoodsActive()),
		allGoods: () => dispatcher(removeReportsAllGoodsActive()),
		current: () => dispatcher(removeReportsCurrentActive()),
		quality: () => dispatcher(removeReportsQualityActive()),
		planning: () => {},
		order: () => {},
	}[type]);

export const mapActiveTDSList = () => {
	const { ordersList, activeOrders } = useSelector((state: IStoreState) => state.orders);
	const { goodsList, activeGoods } = useSelector((state: IStoreState) => state.goods);
	const {
		listTablePlanning,
		listQuality,
		listQualityActive,
		listCurrent,
		listCurrentActive,
		listAllGoods,
		listAllGoodsActive,
	} = useSelector((state: IStoreState) => state.reports);

	const activeList = {
		orders: activeOrders,
		goods: activeGoods,
		allGoods: listAllGoodsActive,
		current: listCurrentActive,
		quality: listQualityActive,
		planning: listQualityActive,
	};

	const IDS = {
		orders: ordersList.map(({ OrderBuyGroupId }) => OrderBuyGroupId),
		goods: goodsList.map(({ Id }) => Id),
		allGoods: listAllGoods.map(({ GoodsId }) => GoodsId),
		current: listCurrent.map(({ GoodsId }) => GoodsId),
		quality: listQuality.map(({ OrderBuyGroupId }) => OrderBuyGroupId),
		planning: listTablePlanning.map(({ ITEM_CODE }) => String(ITEM_CODE)),
	};

	return { activeList, IDS };
};
