import React, { useState, useEffect } from 'react';
import styles from './year.module.scss';
import { getRowsYear } from 'services/utils/helpers/dateDownTimes';
import { Month } from './Month';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';

export const YearCalendar = () => {
	const { year, yearData } = useSelector((state: IStoreState) => state.downTimes);
	const [isVisible, setIsVisible] = useState(false);
	const rowsMonths = getRowsYear();

	useEffect(() => {
		setTimeout(() => setIsVisible(true), 100);
	}, []);

	return (
		<div className={styles.year} style={isVisible ? { opacity: 1 } : { opacity: 0 }}>
			{rowsMonths.map((months, inx) => (
				<div className={styles.row} key={inx}>
					{months.map((month) => (
						<Month key={month.id} month={month} year={year} yearData={yearData} />
					))}
				</div>
			))}
		</div>
	);
};
