import React, { FC, PropsWithChildren } from 'react';
import styles from './tooltip.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

type TTooltipProp = {
	x: number | undefined;
	y: number | undefined;
};

const variants = {
	active: ({ x, y }: { x: number; y: number }) => ({
		opacity: 1,
		top: y,
		left: x,
		transition: {
			duration: 0.4,
			ease: 'easeOut',
		},
	}),
	inactive: {
		opacity: 0,
		transition: {
			duration: 0.3,
		},
	},
};

const motionProps = {
	variants,
	animate: 'active',
	initial: 'inactive',
	exit: 'inactive',
};

export const Tooltip: FC<PropsWithChildren<TTooltipProp>> = ({ x, y, children }) => {
	return (
		<AnimatePresence initial={false}>
			<motion.div className={styles.tooltip} {...motionProps} custom={{ x, y }}>
				{children}
			</motion.div>
		</AnimatePresence>
	);
};
