import React from 'react';
import styles from './modePanel.module.scss';
import { IStoreState } from 'services/store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { setMode } from 'services/store/downTimes';
import classNames from 'classnames';

export const ModePanel = () => {
	const { mode } = useSelector((state: IStoreState) => state.downTimes);
	const isMonth = mode === 'month';
	const isYear = mode === 'year';

	const dispatch = useDispatch();

	return (
		<div className={styles.modes}>
			<div
				className={classNames(styles.modes_month, { [styles.modes_active]: isMonth })}
				onClick={() => dispatch(setMode('month'))}
			>
				Месяц
			</div>
			<div
				className={classNames(styles.modes_year, { [styles.modes_active]: isYear })}
				onClick={() => dispatch(setMode('year'))}
			>
				Год
			</div>
		</div>
	);
};
