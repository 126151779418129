import React, { FC } from 'react';
import styles from './index.module.scss';
import Row from './InfoRow/index';
import { TGetContractorResponse } from 'services/api/getContractorInfo';
import { profileFields } from 'services/store/user';

type TUserProps = {
	user: TGetContractorResponse;
};

export const UserTable: FC<TUserProps> = ({ user }) => {
	return (
		<div className={styles.content}>
			<div className={styles.table}>
				{profileFields.map(({ type, value, editable }) => {
					const propertyValue = user[type as keyof TGetContractorResponse];
					const result =
						type === 'LicenseType' ? (propertyValue === '0' ? 'не для продажи' : 'для продажи') : propertyValue;

					return <Row key={type} name={value} value={result} editable={editable} />;
				})}
			</div>

			{/*<div className={styles.files}>*/}
			{/*	<div className={styles.files_form}>*/}
			{/*		<label htmlFor="files" className={styles.files_form__label}>*/}
			{/*			Прикрепленные документы*/}
			{/*		</label>*/}

			{/*		<div className={styles.files_result}>Файлы отсутствуют</div>*/}

			{/*		<div className={styles.files_add}>*/}
			{/*			<input className={styles.files_add_input} type="file" id="files" />*/}
			{/*			<div className={styles.files_add_button}>Выберите файл</div>*/}
			{/*			<div className={styles.files_add_text}>файл не выбран</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}

			{/*	<div className={styles.files_text}>*/}
			{/*		Прикрепите Свидетельство о гос. регистрации юридического лица и прочие документы. Допустимые форматы файлов:*/}
			{/*		.PNG, .JPG, .JPEG, PDF размером не более 10 Мб*/}
			{/*	</div>*/}
			{/*</div>*/}
		</div>
	);
};
