import React, { useEffect, memo } from 'react';
import styles from './settings.module.scss';
import { SettingButton } from './SettingButton';
import { SettingList } from './SettingList';
import { Dropdown } from 'components/Common/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import {
	hideDivide,
	hideEvents,
	hideGoods,
	hideMultiOrders,
	hideOrder,
	hideOrders,
	hideReportsAllGoods,
	hideReportsCurrent,
	hideReportsPlanning,
	hideReportsQuality,
	showDivide,
	showEventsSetting,
	showGoods,
	showMultiOrders,
	showOrder,
	showOrders,
	showReportsAllGoods,
	showReportsCurrent,
	showReportsPlanning,
	showReportsQuality,
} from 'services/store/setting';
import useBreakpoint from 'services/hooks/useBreakpoint';

interface ISettingsProps {
	type?:
		| 'goods'
		| 'orders'
		| 'order'
		| 'events'
		| 'multiOrders'
		| 'notifications'
		| 'divide'
		| 'planning'
		| 'allGoods'
		| 'quality'
		| 'current'
		| 'downTimes';
}

export const Settings = memo(({ type = 'goods' }: ISettingsProps) => {
	const {
		isShowOrders,
		isShowGoods,
		isShowReportsAllGoods,
		isShowReportsCurrent,
		isShowReportsQuality,
		isShowReportsPlanning,
		isShowOrder,
		isShowEvents,
		isShowMultiOrders,
		isShowDivide,
	} = useSelector((state: IStoreState) => state.setting);

	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');
	const isDesktop = !isMobile;
	const target = useSelector((state: IStoreState) => state.outside.target);
	const dispatch = useDispatch();

	const mapIsShow = {
		orders: isShowOrders,
		order: isShowOrder,
		goods: isShowGoods,
		events: isShowEvents,
		notifications: isShowEvents,
		multiOrders: isShowMultiOrders,
		divide: isShowDivide,
		allGoods: isShowReportsAllGoods,
		quality: isShowReportsQuality,
		current: isShowReportsCurrent,
		planning: isShowReportsPlanning,
		downTimes: false,
	};

	const mapSetShow = {
		orders: () => dispatch(showOrders()),
		order: () => dispatch(showOrder()),
		goods: () => dispatch(showGoods()),
		events: () => dispatch(showEventsSetting()),
		notifications: () => dispatch(showEventsSetting()),
		multiOrders: () => dispatch(showMultiOrders()),
		divide: () => dispatch(showDivide()),
		allGoods: () => dispatch(showReportsAllGoods()),
		quality: () => dispatch(showReportsQuality()),
		current: () => dispatch(showReportsCurrent()),
		planning: () => dispatch(showReportsPlanning()),
		downTimes: () => {},
	};

	const mapSetHide = {
		orders: () => dispatch(hideOrders()),
		order: () => dispatch(hideOrder()),
		goods: () => dispatch(hideGoods()),
		events: () => dispatch(hideEvents()),
		notifications: () => dispatch(hideEvents()),
		multiOrders: () => dispatch(hideMultiOrders()),
		divide: () => dispatch(hideDivide()),
		allGoods: () => dispatch(hideReportsAllGoods()),
		quality: () => dispatch(hideReportsQuality()),
		current: () => dispatch(hideReportsCurrent()),
		planning: () => dispatch(hideReportsPlanning()),
		downTimes: () => {},
	};

	useEffect(() => {
		const list = document.querySelector('[class*=settingList]');
		const button = document.querySelector('[class*=settingButton]');

		if (isDesktop && list && button && !list.contains(target as Node) && !button.contains(target as Node)) {
			mapSetHide[type]();
		}
	}, [target]);

	return (
		<div className={styles.settings}>
			<Dropdown
				button={<SettingButton isMobile={isMobile} onClick={mapSetShow[type]} isOpen={mapIsShow[type]} />}
				isOpen={mapIsShow[type]}
			>
				<SettingList type={type} />
			</Dropdown>
		</div>
	);
});
