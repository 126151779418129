import { request } from 'services/utils/request';
import { TOrderId, TGetOrderCheckxResponse } from 'services/store/interfaces';

export const getOrderBuyGroupCheckx = async (payload: TOrderId): Promise<TGetOrderCheckxResponse[]> => {
	return await request.post(
		'SupplierPortal.GetOrderBuyGroupCheckx',
		{
			...payload,
		},
		localStorage.token
	);
};
