import React, { Fragment, useRef } from 'react';
import styles from './printItems.module.scss';
import { ActionButton } from 'components/Common/ActionButton';
import ReactToPrint from 'react-to-print';
import { TCurrentGood, TEvent, TGetOrderBuyGroupResponse, TSettings } from 'services/store/interfaces';

type TKey = TGetOrderBuyGroupResponse | TCurrentGood | TEvent;
interface IPrintOrdersProps {
	headers: TSettings[];
	body: (TGetOrderBuyGroupResponse | TCurrentGood | TEvent)[];
	isMobile: boolean;
}
export const PrintItems = ({ headers, body, isMobile }: IPrintOrdersProps) => {
	const refComponent = useRef(null);

	return !isMobile ? (
		<Fragment>
			<ReactToPrint
				content={() => refComponent.current}
				trigger={() => <ActionButton text="Распечатать" type="print" />}
			/>

			<div style={{ display: 'none' }}>
				<table className={styles.table} ref={refComponent}>
					<thead className={styles.tr}>
						<tr className={styles.tr}>
							{headers.map((item, index) => (
								<th key={index} className={styles.th}>
									{item.text}
								</th>
							))}
						</tr>
					</thead>

					<tbody>
						{body.map((item, index) => (
							<tr className={styles.tr} key={index}>
								{headers.map(({ id }) => (
									<td key={id} className={styles.td}>
										{item[id as keyof TKey]}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Fragment>
	) : null;
};
