import React from 'react';
import { MainLayout } from 'layouts/Main';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { Events } from 'components/Content/Events';
import { Footer } from 'components/Footer';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { ClearFilter } from 'components/Common/ActionButton/ClearFilter';
import { DownTimes } from 'components/Content/DownTimes';
import styles from './events.module.scss';
import { Actions } from 'components/Actions';

const title = {
	events: 'События',
	notifications: 'Уведомления',
	downTimes: 'Простои',
};

export const EventsPage = () => {
	const { type } = useSelector((state: IStoreState) => state.events);
	const countEvents = useSelector((state: IStoreState) => state.events.count);
	const countNotifications = useSelector((state: IStoreState) => state.notifications.countNotifications);
	const isFilterShow = useSelector((state: IStoreState) => state.filter.isShowEvents);

	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');
	const isFooterMobile = isMobile && !isFilterShow;

	const isDownTimes = type === 'downTimes';

	const count = {
		events: countEvents,
		notifications: countNotifications,
		downTimes: 0,
	};

	return (
		<MainLayout>
			<div className={styles.events}>
				<div className={styles.header}>{title[type]}</div>
				<Actions type={type} itemsCount={count[type]} />

				{isDownTimes ? <DownTimes /> : <Events type={type} count={count[type]} />}
			</div>

			{!isDownTimes && (
				<Footer text="Операции с выбранными событиями" visible={isFooterMobile}>
					{isFooterMobile && <ClearFilter type={type} text="Очистить все фильтры" mobile={true} />}
				</Footer>
			)}
		</MainLayout>
	);
};
