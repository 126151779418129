import React, { useMemo } from 'react';
import styles from './pagination.module.scss';
import { GenericList } from 'components/Common/GenericList';
import { merge } from 'services/utils/js/merge';
import classNames from 'classnames';
import { Desktop } from 'components/HOCS/Desktop';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TContentBlock } from 'services/store/interfaces';
import { AnimatePresence, motion } from 'framer-motion';
import {
	setFiltersEvents,
	setFiltersGoods,
	setFiltersMessages,
	setFiltersOrderGoods,
	setFiltersOrders,
	setFiltersReportsAllGoods,
	setFiltersReportsCurrent,
	setFiltersReportsPlanningPagination,
	setFiltersReportsQuality,
} from 'services/store/filter';

type Page = {
	text: string;
	id: string;
};

interface IPaginationInterface {
	pages: Page[];
	items?: number;
	type?: TContentBlock;
}

const mapFuncsClick = {
	orders: setFiltersOrders,
	goods: setFiltersGoods,
	events: setFiltersEvents,
	order: setFiltersOrderGoods,
	notifications: setFiltersMessages,
	allGoods: setFiltersReportsAllGoods,
	current: setFiltersReportsCurrent,
	quality: setFiltersReportsQuality,
	planning: setFiltersReportsPlanningPagination,
};

const mapSetTitle = {
	notifications: 'уведомлений',
	orders: 'заказов',
	goods: 'товаров',
	events: 'событий',
	order: 'товаров',
	allGoods: 'заказов',
	current: 'заказов',
	quality: 'заказов',
	planning: 'товаров',
};

export const Pagination = ({ type = 'orders', pages, items = 50 }: IPaginationInterface) => {
	const {
		listOrders,
		listGoods,
		listEvents,
		listReportsQuality,
		listReportsCurrent,
		listReportsAllGoods,
		listReportsPlanning,
		listOrderGoods,
		listMessages,
	} = useSelector((state: IStoreState) => state.filter);

	const orders = useSelector((state: IStoreState) => state.orders.ordersList);
	const goods = useSelector((state: IStoreState) => state.goods.goodsList);
	const orderGoods = useSelector((state: IStoreState) => state.orders.currentGoods);
	const { events } = useSelector((state: IStoreState) => state.events);
	const { notifications } = useSelector((state: IStoreState) => state.notifications);
	const { listTablePlanning, listAllGoods, listCurrent, listQuality } = useSelector(
		(state: IStoreState) => state.reports
	);

	const { cancelOrder, ordersList } = useSelector((state: IStoreState) => state.orders);

	const dispatcher = useDispatch();
	const isOrders = type === 'orders';

	const onClickPage = (type: TContentBlock, id: string) => {
		dispatcher(mapFuncsClick[type]({ filters: { PageNumber: Number(id) } }));
	};

	const length = useMemo(
		() => ({
			orders: orders.length,
			goods: goods.length,
			events: events.length,
			order: orderGoods.length,
			notifications: notifications.length,
			allGoods: listAllGoods.length,
			current: listCurrent.length,
			quality: listQuality.length,
			planning: listTablePlanning.length,
		}),
		[
			orders.length,
			goods.length,
			events.length,
			orderGoods.length,
			notifications.length,
			listAllGoods.length,
			listCurrent.length,
			listQuality.length,
			listTablePlanning.length,
		]
	);

	const current = {
		orders: listOrders.PageNumber,
		goods: listGoods.PageNumber,
		events: listEvents.PageNumber,
		order: listOrderGoods.PageNumber,
		notifications: listMessages.PageNumber,
		allGoods: listReportsAllGoods.PageNumber,
		current: listReportsCurrent.PageNumber,
		quality: listReportsQuality.PageNumber,
		planning: listReportsPlanning.PageNumber,
	};

	return (
		<Desktop>
			<AnimatePresence>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					className={classNames(styles.pagination, {
						[styles.pagination__hidden]: isOrders && cancelOrder.length > 0 && cancelOrder.length === ordersList.length,
					})}
				>
					<div className={styles.text}>
						Показаны {length[type]} {mapSetTitle[type]} из {items}
					</div>

					<div className={styles.pages}>
						<GenericList
							list={pages.map((item) =>
								merge(item)({
									className: classNames(styles.page, {
										[styles.current]: Number(item.text) === current[type],
									}),
									onClick: () => {
										onClickPage(type, item.text);
										window.scrollTo(0, 0);
									},
								})
							)}
						/>
					</div>
				</motion.div>
			</AnimatePresence>
		</Desktop>
	);
};
