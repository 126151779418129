import { request } from 'services/utils/request';
import { TUpdateOrderBuyGroupPayload, TUpdateOrderBuyGroupResponse } from 'services/store/interfaces';
export const updateOrderBuyGroup = async (
	payload: TUpdateOrderBuyGroupPayload
): Promise<TUpdateOrderBuyGroupResponse[]> => {
	return await request.post(
		'SupplierPortal.UpdateOrderBuyGroup',
		{
			...payload,
		},
		localStorage.token
	);
};
