import React, { FC, useState, useEffect } from 'react';
import styles from './reports.module.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import { Dropdown } from 'components/Common/Dropdown';
import classNames from 'classnames';
import { Button } from './Button';
import { List } from './List';
import { useLocation } from 'react-router-dom';

interface IReportsMenuProps {
	mix?: string;
}
export const Reports: FC<IReportsMenuProps> = ({ mix }) => {
	const { pathname } = useLocation();
	const [isOpen, setIsOpen] = useState(false);
	const [isCurrent, setIsCurrent] = useState(pathname.includes('reports'));

	useEffect(() => {
		setIsCurrent(pathname.includes('reports'));
	}, [pathname]);

	return (
		<OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
			<div
				className={classNames(
					styles.reports,
					{
						[styles.reports_opened]: isOpen,
						[styles.reports_current]: isCurrent,
						[styles.reports_active]: isCurrent && isOpen,
					},
					mix
				)}
			>
				<Dropdown button={<Button onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />} isOpen={isOpen}>
					<List onClose={() => setIsOpen(false)} />
				</Dropdown>
			</div>
		</OutsideClickHandler>
	);
};
