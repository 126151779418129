import React from 'react';
import styles from '../goodsDetail.module.scss';
import classNames from 'classnames';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';
import { QuantityGood } from '../../QuantityGood';
import { IStoreState, TCurrentGood } from 'services/store/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { addGood, removeGood } from 'services/store/multiorders';
import { addGoodItem, removeGoodItem } from 'services/store/divideOrders';

interface IGoodRow {
	good: TCurrentGood;
	onChange: (valueId: string, valueQuantity: string, OrderId: string) => void;
	type: 'multi' | 'divide';
}
export const GoodRow = ({ good, onChange = () => {}, type }: IGoodRow) => {
	const settingMultiGoods = useSelector((state: IStoreState) => state.setting.listMultiOrder);
	const settingDivideGoods = useSelector((state: IStoreState) => state.setting.listDivide);

	const activeMultiGoods = useSelector((state: IStoreState) => state.multiOrders.activeGoods);
	const activeDivideGoods = useSelector((state: IStoreState) => state.divideOrders.orderBuyIdList);

	const isActiveMulti = activeMultiGoods.includes(String(good.OrderBuyId));
	const isActiveDivide = activeDivideGoods.includes(String(good.OrderBuyId));
	const orderId = String(good.OrderBuyGroupId);

	const isActive = {
		multi: isActiveMulti,
		divide: isActiveDivide,
	};

	const dispatch = useDispatch();

	const handleActiveMulti = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addGood(id)) : dispatch(removeGood(id)));
	};

	const handleActiveDivide = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addGoodItem(id)) : dispatch(removeGoodItem(id)));
	};

	const settings = {
		multi: settingMultiGoods,
		divide: settingDivideGoods,
	};

	const handlerActive = {
		multi: handleActiveMulti,
		divide: handleActiveDivide,
	};

	return (
		<div className={classNames(styles.editTr, { [styles.active]: isActive[type] })}>
			<div className={classNames(styles.editTh, styles.checkGood)}>
				<InputCheckbox
					isActive={isActive[type]}
					onChange={(isActive) => handlerActive[type](isActive)(String(good.OrderBuyId))}
				/>
			</div>

			{settings[type].map(({ id, isShow }) => {
				const isName = id === 'GoodsName';
				const isQuantity = id === 'QuantitySupplier';
				const isLimit = id === 'LimitQuantity';
				const inPack = good.QuantityInPack || '';
				const quantity = good.QuantitySupplier || '';
				const endQuantity = good.QuantityOrder || '';
				const limitQuantity = good.LimitQuantity || '100';
				const isControlled = good.ControlWeight !== '1';
				const lineId = good.OrderBuyId || '';

				const quantityMap = {
					multi: (
						<QuantityGood
							lineId={lineId}
							orderId={orderId}
							endQuantity={limitQuantity}
							value={quantity}
							inPack={inPack}
							onChange={onChange}
							isControlled={isControlled}
							isEdit={true}
						/>
					),
					divide: (
						<QuantityGood
							lineId={lineId}
							orderId={orderId}
							value={quantity}
							endQuantity={endQuantity}
							onChange={onChange}
							inPack={inPack}
							isActive={isActive[type]}
							isControlled={true}
						/>
					),
				};

				const value = isQuantity ? quantityMap[type] : isLimit ? limitQuantity : good[id as keyof TCurrentGood];

				return isShow ? (
					<div
						className={classNames(styles.editThValue, {
							[styles.goodName]: isName,
						})}
						key={id}
					>
						{value}
					</div>
				) : null;
			})}
		</div>
	);
};
