import { request } from 'services/utils/request';
import { TCleanTokenPayload, TCleanTokenResponse } from 'services/store/interfaces';

export const cleanToken = async (data: TCleanTokenPayload): Promise<TCleanTokenResponse[]> => {
	return await request.post(
		'CleanToken',
		{
			...data,
		},
		localStorage.token
	);
};
