import { TCurrentOrder } from 'services/store/interfaces';

export const getOrderStatus = (order: TCurrentOrder) => {
	const status = order?.Checkx;
	const isRevised = order?.IsRevised;

	if (status == '10' && isRevised == '2') {
		return { type: 'success', text: 'Запрос одобрен' };
	}

	if ((!isRevised || isRevised == '99') && status == '10') {
		return { type: 'success', text: 'На сайте' };
	}

	if ((!isRevised || isRevised == '99') && status == '50') {
		return { type: 'success', text: 'Подтвержден' };
	}

	if ((status == '50' || status == '10') && isRevised == '1') {
		return { type: 'warning', text: 'Запрос корректировки' };
	}

	if ((status == '50' || status == '10') && isRevised == '3') {
		return { type: 'error', text: 'Запрос отклонен' };
	}

	if (status == '90') {
		return { type: 'default', text: 'Выполнен' };
	}

	return { type: 'warning', text: '' };
};

export const isUpdate = (order: TCurrentOrder) => {
	return order.Checkx == '10' && order.IsRevised == '2';
};

export const is10Correct = (order: TCurrentOrder) => {
	return order.Checkx == '10' && order.IsRevised != '1' && order.IsRevised != '2';
};

export const isCorrected = (order: TCurrentOrder) => {
	const { Checkx, CheckxFact, IsRevised } = order;
	const isCorrectCheckxFact = [50, 51].includes(Number(CheckxFact));

	return Checkx == '50' && isCorrectCheckxFact && IsRevised != '1' && IsRevised != '2';
};
