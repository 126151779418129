import { createContext } from 'react';

export type TCoordinate = {
	id: string;
	width: number;
	left: number;
};

const GroupsContext = createContext({
	width: '',
	coordinates: [{ id: '', width: 0, left: 0 }],
	setCoordinates: (coordinates: TCoordinate[]) => {
		/** **/
	},
	setAllWidth: (width: string) => {
		/** **/
	},
});

export default GroupsContext;
