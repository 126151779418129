import React, { useEffect, useState } from 'react';
import { ReactComponent as MessagesSVG } from 'assets/images/header/messages.svg';
import styles from './eventsIcon.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';

export const EventsIcon = () => {
	const countUnread = useSelector((state: IStoreState) => state.notifications.sidebarUnreadCount);
	const [count, setCount] = useState(countUnread);

	useEffect(() => {
		setCount(countUnread);
	}, [countUnread]);

	return (
		<div
			className={classNames(styles.events, {
				[styles.events_active]: count > 0,
			})}
		>
			<MessagesSVG />
			{count > 0 && <span className={styles.events_count}>{count}</span>}
		</div>
	);
};
