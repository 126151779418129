import React, { useEffect, useState } from 'react';
import styles from './inputCheckbox.module.scss';
import classNames from 'classnames';

interface IInputCheckboxProps {
	onChange?: (value: boolean) => void;
	mix?: object;
	isActive?: boolean;
	disabled?: boolean;
	isCookies?: boolean;
}

export const InputCheckbox = ({
	onChange = () => {},
	isActive = false,
	mix,
	disabled,
	isCookies,
}: IInputCheckboxProps) => {
	const [active, setActive] = useState(isActive);

	useEffect(() => setActive(isActive), [isActive]);

	const handleChange = () => {
		if (!isCookies) setActive(!active);
		onChange(!active);
	};

	return (
		<div
			className={classNames(styles.input, {
				[styles.input_disable]: disabled,
			})}
		>
			<input type="checkbox" onChange={disabled ? () => {} : handleChange} checked={active} />
			<span
				className={classNames(
					classNames(
						styles.checkmark,
						{
							[styles.checkmark__disable]: disabled,
						},
						mix
					)
				)}
			/>
		</div>
	);
};
