import React, { Fragment } from 'react';
import { IStoreState } from 'services/store/interfaces';
import { EventItem } from '../../EventsItems/EventItem';
import styles from '../eventsAll.module.scss';
import { EventsList } from '../../EventsList';
import scs from '../NewEvents/newEvents.module.scss';
import { useSelector } from 'react-redux';
export const NotificationList = () => {
	const readList = useSelector((state: IStoreState) => state.notifications.readNotifications);
	const unReadList = useSelector((state: IStoreState) => state.notifications.unReadNotifications);

	return (
		<Fragment>
			<p className={styles.heading}>Непрочитанные уведомления ({unReadList.length})</p>

			{unReadList.length > 0 ? (
				<EventsList>
					{unReadList.map((notification, index) => {
						const { MessageId, Timex, MessageText, MessageTypeId } = notification;

						return (
							<EventItem
								key={index}
								id={String(MessageId)}
								title={MessageText}
								description={MessageText}
								date={Timex}
								type="notifications"
								status={MessageTypeId}
							/>
						);
					})}
				</EventsList>
			) : (
				<div className={scs.empty}>Непрочитанные уведомления отсутствуют</div>
			)}

			<p className={styles.heading}>Прочитанные уведомления ({readList.length})</p>

			{readList.length > 0 ? (
				<EventsList>
					{readList.map((notification, index) => {
						const { MessageId, Timex, MessageText, Checkx } = notification;

						return (
							<EventItem
								key={index}
								id={String(MessageId)}
								title={MessageText}
								description={MessageText}
								status={String(Checkx)}
								date={Timex}
								checkx="read"
								type="notifications"
							/>
						);
					})}
				</EventsList>
			) : (
				<div className={scs.empty}>Прочитанные уведомления отсутствуют</div>
			)}
		</Fragment>
	);
};
