import { TCurrentGood, TSettings } from 'services/store/interfaces';

export function clipboardGoods(headers: TSettings[], goods: TCurrentGood[], func: () => void) {
	const headersList = headers.filter((header) => header.isShow).map(({ id, text }) => ({ id, text }));

	const result = headersList
		.map(({ id, text }) => {
			const goodsString = goods
				.map((good) => {
					const value = good[id as keyof TCurrentGood] || '';
					return `${value} ${' '.repeat(45 - value.length)}`;
				})
				.join('');

			return `${text}${' '.repeat(40 - text.length)}${goodsString}\n`;
		})
		.join('');

	navigator.clipboard.writeText(result).then(func);
}
