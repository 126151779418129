import React, { FC, useEffect, useState, useRef, useContext } from 'react';
import styles from './orderRow.module.scss';
import { IStoreState, TGetOrderBuyGroupResponse } from 'services/store/interfaces';
import { addOrder, removeOrder } from 'services/store/orders';
import { useDispatch, useSelector } from 'react-redux';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';
import classnames from 'classnames';
import GroupsContext from 'services/contexts/GroupsContext';
import { OrderItem } from '../OrderItem';
import { motion } from 'framer-motion';
import { fetchUpdateOrdersAfterConfirm, fetchUpdateOrdersAfterRewise } from 'services/store/grouping';

type TOrderRowProps = {
	order: TGetOrderBuyGroupResponse;
};

type TOrderPayloadType = {
	payload: TGetOrderBuyGroupResponse;
};

const getStylesParent = (elem: HTMLDivElement, leftPoint: number, width: string) => {
	const body = elem?.closest('[class*="body"]') as HTMLElement;
	const parent = elem?.closest('[class*="groups"]') as HTMLElement;

	if (body) body.style.border = 'none';

	if (parent) {
		parent.style.left = 'unset';
		const parentLeft = parent.getBoundingClientRect().left;
		const leftUp = `${leftPoint - parentLeft}px`;
		const leftDown = `-${parentLeft - leftPoint}px`;
		const left = leftPoint > parentLeft ? leftUp : leftDown;

		parent.style.padding = '0';
		parent.style.borderTop = '1px solid #dfdff0';
		parent.style.position = 'relative';
		parent.style.width = `${width}px`;
		parent.style.left = left;
	}
};

const setHiddenStylesParent = (elem: HTMLDivElement) => {
	const parent = elem?.closest('[class*="groups"]') as HTMLElement;

	if (parent) {
		parent.style.borderTop = 'none';
		parent.style.borderBottom = 'none';
	}
};

const variants = {
	visible: { opacity: 1, height: 'auto' },
	hidden: { opacity: 0, height: 0 },
};

export const OrderRow: FC<TOrderRowProps> = ({ order }) => {
	const [item, setItem] = useState<TGetOrderBuyGroupResponse>(order);

	const { width, coordinates } = useContext(GroupsContext);
	const { activeOrders } = useSelector((state: IStoreState) => state.orders);
	const settingOrders = useSelector((state: IStoreState) => state.setting.listOrders);
	const { groupingProps } = useSelector((state: IStoreState) => state.grouping);

	const { cancelOrder, confirmOrder, revisedOrder } = useSelector((state: IStoreState) => state.orders);
	const dispatch = useDispatch();

	const ref = useRef<HTMLDivElement>(null);
	const handleOrder = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addOrder({ id })) : dispatch(removeOrder({ id })));
	};

	useEffect(() => {
		if (!ref.current) return;

		getStylesParent(ref.current, coordinates[0].left, width);
	}, [coordinates[0].left, width, groupingProps.length]);

	useEffect(() => {
		setTimeout(() => {
			if (!(confirmOrder.length > 0 && confirmOrder.includes(String(item?.OrderBuyGroupId)))) return;

			dispatch(fetchUpdateOrdersAfterConfirm(String(item?.OrderBuyGroupId))).then((res: TOrderPayloadType) =>
				setItem(res.payload)
			);
		}, 1000);
	}, [confirmOrder.length]);

	useEffect(() => {
		setTimeout(() => {
			if (!(Object.keys(revisedOrder).length > 0 && Object.keys(revisedOrder).includes(String(item?.OrderBuyGroupId))))
				return;
			dispatch(fetchUpdateOrdersAfterRewise(String(item?.OrderBuyGroupId))).then((res: TOrderPayloadType) =>
				setItem(res.payload)
			);
		}, 1000);
	}, [JSON.stringify(revisedOrder)]);

	useEffect(() => {
		if (!ref.current || !cancelOrder.includes(String(item?.OrderBuyGroupId))) return;

		setHiddenStylesParent(ref.current);
	}, [JSON.stringify(cancelOrder)]);

	return (
		<motion.div
			animate={cancelOrder.includes(String(item?.OrderBuyGroupId) || '') ? 'hidden' : 'visible'}
			transition={{ duration: 0.3 }}
			className={styles.orderRow}
			variants={variants}
		>
			<div className={classnames(styles.orderRow_td, styles.wrLeft)} ref={ref}>
				<InputCheckbox
					isActive={activeOrders?.includes(String(item?.OrderBuyGroupId))}
					onChange={(isActive) => handleOrder(isActive)(String(item?.OrderBuyGroupId))}
					mix={styles.checkmark}
				/>
			</div>

			{settingOrders.map(({ id, isShow }) => {
				return isShow ? <OrderItem order={item} id={id} key={id} /> : null;
			})}
		</motion.div>
	);
};
