import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	INotificationsAction,
	INotificationsListState,
	TFilterMessages,
	TMessageUpdatePayload,
	TNotification,
} from './interfaces';
import { showEvents } from './events';
import { showEventsSetting } from './setting';
import { portalGetMessage } from '../api/portalGetMessage';
import { portalUpdateCheckxMessage } from '../api/portalUpdateCheckxMessage';
import { defaultListMessages } from './filter';

export const fetchGetNotifications = createAsyncThunk(
	'notifications/fetchGetNotifications',
	async (data: TFilterMessages) => {
		return await portalGetMessage(data);
	}
);

export const fetchGetUnreadSidebarNotifications = createAsyncThunk(
	'notifications/fetchGetUnreadSidebarNotifications',
	async () => {
		return await portalGetMessage({ ...defaultListMessages, Checkx: '1' });
	}
);

export const fetchPortalUpdateCheckxMessage = createAsyncThunk(
	'notifications/fetchPortalUpdateCheckxMessage',
	async (data: TMessageUpdatePayload) => {
		return await portalUpdateCheckxMessage(data);
	}
);

export const fetchSidebarUpdateCheckxMessage = createAsyncThunk(
	'notifications/fetchSidebarUpdateCheckxMessage',
	async (data: TMessageUpdatePayload) => {
		return await portalUpdateCheckxMessage(data);
	}
);

const initialState: INotificationsListState = {
	activeNotifications: [],
	countNotifications: 0,
	isShowNotifications: false,
	isLoading: false,
	sidebarUnreadList: [],
	sidebarUnreadCount: 0,
	sidebarUpdate: true,
	unreadCount: 0,
	readCount: 0,
	current: '0',
	notifications: [],
	unReadNotifications: [],
	readNotifications: [],
	message: '',
};

const notifications = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		addNotification: (state: INotificationsListState, action: INotificationsAction) => {
			if (action.payload.id) state.activeNotifications = [...state.activeNotifications, action.payload.id];
			return state;
		},
		removeNotification: (state: INotificationsListState, action: INotificationsAction) => {
			if (action.payload.id)
				state.activeNotifications = state.activeNotifications.filter((id: string) => id !== action.payload.id);
			return state;
		},
		showNotification: (state: INotificationsListState) => {
			state.isShowNotifications = !state.isShowNotifications;
		},
		hideNotification: (state: INotificationsListState) => {
			state.isShowNotifications = false;
		},
		setUpdateSidebarCount: (state, action) => {
			state.sidebarUpdate = action.payload;
		},
		setCurrentNotification: (state, action) => {
			state.current = action.payload;
		},
		hideMessage: (state, action) => {
			const readMessage = state.unReadNotifications.filter((item: TNotification) => item.MessageId === action.payload);

			state.unReadNotifications = state.unReadNotifications.filter(
				(item: TNotification) => item.MessageId !== action.payload
			);

			state.readNotifications = [...readMessage, ...state.readNotifications];

			state.unreadCount = state.unReadNotifications.length;
			state.readCount = state.readNotifications.length;
		},
		hideMessageSidebar: (state, action) => {
			state.sidebarUnreadList = state.sidebarUnreadList.filter(
				(item: TNotification) => item.MessageId !== action.payload
			);
			state.sidebarUnreadCount = state.sidebarUnreadList.length;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(showEvents, (state: INotificationsListState) => {
			state.isShowNotifications = false;
		});
		builder.addCase(showEventsSetting, (state: INotificationsListState) => {
			if (window.innerWidth > 560) state.isShowNotifications = false;
		});
		builder.addCase(fetchGetNotifications.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchGetNotifications.fulfilled, (state, action) => {
			const data = action.payload;
			state.isLoading = false;

			if (data.length === 0) {
				state.countNotifications = 0;
				state.unreadCount = 0;
				state.message = 'Уведомления отсутствуют ...';

				if (state.sidebarUpdate) state.sidebarUnreadCount = 0;
				return;
			}

			if (data[0].Error) {
				state.countNotifications = 0;
				state.message = 'Проверьте правильность данных ...';
				return;
			}

			state.countNotifications = Number(data[0].Amount);

			state.unReadNotifications = data.filter((message) => message.Checkx === '1');
			state.readNotifications = data.filter((message) => message.Checkx === '2');

			state.unreadCount = data.filter((message) => message.Checkx === '1').length;
			state.readCount = data.filter((message) => message.Checkx === '2').length;

			if (state.sidebarUpdate) {
				state.sidebarUnreadCount = state.unreadCount;
				state.sidebarUnreadList = state.unReadNotifications;
			}

			state.isLoading = false;
			state.notifications = data;
		});
		builder.addCase(fetchGetNotifications.rejected, (state) => {
			state.countNotifications = 0;
			state.unreadCount = 0;
			state.message = 'Уведомления отсутствуют ...';
			state.isLoading = false;
		});
		builder.addCase(fetchPortalUpdateCheckxMessage.pending, () => {});
		builder.addCase(fetchPortalUpdateCheckxMessage.fulfilled, (state, action) => {
			const data = action.payload;

			if (!data[0].Error && state.sidebarUpdate) {
				state.sidebarUnreadCount = state.sidebarUnreadCount - 1;
				state.unreadCount = state.unreadCount - 1;
				state.readCount = state.readCount + 1;
			}

			if (!data[0].Error && !state.sidebarUpdate) {
				state.unreadCount = state.unreadCount - 1;
				state.readCount = state.readCount + 1;
			}
		});
		builder.addCase(fetchPortalUpdateCheckxMessage.rejected, () => {});
		builder.addCase(fetchSidebarUpdateCheckxMessage.pending, () => {});
		builder.addCase(fetchSidebarUpdateCheckxMessage.fulfilled, (state, action) => {
			const data = action.payload;

			if (!data[0].Error && state.sidebarUpdate) {
				state.sidebarUnreadCount = state.sidebarUnreadCount - 1;
				state.unreadCount = state.unreadCount - 1;
				state.readCount = state.readCount + 1;
			}

			if (!data[0].Error && !state.sidebarUpdate) {
				state.sidebarUnreadCount = state.sidebarUnreadCount - 1;
			}
		});
		builder.addCase(fetchSidebarUpdateCheckxMessage.rejected, () => {});
		builder.addCase(fetchGetUnreadSidebarNotifications.pending, () => {});
		builder.addCase(fetchGetUnreadSidebarNotifications.fulfilled, (state, action) => {
			const data = action.payload;

			if (data.length === 0) {
				state.sidebarUnreadCount = 0;
				state.sidebarUnreadList = [];
				return;
			}

			if (data[0].Error) {
				state.sidebarUnreadCount = 0;
				state.sidebarUnreadList = [];
				return;
			}

			state.sidebarUnreadCount = Number(data[0].Amount);
			state.sidebarUnreadList = data;
		});
		builder.addCase(fetchGetUnreadSidebarNotifications.rejected, () => {});
	},
});

export const {
	showNotification,
	hideNotification,
	setCurrentNotification,
	hideMessage,
	hideMessageSidebar,
	setUpdateSidebarCount,
} = notifications.actions;
export default notifications.reducer;
