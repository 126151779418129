import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from '../store/interfaces';

export const useIsAdminSession = () => {
	const isAdmin = useSelector((state: IStoreState) => state.support.isAdmin);
	const [isAdminSession, setIsAdminSession] = useState<boolean>(false);

	useEffect(() => {
		setIsAdminSession(localStorage.getItem('activeSession') === 'yes');
	}, [isAdmin]);

	return [isAdminSession];
};
