import React, { Fragment } from 'react';
import { IStoreState } from 'services/store/interfaces';
import styles from '../infoTable.module.scss';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { TDemandTableItemData } from '../../../services/store/interfaces/reports';

export const ReportsPlanningTable = () => {
	const { listTablePlanning } = useSelector((state: IStoreState) => state.reports);
	const { listReportsPlanning } = useSelector((state: IStoreState) => state.setting);

	return (
		<Fragment>
			{listTablePlanning.map((good, inx) => {
				return (
					<tr className={styles.tr} key={inx}>
						{listReportsPlanning.map(({ id, isShow }) => {
							const value = good[id as keyof TDemandTableItemData];

							return isShow ? (
								<td
									className={classNames(styles.td, styles.td__reports, {
										[styles.td__planGoodsId]: id === 'GoodsId',
									})}
									key={id}
								>
									{value}
								</td>
							) : null;
						})}
					</tr>
				);
			})}
		</Fragment>
	);
};
