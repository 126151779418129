import React, { useState, useLayoutEffect } from 'react';
import styles from './promoCard.module.scss';
import className from 'classnames';
import { getDateEvent } from 'services/utils/js/getDateEvent';
import { ArrowSVG, CalendarSVG, PromoSVG } from 'assets/images/events';
import OutsideClickHandler from 'react-outside-click-handler';

const properties = [
	{ name: 'DistributionStartDate', value: 'Старт распределения в сеть' },
	{ name: 'PromotionGoodsTimeBegin', value: 'Дата старта промо' },
	{ name: 'PromotionGoodsTimeEnd', value: 'Дата окончания промо' },
	{ name: 'WarehouseQuantity', value: 'Количество торговых точек' },
];

export const PromoCard = ({ ...event }) => {
	const { GoodsId, GoodsName, Datex, Status, DayName, Info, DayId } = event;
	const [isOpen, setIsOpen] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [isTransition, setTransition] = useState(false);

	const isPromo = Status === '30';

	useLayoutEffect(() => {
		if (isOpen) {
			setIsVisible(true);

			setTimeout(() => setTransition(true), 300);
		}

		if (!isOpen) setTransition(false);
	}, [isOpen]);

	const endTransition = () => {
		if (!isOpen) setIsVisible(false);
	};

	return (
		<OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
			<div className={styles.promo} onClick={() => setIsOpen(!isOpen)}>
				<div className={styles.promo_top}>
					<div className={styles.promo_left}>
						<div className={styles.promo_image}>
							{isPromo ? (
								<PromoSVG color={isVisible ? '#8DC640' : '#8A9099'} />
							) : (
								<CalendarSVG color={isVisible ? '#8DC640' : '#8A9099'} />
							)}
						</div>

						<div
							className={className(styles.promo_sku, {
								[styles.promo_sku__promo]: isPromo,
							})}
						>
							{isPromo ? `SKU ${GoodsId}` : `${DayName}`}
						</div>

						<div className={styles.promo_title}>{isPromo ? `${GoodsName} запланировано для участия в ПРОМО` : ''}</div>

						<div
							className={className(styles.promo_mobile, {
								[styles.promo_mobile__active]: isVisible,
							})}
						>
							{isPromo ? `SKU ${GoodsId} запланировано для участия в ПРОМО` : `Событие №${DayId}`}
						</div>
					</div>

					<div className={styles.promo_right}>
						<div className={styles.promo_date}>{getDateEvent(Datex)}</div>

						<div className={className(styles.promo_arrow, { [styles.promo_arrow__top]: isOpen })}>
							<ArrowSVG color={isOpen ? '#8dc640' : '#8A9099'} />
						</div>
					</div>
				</div>

				{!isVisible && !isPromo && (
					<div className={className(styles.promo_info__name, styles.promo_info__mobile)}>{DayName}</div>
				)}

				{isVisible && (
					<div
						className={className(styles.promo_card, {
							[styles.promo_card__event]: !isPromo,
						})}
						onTransitionEnd={endTransition}
						style={{ opacity: isTransition ? 1 : 0 }}
					>
						{!isPromo ? (
							<div className={styles.promo_info}>
								<div className={styles.promo_info__name}>{DayName}</div>
								<div className={styles.promo_info__info}>{Info}</div>
							</div>
						) : (
							<>
								<div className={styles.promo_card__sku}>SKU {GoodsId}</div>
								<div className={styles.promo_card__title}>{GoodsName}</div>

								<div className={styles.promo_card__info}>
									SKU {GoodsId} {GoodsName} запланировано для участия в ПРОМО
								</div>

								<div className={styles.promo_properties}>
									{properties.map(({ name, value }) => (
										<div className={styles.promo_property} key={name}>
											<div className={styles.promo_name}>{value}</div>
											<div className={styles.promo_value}>{event[name]}</div>
										</div>
									))}
								</div>
							</>
						)}
					</div>
				)}

				<div className={styles.promo_mobile__date}>{getDateEvent(Datex)}</div>
			</div>
		</OutsideClickHandler>
	);
};
