import React, { FC } from 'react';
import styles from './propItem.module.scss';
import { ReactComponent as ArrSVG } from 'assets/images/grouping/arrow.svg';
import { ReactComponent as CloseSVG } from 'assets/images/grouping/close.svg';

type TPropItem = {
	title: string;
	onClose: () => void;
};

export const PropItem: FC<TPropItem> = ({ title, onClose }) => (
	<div className={styles.box__result_item}>
		<div className={styles.box__result_text}>
			<ArrSVG />
			<div className={styles.box__result_title}>{title}</div>
		</div>

		<div className={styles.box__result_close} onClick={onClose}>
			<CloseSVG />
		</div>
	</div>
);
