import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const UseIsCookies = () => {
	const { pathname } = useLocation();
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			const params = localStorage.getItem('cookies');
			const isTrust = params ? JSON.parse(params).analytical === '' : !params;

			setIsVisible(isTrust && !pathname.includes('cookies'));
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return { isVisible };
};
