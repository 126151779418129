import React, { useCallback, useEffect } from 'react';
import { Toggle } from '../../Toggle';
import { IStoreState } from 'services/store/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import styles from './groupToggle.module.scss';
import { setGroupingProps, setIsGroupingOrders } from 'services/store/grouping';
import { setFiltersOrders } from 'services/store/filter';

const GroupToggle = () => {
	const { isGroupingOrders } = useSelector((state: IStoreState) => state.grouping);
	const { listOrders } = useSelector(
		(state: IStoreState) => state.filter,
		(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
	);
	const dispatch = useDispatch();

	const onClick = useCallback(() => {
		dispatch(setIsGroupingOrders(!isGroupingOrders));
		dispatch(setGroupingProps([]));
	}, [isGroupingOrders]);

	useEffect(() => {
		if (!isGroupingOrders) dispatch(setFiltersOrders({ filters: listOrders }));
	}, [isGroupingOrders]);

	return (
		<div className={styles.group}>
			<Toggle isOn={isGroupingOrders} onClick={onClick} />
			<div className={styles.group__text}>Группировка заказов</div>
		</div>
	);
};

export default GroupToggle;
