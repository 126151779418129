import React, { Fragment, useEffect } from 'react';
import { ActionMerge } from './ActionMerge';
import { ActionSeparate } from './ActionSeparate';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { ActionEditOrders } from './ActionEditOrders';
import { fetchISEnableActions } from 'services/store/multiorders';

export const EnableActions = () => {
	const activeOrders = useSelector((state: IStoreState) => state.orders.activeOrders);
	const isEnable = useSelector((state: IStoreState) => state.multiOrders.isEnableActions);
	const mode = useSelector((state: IStoreState) => state.orders.current);
	const isEnableMergeEdit = activeOrders.length > 1 && mode === '0';
	const isEnableSeparate = activeOrders.length === 1 && mode === '0';

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchISEnableActions({ OrderBuyGroupId: activeOrders }));
	}, [activeOrders]);

	return (
		<Fragment>
			{isEnableMergeEdit && isEnable && <ActionEditOrders />}
			{isEnableMergeEdit && isEnable && <ActionMerge />}
			{isEnableSeparate && isEnable && <ActionSeparate />}
		</Fragment>
	);
};
