import React from 'react';
import styles from './eplusBanner.module.scss';

export const EplusBanner = () => {
	return (
		<div className={styles.eplusBanner}>
			<img src="/assets/images/homepage/eplusBanner.svg" alt="" className={styles.eplusBanner__image} />
		</div>
	);
};
