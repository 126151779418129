import React, { FC } from 'react';
import styles from './Button.module.scss';
import { ReactComponent as ArrowSVG } from 'assets/images/actions/arrow_down.svg';
import classNames from 'classnames';

type TButtonProps = {
	onClick: () => void;
	isOpen: boolean;
};
export const Button: FC<TButtonProps> = ({ onClick, isOpen }) => {
	return (
		<div
			className={classNames(styles.button, {
				[styles.button_opened]: isOpen,
			})}
			onClick={onClick}
		>
			<div className={styles.button_text}>Операции с заказами</div>

			<div
				className={classNames(styles.button_arrow, {
					[styles.button_arrow__isClose]: isOpen,
				})}
			>
				<ArrowSVG />
			</div>
		</div>
	);
};
