import React from 'react';
import styles from './index.module.scss';
import { ReactComponent as LogoImg } from 'assets/images/header/logo.svg';
import { Callto } from '../Header/HeaderWithAuth/Callto';

export const Logo = () => (
	<div className={styles.logo}>
		<div className={styles.logo_image}>
			<a href="/home">
				<LogoImg />
			</a>
		</div>
		<div className={styles.logo_text}>
			<a href="/home">
				Личный кабинет <span className={styles.logo_text__add}> поставщика</span>
			</a>
		</div>

		<div className={styles.logo_phone}>
			<Callto type="email" email="zakaz_support@eurotorg.by">
				zakaz_support@eurotorg.by
			</Callto>
		</div>
	</div>
);
