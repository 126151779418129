import React, { Fragment, useEffect } from 'react';
import styles from '../infoTable.module.scss';
import { IStoreState, TCurrentGood } from 'services/store/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { QuantityGood } from 'components/Content/Orders/EditMany/QuantityGood';
import { fetchUpdateGoodsAfterSave, setQuantityOrder } from 'services/store/orders';
import classNames from 'classnames';

export const InfoOrderGoods = () => {
	const orderGoods = useSelector((state: IStoreState) => state.orders.currentGoods);
	const settingOrder = useSelector((state: IStoreState) => state.setting.listOrder);
	const currentOrderInfo = useSelector((state: IStoreState) => state.orders.currentInfo);
	const { savedOrder } = useSelector((state: IStoreState) => state.orders);

	const dispatch = useDispatch();

	const onChange = (OrderBuyId: string, QuantitySupplier: string, OrderId: string) => {
		dispatch(setQuantityOrder({ OrderBuyId, QuantitySupplier, OrderId, Info1: 'Изменение количества продукции' }));
	};

	useEffect(() => {
		if (!savedOrder) return;
		dispatch(fetchUpdateGoodsAfterSave());
	}, [savedOrder]);

	return (
		<Fragment>
			{orderGoods.map((good, index) => (
				<tr className={styles.tr} key={`${good.Barcode1}${orderGoods.length - index}`}>
					{settingOrder.map(({ id, isShow }) => {
						const isLimit = id === 'LimitQuantity';
						const isQuantity = id === 'QuantitySupplier' && currentOrderInfo.Checkx === '10';
						const isOthers = !isLimit && !isQuantity;
						const quantity = good.QuantitySupplier || '';
						const lineId = good.OrderBuyId || '';
						const inPack = good.QuantityMultiple || '';
						const isControlled = good.ControlWeight !== '1';
						const endQuantity = good.LimitQuantity || '100';
						const orderId = String(good.OrderBuyGroupId);

						return isShow ? (
							<td className={classNames(styles.td, { [styles.status]: isQuantity })} key={id}>
								{isLimit && endQuantity}

								{isQuantity && (
									<QuantityGood
										orderId={orderId}
										lineId={lineId}
										value={quantity}
										inPack={inPack}
										onChange={onChange}
										endQuantity={endQuantity}
										isControlled={isControlled}
										isEdit={true}
									/>
								)}

								{isOthers && good[id as keyof TCurrentGood]}
							</td>
						) : null;
					})}
				</tr>
			))}
		</Fragment>
	);
};
