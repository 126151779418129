import React from 'react';
import classNames from 'classnames';
import styles from './more.module.scss';
import { Arrow } from '../Arrow';

interface IMoreRowProps {
	isOpen: boolean;
	onClickMore: () => void;
	onClickDetail?: () => void;
}
export const More = ({ isOpen, onClickMore, onClickDetail }: IMoreRowProps) => {
	return (
		<div className={styles.open}>
			<div className={styles.open_button} onClick={onClickMore}>
				<span>{isOpen ? 'Скрыть' : 'Развернуть'}</span>
				<span
					className={classNames(styles.arrow, {
						[styles.arrow__transform]: isOpen,
					})}
				>
					<Arrow />
				</span>
			</div>

			{onClickDetail && (
				<div className={styles.open_details} onClick={onClickDetail}>
					Детали заказа
				</div>
			)}
		</div>
	);
};
