export const getYAxisValues = (start: number, end: number, step: number) => {
	let values: number[] = [];
	const resStep = step === 0 ? Math.floor(end / 8) : step;

	for (let i = start; i <= end + resStep; i += resStep) {
		values = [...values, i];
	}

	return values;
};
