import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	IReportsState,
	TDemandPlanResponse,
	TMonthDemandPlanPayload,
	TReportsAllGoodsItem,
	TReportsCurrentItem,
	TReportsFilters,
	TReportsQualityItem,
} from './interfaces/reports';
import { getDataReports } from '../api/getDataReports';
import { TFilterGoods, TReportFilterPlanning } from './interfaces';
import { getContractorGoods } from '../api/getContractorGoods';
import { getLastMonthDemandPlan } from '../api/getLastMonthDemandPlan';
import { getContractorDemandPlan } from '../api/getContractorDemandPlan';
import { formatDate } from '../utils/helpers/formatDate';
import { contractorIsAllowedDemandPlan } from '../api/contractorIsAllowedDemandPlan';

const initialState: IReportsState = {
	goodsInFilter: [],
	dateInFilterPlanning: formatDate(new Date()),
	dateActualPlan: '...',
	isPlanningTable: true,
	listSchedulePlanning: [],
	listTablePlanning: [],
	listAllGoods: [],
	listAllGoodsActive: [],
	countAllGoods: 0,
	listCurrent: [],
	listCurrentActive: [],
	countCurrent: 0,
	listQuality: [],
	listQualityActive: [],
	countQuality: 0,
	countPlanning: 0,
	loading: false,
	message: '',
};

export const fetchGetGoodsForFilter = createAsyncThunk('reports/fetchGetGoodsForFilter', async (data: TFilterGoods) => {
	return await getContractorGoods(data);
});

export const fetchGetMonthForFilter = createAsyncThunk(
	'reports/fetchGetMonthForFilter',
	async (data: TMonthDemandPlanPayload) => {
		return await getLastMonthDemandPlan(data);
	}
);
export const fetchGetReportsAllGoods = createAsyncThunk(
	'reports/fetchGetReportsAllGoods',
	async (data: TReportsFilters) => {
		return await getDataReports(data);
	}
);

export const fetchGetReportsCurrent = createAsyncThunk(
	'reports/fetchGetReportsCurrent',
	async (data: TReportsFilters) => {
		return await getDataReports(data);
	}
);

export const fetchGetReportsQuality = createAsyncThunk(
	'reports/fetchGetReportsQuality',
	async (data: TReportsFilters) => {
		return await getDataReports(data);
	}
);

export const fecthGetPlanningData = createAsyncThunk(
	'reports/fecthGetPlanningData',
	async (data: TReportFilterPlanning) => {
		return await getContractorDemandPlan(data);
	}
);

export const fetchGetIsAllowedDemand = createAsyncThunk(
	'reports/fetchGetIsAllowedDemand',
	async (data: TMonthDemandPlanPayload) => {
		return await contractorIsAllowedDemandPlan(data);
	}
);

const reports = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		setReportsAllGoodsActive: (state) => {
			const newSet = new Set([...state.listAllGoodsActive, ...state.listAllGoods.map(({ GoodsId }) => GoodsId)]);
			state.listAllGoodsActive = Array.from(newSet);
		},
		removeReportsAllGoodsActive: (state) => {
			const goodsIds = state.listAllGoods.map(({ GoodsId }) => GoodsId);
			state.listAllGoodsActive = state.listAllGoodsActive.filter((id) => !goodsIds.includes(id));
		},
		setReportsCurrentActive: (state) => {
			const newSet = new Set([...state.listCurrentActive, ...state.listCurrent.map(({ GoodsId }) => GoodsId)]);
			state.listCurrentActive = Array.from(newSet);
		},
		removeReportsCurrentActive: (state) => {
			const goodsIds = state.listCurrent.map(({ GoodsId }) => GoodsId);
			state.listCurrentActive = state.listCurrentActive.filter((id) => !goodsIds.includes(id));
		},
		removeReportsQualityActive: (state) => {
			const goodsIds = state.listQuality.map(({ OrderBuyGroupId }) => OrderBuyGroupId);
			state.listQualityActive = state.listQualityActive.filter((id) => !goodsIds.includes(id));
		},
		setReportsQualityActive: (state) => {
			const newSet = new Set([
				...state.listQualityActive,
				...state.listQuality.map(({ OrderBuyGroupId }) => OrderBuyGroupId),
			]);
			state.listQualityActive = Array.from(newSet);
		},
		addAllGoodsItem: (state, action) => {
			const activeIds = state.listAllGoodsActive;
			const goodId = action.payload.id;

			if (goodId && !activeIds.includes(goodId)) state.listAllGoodsActive = [...activeIds, goodId];
		},
		removeAllGoodsItem: (state, action) => {
			state.listAllGoodsActive = state.listAllGoodsActive.filter((id) => id !== action.payload.id);
		},
		addCurrentItem: (state, action) => {
			const activeIds = state.listCurrentActive;
			const goodId = action.payload.id;

			if (goodId && !activeIds.includes(goodId)) state.listCurrentActive = [...activeIds, goodId];
		},
		removeCurrentItem: (state, action) => {
			state.listCurrentActive = state.listCurrentActive.filter((id) => id !== action.payload.id);
		},
		addQualityItem: (state, action) => {
			const activeIds = state.listQualityActive;
			const id = action.payload.id;

			if (id && !activeIds.includes(id)) state.listQualityActive = [...activeIds, id];
		},
		removeQualityItem: (state, action) => {
			state.listQualityActive = state.listQualityActive.filter((id) => id !== action.payload.id);
		},
		setIsTablePlanning: (state, action) => {
			state.isPlanningTable = action.payload;

			window.scroll({ top: 0, behavior: 'smooth' });
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGetReportsAllGoods.pending, (state) => {
				state.listAllGoods = [];
				state.loading = true;
				state.message = '';
			})
			.addCase(fetchGetReportsAllGoods.fulfilled, (state, action) => {
				state.loading = false;
				const allGoodsList = action.payload as TReportsAllGoodsItem[];

				if (allGoodsList.length > 0 && !allGoodsList[0].ErrorDescription) {
					state.listAllGoods = allGoodsList;
					state.countAllGoods = Number(allGoodsList[0].Amount);
				}

				if (allGoodsList.length > 0 && allGoodsList[0].ErrorDescription) {
					state.listAllGoods = [];
					state.countAllGoods = 0;
					state.message = allGoodsList[0].ErrorDescription || 'Извините, произошла ошибка. Попробуйте позже...';
				}

				if (allGoodsList.length === 0) {
					state.listAllGoods = [];
					state.countAllGoods = 0;
				}
			})
			.addCase(fetchGetReportsAllGoods.rejected, (state) => {
				state.loading = false;
				state.countAllGoods = 0;
			})
			.addCase(fetchGetReportsCurrent.pending, (state) => {
				state.listCurrent = [];
				state.loading = true;
				state.message = '';
			})
			.addCase(fetchGetReportsCurrent.fulfilled, (state, action) => {
				state.loading = false;
				const currentList = action.payload as TReportsCurrentItem[];

				if (currentList.length > 0 && !currentList[0].ErrorDescription) {
					state.listCurrent = currentList;
					state.countCurrent = Number(currentList[0].Amount);
				}

				if (currentList.length > 0 && currentList[0].ErrorDescription) {
					state.listCurrent = [];
					state.countCurrent = 0;
					state.message = currentList[0].ErrorDescription || 'Извините, произошла ошибка. Попробуйте позже...';
				}

				if (currentList.length === 0) {
					state.listCurrent = [];
					state.countCurrent = 0;
				}
			})
			.addCase(fetchGetReportsCurrent.rejected, (state) => {
				state.loading = false;
				state.listCurrent = [];
				state.countCurrent = 0;
			})
			.addCase(fetchGetReportsQuality.pending, (state) => {
				state.listQuality = [];
				state.loading = true;
				state.message = '';
			})
			.addCase(fetchGetReportsQuality.fulfilled, (state, action) => {
				state.loading = false;
				const qualityList = action.payload as TReportsQualityItem[];

				if (qualityList.length > 0 && !qualityList[0].ErrorDescription) {
					state.listQuality = qualityList;
					state.countQuality = Number(qualityList[0].Amount);
				}

				if (qualityList.length > 0 && qualityList[0].ErrorDescription) {
					state.listQuality = [];
					state.countQuality = 0;
					state.message = qualityList[0].ErrorDescription || 'Извините, произошла ошибка. Попробуйте позже...';
				}

				if (qualityList.length === 0) {
					state.listQuality = [];
					state.countQuality = 0;
				}
			})
			.addCase(fetchGetReportsQuality.rejected, (state) => {
				state.loading = false;
				state.countQuality = 0;
			})
			.addCase(fecthGetPlanningData.pending, (state) => {
				state.loading = true;
			})
			.addCase(fecthGetPlanningData.fulfilled, (state, action) => {
				state.loading = false;
				const data = action.payload as TDemandPlanResponse;
				const scheduleItems = data.table2;
				const tableItems = data.table;

				if (tableItems?.length > 0 && !tableItems[0].ErrorDescription) {
					state.dateActualPlan = tableItems[0].CREATE_DATE;
					state.listTablePlanning = tableItems;
					state.listSchedulePlanning = scheduleItems;
					state.countPlanning = tableItems[0].Amount;
				}

				if (tableItems?.length > 0 && tableItems[0].ErrorDescription) {
					state.listTablePlanning = [];
					state.listSchedulePlanning = [];
					state.countPlanning = 0;
					state.message = tableItems[0].ErrorDescription || 'Извините, произошла ошибка. Попробуйте позже...';
				}

				if (tableItems?.length === 0) {
					state.listTablePlanning = [];
					state.listSchedulePlanning = [];
					state.message = 'Нет данных ...';
					state.countPlanning = 0;
				}
			})
			.addCase(fecthGetPlanningData.rejected, (state) => {
				state.loading = false;
				state.countPlanning = 0;
				state.message = 'Нет данных ...';
			})
			.addCase(fetchGetGoodsForFilter.pending, () => {})
			.addCase(fetchGetGoodsForFilter.fulfilled, (state, action) => {
				const data = action.payload;

				if (data[0].GoodsId)
					state.goodsInFilter = action.payload.map(({ GoodsId, GoodsName }) => ({
						id: GoodsId,
						text: GoodsName || '',
					}));
				if (!data[0].Id) state.goodsInFilter = [];
			})
			.addCase(fetchGetGoodsForFilter.rejected, (state) => {
				state.goodsInFilter = [];
			})
			.addCase(fetchGetMonthForFilter.pending, () => {})
			.addCase(fetchGetMonthForFilter.fulfilled, (state, action) => {
				if (action.payload[0] && action.payload[0].Last_Month) {
					state.dateInFilterPlanning = action.payload[0].Last_Month;
				}
			})
			.addCase(fetchGetMonthForFilter.rejected, () => {})
			.addCase(fetchGetIsAllowedDemand.pending, () => {})
			.addCase(fetchGetIsAllowedDemand.fulfilled, (state, action) => {
				if (action.payload[0]?.Result && action.payload[0]?.Result == 1) {
					localStorage.userIsAllowedDemandPlan = 'yes';
				} else {
					localStorage.userIsAllowedDemandPlan = 'no';
				}
				console.log('action.payload[0].Result === ', action.payload[0]?.Result == 1);
			})
			.addCase(fetchGetIsAllowedDemand.rejected, () => {});
	},
});

export const {
	addQualityItem,
	removeQualityItem,
	removeReportsQualityActive,
	setReportsQualityActive,
	setReportsCurrentActive,
	removeReportsCurrentActive,
	addCurrentItem,
	removeCurrentItem,
	addAllGoodsItem,
	removeAllGoodsItem,
	setReportsAllGoodsActive,
	removeReportsAllGoodsActive,
	setIsTablePlanning,
} = reports.actions;
export default reports.reducer;
