import React, { useMemo } from 'react';
import styles from './infoTable.module.scss';
import { useSelector } from 'react-redux';
import { IStoreState, TContentTable } from 'services/store/interfaces';
import { Message } from '../Common/Message';
import { InfoOrders } from './InfoOrders';
import { InfoGoods } from './InfoGoods';
import { InfoOrderGoods } from './InfoOrderGoods';
import { HeadTable } from './HeadTable';
import { InfoAllGoods } from './InfoAllGoods';
import { ReportsCurrent } from './InfoReportsCurrent';
import { ReportsQuality } from './InfoReportQuality';
import { motion, AnimatePresence } from 'framer-motion';
import { ReportsPlanningTable } from './InfoTablePlanning';

type TChangeFunc = (arg: boolean) => (arg: string) => void;

interface IInfoTableProps {
	onChange?: TChangeFunc;
	type: TContentTable;
	dragEffect?: boolean;
	onDrag?: (value: null | HTMLElement) => void;
}

const getInfoBody = (type: TContentTable, onChange: TChangeFunc) =>
	({
		order: <InfoOrderGoods />,
		orders: <InfoOrders onChange={onChange} />,
		goods: <InfoGoods onChange={onChange} />,
		allGoods: <InfoAllGoods />,
		current: <ReportsCurrent />,
		quality: <ReportsQuality />,
		planning: <ReportsPlanningTable />,
	}[type]);

export const InfoTable = ({
	type,
	onChange = () => () => {},
	dragEffect = false,
	onDrag = () => {},
}: IInfoTableProps) => {
	const { goodsList } = useSelector((state: IStoreState) => state.goods);
	const { message, ordersList, currentGoods } = useSelector((state: IStoreState) => state.orders);
	const { listAllGoods, listCurrent, listQuality, listTablePlanning } = useSelector(
		(state: IStoreState) => state.reports
	);

	const mapBodyTable = useMemo(
		() => ({
			order: currentGoods.length > 0,
			orders: ordersList.length > 0,
			goods: goodsList.length > 0,
			allGoods: listAllGoods.length > 0,
			current: listCurrent.length > 0,
			quality: listQuality.length > 0,
			planning: listTablePlanning.length > 0,
		}),
		[
			currentGoods.length,
			ordersList.length,
			goodsList.length,
			listAllGoods.length,
			listCurrent.length,
			listQuality.length,
			listTablePlanning.length,
		]
	);

	return mapBodyTable[type] ? (
		<AnimatePresence>
			<motion.table
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.3 }}
				className={styles.table}
			>
				<thead className={styles.tr}>
					<HeadTable type={type} dragEffect={dragEffect} onDrag={onDrag} />
				</thead>

				<tbody>{getInfoBody(type, onChange)}</tbody>
			</motion.table>
		</AnimatePresence>
	) : (
		<Message text={type === 'orders' ? message : ''} />
	);
};
