import React, { FC, useState, useEffect } from 'react';
import styles from './week.module.scss';
import { TDownTimesDataCount, TNumDay, TNumRequired } from 'services/store/interfaces/downTimes';
import { Day } from '../Day';
import { DAYS } from '../../../DatePanel/Month/data';
import { Down } from '../Down';
import { IStoreState } from 'services/store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentGroup } from 'services/store/downTimes';

type TWeekCalendarProps = {
	days: Array<TNumDay | TNumRequired>;
	downs: TDownTimesDataCount[];
	order: number;
};

export const Week: FC<TWeekCalendarProps> = ({ days, downs, order }) => {
	const { monthData, month } = useSelector((state: IStoreState) => state.downTimes);
	const [isVisible, setIsVisible] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (monthData.length === 0) {
			setIsVisible(false);
		}

		setTimeout(() => setIsVisible(true), 300);
	}, [JSON.stringify(monthData)]);

	const onEdit = (down: TDownTimesDataCount) => {
		const dayInfo = { Info: [down] };
		dispatch(setCurrentGroup({ dayInfo, type: 'info' }));
	};

	return (
		<div className={styles.week}>
			{DAYS.map((name, inx) => {
				const day = days.filter(({ day }) => day === name)[0];
				const isEdit = day && day.Info && day.Info.length === 1;

				return <Day month={month} day={day} key={inx} isEdit={Boolean(isEdit)} />;
			})}

			{downs.map((down, inx) => (
				<Down
					key={inx}
					days={days.map(({ num }) => num)}
					order={order}
					isVisible={isVisible}
					onEdit={() => onEdit(down)}
					{...down}
				/>
			))}
		</div>
	);
};
