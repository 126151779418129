import React from 'react';
import { FilterGoods } from 'components/Content/Goods/FilterGoods';
import { TFilterGoods } from 'services/store/interfaces';

interface IFilterOrderProps {
	onChange?: (fields: TFilterGoods) => void;
	onSet?: () => void;
}
export const FilterOrder = ({ onChange = () => {}, onSet = () => {} }: IFilterOrderProps) => {
	return <FilterGoods type="order" onChange={onChange} onSet={onSet} />;
};
