import React from 'react';
import styles from '../Actions/actions.module.scss';
import { Arrow } from '../InfoCard/Arrow';
import { DropButton } from '../Common/DropButton';

interface IBackItems {
	title: string;
	handler: () => void;
}
export const BackItems = ({ title, handler }: IBackItems) => {
	return (
		<div className={styles.back} onClick={handler}>
			<span className={styles.back_arrow}>
				<Arrow width={8} height={8} color="white" />
			</span>

			<DropButton text={title} onClick={() => {}} />
		</div>
	);
};
