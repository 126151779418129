import React, { Fragment } from 'react';
import { Footer } from '../index';
import { ActionButton } from 'components/Common/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { closeModal, fetchUpdateOrderBuyMulti } from 'services/store/multiorders';
import styles from './multiEditFooter.module.scss';
import { ModalW } from '../../Modals';

interface IMultiEditFooter {
	isEnable: boolean;
}
export const MultiEditFooter = ({ isEnable }: IMultiEditFooter) => {
	const quantities = useSelector((state: IStoreState) => state.multiOrders.quantityList);
	const isLoadingMulti = useSelector((state: IStoreState) => state.multiOrders.isLoadingMulti);
	const ordersIds = useSelector((state: IStoreState) => state.orders.activeOrders);
	const message = useSelector((state: IStoreState) => state.multiOrders.message);
	const errors = useSelector((state: IStoreState) => state.multiOrders.errors);

	const ordersCorrect = Object.values(errors).filter((isError) => !isError).length;
	const OrderBuys = quantities.filter((quantity) => quantity.QuantitySupplier !== '');
	const errorsUnits = Object.keys(errors).length;
	const isCorrect = errorsUnits === ordersCorrect;
	const isEmpty = OrderBuys.length === 0;

	const isActive = quantities.length > 0 && isCorrect && !isEmpty;
	const isOpenModal = message.text !== '';
	const isCentered = message.type === 'unsave';

	const dispatch = useDispatch();

	const onClose = () => dispatch(closeModal());
	const onSave = () => {
		dispatch(
			fetchUpdateOrderBuyMulti({ IsForDraft: '1', OrderBuyGroupId: ordersIds, OrderBuyGroupInfoId: '38', OrderBuys })
		);
	};

	return isEnable ? (
		<Fragment>
			<Footer text="Операции с выбран. заказами" visible={true} fixed={true}>
				<ActionButton
					text="Сохранить"
					type="save"
					onClick={onSave}
					mix={isActive ? styles.save : {}}
					disabled={!isActive || !isEnable}
				/>
			</Footer>

			<ModalW
				isOpen={isOpenModal}
				onConfirm={onSave}
				message={message.text}
				title={message.title}
				type={message.type}
				onCancel={onClose}
				isRight={!isCentered}
				isCentered={isCentered}
				isLoading={isLoadingMulti}
			/>
		</Fragment>
	) : null;
};
