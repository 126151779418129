import React from 'react';
import styles from './actions.module.scss';
import classNames from 'classnames';

interface IActionsProps {
	confirmButton?: React.ReactNode;
	clearButton?: React.ReactNode;
	closeButton?: React.ReactNode;
	disabled?: boolean;
}
export const Actions = ({ confirmButton, clearButton, closeButton, disabled = false }: IActionsProps) => {
	return (
		<div className={styles.actions}>
			<div className={styles.actions__top}>
				<div className={classNames(styles.actions__confirm, { [styles.actions__disable]: disabled })}>
					{confirmButton}
				</div>

				<div className={classNames(styles.actions__clear, { [styles.actions__disable]: disabled })}>{clearButton}</div>
			</div>

			{closeButton && <div className={styles.actions__bottom}>{closeButton}</div>}
		</div>
	);
};
