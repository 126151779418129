import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MONTHS } from 'components/Content/DownTimes/DatePanel/Month/data';
import { IDownTimesState, TDownTimesPayload, TUpdateDownTimesPayload } from './interfaces/downTimes';
import { getDataDownTimesMonth, getDataDownTimesYear } from '../api/getDowntimes';
import { removeDataDownTimes, updateDataDownTimes } from '../api/updateDowntimes';
import { AppDispatch } from './index';

const initialState: IDownTimesState = {
	mode: 'month',
	loading: true,
	month: MONTHS.filter(({ id }) => new Date().getMonth() === id)[0],
	year: new Date().getFullYear(),
	monthData: [],
	yearData: [],
	isOpenCreate: false,
	isOpenUpdate: false,
	isOpenInfo: false,
	currentGroup: null,
	message: [],
};

export const fetchGetDataDownTimesMonth = createAsyncThunk(
	'downTimes/fetchGetDataDownTimesMonth',
	async (data: TDownTimesPayload) => {
		return await getDataDownTimesMonth(data);
	}
);
export const fetchGetDataDownTimesYear = createAsyncThunk(
	'downTimes/fetchGetDataDownTimesYear',
	async (data: TDownTimesPayload) => {
		return await getDataDownTimesYear(data);
	}
);

export const fetchUpdateDowntimes = createAsyncThunk(
	'downTimes/fetchUpdateDowntimes',
	async (data: TUpdateDownTimesPayload) => {
		return await updateDataDownTimes(data);
	}
);

export const fetchUpdateDowntimesWithUpdate =
	(data: TUpdateDownTimesPayload, Year: string, Month: string, mode: string) => async (dispatch: AppDispatch) => {
		const res = await dispatch(fetchUpdateDowntimes(data));

		if (!res.payload && res.payload[0].Result !== 'Ok') return;

		if (mode == 'month') {
			dispatch(fetchGetDataDownTimesMonth({ Year, Month }));
		}

		if (mode == 'year') {
			dispatch(fetchGetDataDownTimesYear({ Year }));
		}
	};

export const fetchGetDowntimesWithAnimate =
	(Year: string, Month: string, mode: 'month' | 'year') => async (dispatch: AppDispatch) => {
		if (mode == 'month') {
			dispatch(setMonthDataEmpty());
			dispatch(fetchGetDataDownTimesMonth({ Year, Month }));
		}

		if (mode == 'year') {
			dispatch(setYearDataEmpty());
			dispatch(fetchGetDataDownTimesYear({ Year }));
		}
	};

export const fetchRemoveDowntimes = createAsyncThunk(
	'downTimes/fetchRemoveDowntimes',
	async (data: TUpdateDownTimesPayload) => {
		return await removeDataDownTimes(data);
	}
);

export const fetchRemoveWithUpdate =
	(data: TUpdateDownTimesPayload, Year: string, Month: string, isOne: boolean) => async (dispatch: AppDispatch) => {
		const res = await dispatch(fetchRemoveDowntimes(data));

		if (res.payload[0].Result === 'Ok') {
			dispatch(fetchGetDataDownTimesMonth({ Year, Month }));

			if (isOne)
				setTimeout(() => {
					dispatch(closeInfoModal());
				}, 2000);
		}
	};

const downTimes = createSlice({
	name: 'downTimes',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setMonth: (state, action) => {
			state.month = action.payload;
		},
		setMode: (state, action) => {
			state.mode = action.payload;
		},
		setMonthDataEmpty: (state) => {
			state.monthData = [];
		},
		setYearDataEmpty: (state) => {
			state.yearData = [];
		},
		setYear: (state, action) => {
			state.year = action.payload;
		},
		setCurrentMonth: (state, action) => {
			const { mode, month } = action.payload;

			state.mode = mode;
			state.month = month;
		},
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		setIsOpenCreate: (state, action) => {
			state.isOpenCreate = action.payload;
			state.currentGroup = null;
			state.message = [];
		},
		setIsOpenUpdate: (state, action) => {
			state.isOpenUpdate = action.payload;
		},
		setIsOpenInfo: (state, action) => {
			state.isOpenInfo = action.payload;
			state.message = [];
		},
		closeInfoModal: (state) => {
			state.currentGroup = null;
			state.isOpenInfo = false;
			state.message = [];
		},
		closeCreateModal: (state) => {
			state.currentGroup = null;
			state.isOpenCreate = false;
			state.message = [];
		},
		closeUpdateModal: (state) => {
			state.currentGroup = null;
			state.isOpenUpdate = false;
			state.message = [];
		},
		setCurrentGroup: (state, action) => {
			state.currentGroup = action.payload.dayInfo;

			if (action.payload.type === 'edit') {
				state.isOpenUpdate = true;
			}

			if (action.payload.type === 'create') {
				state.isOpenCreate = true;
			}

			if (action.payload.type === 'info') {
				state.isOpenInfo = true;
				state.isOpenUpdate = false;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchGetDataDownTimesMonth.pending, () => {});
		builder.addCase(fetchGetDataDownTimesMonth.fulfilled, (state, action) => {
			state.loading = false;

			if (!action.payload[0].Result && !action.payload[0].Error) {
				state.monthData = action.payload;
			}

			if (action.payload[0].Result || action.payload[0].Error) {
				state.monthData = [];
			}
		});
		builder.addCase(fetchGetDataDownTimesMonth.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(fetchGetDataDownTimesYear.pending, (state) => {
			state.yearData = [];
		});
		builder.addCase(fetchGetDataDownTimesYear.fulfilled, (state, action) => {
			state.loading = false;

			if (!action.payload[0].Result && !action.payload[0].Error) {
				state.yearData = action.payload;
			}

			if (action.payload[0].Result || action.payload[0].Error) {
				state.yearData = [];
			}
		});
		builder.addCase(fetchGetDataDownTimesYear.rejected, () => {});
		builder.addCase(fetchUpdateDowntimes.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(fetchUpdateDowntimes.fulfilled, (state, action) => {
			state.message = [];

			if (action.payload[0].Error && action.payload[0].ErrorDescription) {
				state.message = [{ type: 'error', text: 'Произошла ошибка, попробуйте позже...' }];
			}

			if (action.payload[0].Result === 99 && action.payload[0].ResultDescription) {
				state.message = [{ type: 'error', text: action.payload[0].ResultDescription }];
			}

			if (action.payload[0].Result === 'Ok' && action.payload[0].ResultDescription) {
				state.message = [{ type: 'success', text: action.payload[0].ResultDescription }];
			}

			state.loading = false;
		});
		builder.addCase(fetchUpdateDowntimes.rejected, (state) => {
			state.message = [...state.message, { type: 'error', text: 'Что-то пошле не так...' }];
		});
		builder.addCase(fetchRemoveDowntimes.pending, () => {});
		builder.addCase(fetchRemoveDowntimes.fulfilled, (state, action) => {
			state.message = [];

			if (action.payload[0].Result === 'Ok' && action.payload[0].ResultDescription && action.payload[0].DayId) {
				state.message = [
					...state.message,
					{ type: 'success', text: action.payload[0].ResultDescription, DayId: action.payload[0].DayId },
				];
			}

			if (action.payload[0].Result !== 'Ok' && action.payload[0].ResultDescription) {
				state.message = [...state.message, { type: 'error', text: action.payload[0].ResultDescription }];
			}
		});
		builder.addCase(fetchRemoveDowntimes.rejected, () => {});
	},
});

export const {
	setIsOpenUpdate,
	closeUpdateModal,
	setMessage,
	closeCreateModal,
	setLoading,
	setIsOpenInfo,
	closeInfoModal,
	setCurrentGroup,
	setIsOpenCreate,
	setMonth,
	setMode,
	setYear,
	setCurrentMonth,
	setMonthDataEmpty,
	setYearDataEmpty,
} = downTimes.actions;

export default downTimes.reducer;
