import React, { FC } from 'react';
import { MainLayout } from 'layouts/Main';
import { Cookies } from 'components/Cookies';

export const CookiesPage: FC = () => {
	return (
		<MainLayout>
			<Cookies />
		</MainLayout>
	);
};
