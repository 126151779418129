import { request } from 'services/utils/request';
import { TFilterOrders, TGetOrderBuyGroupTable } from 'services/store/interfaces';

export const getOrderBuyGroup = async (payload: TFilterOrders): Promise<TGetOrderBuyGroupTable> => {
	return await request.post(
		'SupplierPortal.GetOrderBuyGroup',
		{
			...payload,
		},
		localStorage.token
	);
};
