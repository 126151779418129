import React, { Fragment } from 'react';
import { setMessage } from 'services/store/divideOrders';
import { ActionButton } from 'components/Common/ActionButton';
import { useDispatch } from 'react-redux';
import useBreakpoint from 'services/hooks/useBreakpoint';

export const DivideAction = ({ disabled }: { disabled?: boolean }) => {
	const breakpoints = useBreakpoint();
	const dispatch = useDispatch();
	const isMobile = ['xs'].includes(breakpoints);

	return (
		<Fragment>
			<ActionButton
				text={isMobile ? 'Разъединить и вынести в новый заказ' : 'Разделить'}
				type="separate"
				onClick={() =>
					dispatch(
						setMessage({
							title: 'Вы собираетесь разделить заказ',
							text: 'Отмеченные товары будут перенесены в новый заказ, текущему заказу с остальными товарами будет также присвоен новый код.',
							type: 'divide',
						})
					)
				}
				disabled={disabled}
			/>
		</Fragment>
	);
};
