import React from 'react';
import styles from './spinner.module.scss';
import classNames from 'classnames';

interface ISpinnerProps {
	mix?: string;
	delay?: number;
	size?: number;
}

export const Spinner = ({ delay = 0, mix, size = 50 }: ISpinnerProps) => (
	<div
		className={classNames(styles.load, mix)}
		style={{ animationDuration: `${delay}s`, width: `${size}px`, height: `${size}px` }}
	>
		<span className={styles.inner} />
		<span className={styles.inner} />
		<span className={styles.inner} />
		<span className={styles.inner} />
	</div>
);
