import { createSlice } from '@reduxjs/toolkit';
import { ICookies } from './interfaces';
import { getDateAgo } from '../utils/helpers/getDateAgo';

const initialState: ICookies = {
	settings: localStorage.cookies ? JSON.parse(localStorage.cookies) : { analytical: '' },
	date: localStorage.startCookies,
};

const cookies = createSlice({
	name: 'cookies',
	initialState,
	reducers: {
		setSettings: (state, action) => {
			const isData = action.payload;

			if (isData) {
				state.settings = action.payload;
				localStorage.setItem('cookies', JSON.stringify(action.payload));
			} else {
				state.settings = { analytical: '' };
				localStorage.removeItem('cookies');
				localStorage.removeItem('startCookies');
			}
		},
		setDateCookie: (state) => {
			state.date = JSON.stringify(getDateAgo(0));
			localStorage.setItem('startCookies', state.date);
		},
	},
});

export const { setSettings, setDateCookie } = cookies.actions;
export default cookies.reducer;
