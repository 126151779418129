import React, { FC, useState, Fragment, useEffect } from 'react';
import styles from './export.module.scss';
import { Modal } from 'components/Mass/Modal';
import { Points } from 'components/Common/Loading/Points';
import { TError, TReportFilterPlanning, TReportsFilter } from 'services/store/interfaces';
import { ReactComponent as ExportSVG } from 'assets/images/actions/reports.svg';
import { removeObjKeys } from 'services/utils/helpers/removeObjKeys';
import { exportFile } from 'services/utils/js/exportToCSV';
import { getIsExcel, getJSON } from 'services/utils/helpers/getIsExcel';

interface IExportProp {
	filters: TReportsFilter | TReportFilterPlanning;
	text: string;
	filename: string;
	onExport: (filters: any) => Promise<any>;
	disabled: boolean;
}

export const Export: FC<IExportProp> = ({ filename, text, disabled, filters, onExport }) => {
	const [modal, setIsModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState(false);

	const onCloseModal = () => setIsModal(false);

	const onClick = () => {
		setIsLoading(true);

		onExport({ ...removeObjKeys(filters, ['PageNumber', 'Limit']) })
			.then((res) => res.blob())
			.then((blob) => {
				getIsExcel(blob)
					? exportFile(blob, filename)
					: getJSON(blob).then((err: TError) => console.log({ type: 'error', text: err.ErrorDescription }));
			})
			.then(() => setIsSuccess(true))
			.then(() => setIsLoading(false));
	};

	useEffect(() => {
		if (isSuccess) {
			setTimeout(onCloseModal);
			setTimeout(() => setIsLoading(false));
			setIsSuccess(false);
		}
	}, [isSuccess]);

	return (
		<Fragment>
			<div className={styles.export} onClick={() => (disabled ? {} : setIsModal(true))}>
				<ExportSVG />
				Экспорт
			</div>

			{modal && (
				<Modal title={text} description={`${text} может занять какое-то время`} onClose={onCloseModal}>
					<div className={styles.close} onClick={onCloseModal}>
						Закрыть
					</div>

					<div className={styles.confirm} onClick={onClick}>
						{isLoading ? <Points style={{ margin: 5 }} /> : 'Продолжить'}
					</div>
				</Modal>
			)}
		</Fragment>
	);
};
