export const getColumnsGroupData = () => {
	const items = document.querySelectorAll('[class*="withGroupInfoOrders_th"]');
	const props = document.querySelector('[class*="withGroupInfoOrders_tr"]');
	const check = document.querySelector('[class*="withGroupInfoOrders_check"]');

	const columns = Array.from(items);

	const points = columns.map((elem) => {
		const id = (elem as HTMLElement).dataset.id || '';
		const { width, left } = elem.getBoundingClientRect();

		return { id, width, left };
	});

	return {
		width: props?.getBoundingClientRect().width,
		points: [
			{ id: 'Left', width: check?.getBoundingClientRect().width, left: check?.getBoundingClientRect().left },
			...points,
		],
	};
};
