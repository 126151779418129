import React, { FC, Fragment, useState } from 'react';
import styles from './iconsMobile.module.scss';
import { Submenu } from '../../Submenu';
import { Nullable } from 'services/store/interfaces';
import classNames from 'classnames';
import { ReactComponent as HamburgerSVG } from 'assets/images/header/hamburger.svg';
import { ReactComponent as CloseSVG } from 'assets/images/header/close.svg';
import { ReactComponent as LogoSVG } from 'assets/images/header/logo_mini.svg';
import { MessagesMenu } from '../../MessagesMenu';
import { UserMenu } from '../../UserMenu';
import { EventsIcon } from '../EventsIcon';

const ICONSMENU = [
	{ id: 1, note: 'messages', element: <EventsIcon /> },
	{ id: 3, note: 'user', element: <HamburgerSVG /> },
];

const ICONSUBMENU = [
	{ id: 1, note: 'messages', element: <EventsIcon /> },
	{ id: 2, note: 'logo', element: <LogoSVG /> },
	{ id: 3, note: 'close', element: <CloseSVG /> },
];

interface IIconsItems {
	type: Nullable<string>;
	onChange: (value: Nullable<string>) => void;
}

const IconsItems: FC<IIconsItems> = ({ type, onChange }) => (
	<Fragment>
		{ICONSMENU.map(({ id, note, element }) => (
			<div
				key={id}
				className={classNames(styles.item, { [styles.active]: type === note })}
				onClick={() => onChange(note)}
			>
				{element}
			</div>
		))}
	</Fragment>
);

export const IconsMobile = () => {
	const [type, setTypeSubmenu] = useState<Nullable<string>>(null);

	return (
		<div className={styles.icons}>
			<IconsItems type={type} onChange={setTypeSubmenu} />

			<Submenu type={type}>
				<div className={styles.submenu}>
					{ICONSUBMENU.map(({ id, note, element }) => (
						<div
							key={id}
							className={classNames(styles.item)}
							onClick={() => {
								setTypeSubmenu(note);
							}}
						>
							{element}
						</div>
					))}
				</div>

				<div className={styles.submenu__list}>
					{type === 'user' && <UserMenu />}
					{type === 'messages' && <MessagesMenu />}
				</div>
			</Submenu>
		</div>
	);
};
