import React from 'react';
import styles from './editManyGroup.module.scss';
import { OrdersInfo } from './OrdersInfo';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { Redirect } from 'react-router-dom';

export const EditManyGroup = () => {
	const activeOrders = useSelector((state: IStoreState) => state.orders.activeOrders);
	const isEnable = useSelector((state: IStoreState) => state.multiOrders.isEnableActions);
	const isEnableEdit = activeOrders.length > 1 && isEnable;

	return isEnableEdit ? (
		<div className={styles.editManyBlock}>
			<OrdersInfo />
		</div>
	) : (
		<Redirect to="/orders" />
	);
};
