import React from 'react';
import styles from './activeOrders.module.scss';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { Order } from './Order';
import { AnimatePresence } from 'framer-motion';

export const ActiveOrders = () => {
	const ordersIdActive = useSelector((state: IStoreState) => state.orders.activeOrders);

	return (
		<AnimatePresence>
			<div className={styles.orders}>
				{ordersIdActive.map((text) => (
					<Order key={text} id={text} />
				))}
			</div>
		</AnimatePresence>
	);
};
