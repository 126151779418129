import React, { FC, PropsWithChildren, Fragment } from 'react';
import classNames from 'classnames';
import styles from './genericList.module.scss';
import { Toggle } from '../Toggle';
import { InputText } from '../Input/InputText';
import { InputSelect } from '../Input/InputSelect';
import { InputCheckbox } from '../Input/InputCheckbox';
export interface IList {
	text: string;
	id: string;
	onClick?: (id: string) => void;
	className?: string;
	As?: 'a' | 'li' | 'button' | 'div';
	href?: string;
	type?: string;
	isActive?: (id: string) => boolean;
}

interface IGenericListProps {
	list: IList[];
}

export const GenericList: FC<PropsWithChildren<IGenericListProps>> = ({ list, children }) => {
	return (
		<Fragment>
			{children}

			{list.map(({ As = 'div', isActive = () => true, type, text, className, id, onClick = () => {}, href }) => {
				const isToggle = type === 'toggle';
				const isCheckbox = type === 'checkbox';

				return (
					<As
						key={id}
						className={classNames(className, {
							[styles.toggle]: isToggle,
							[styles.checkbox]: isCheckbox,
							[styles.passive]: !isActive(id) && !isCheckbox,
						})}
						onClick={() => onClick(id)}
						href={href}
					>
						{type === 'checkbox' && <InputCheckbox isActive={isActive(id)} />}

						<div className={styles.text}>{text}</div>

						{type === 'toggle' && <Toggle isOn={isActive(id)} id={id} />}
						{type === 'select' && <InputSelect initial={{ id: '0', text: 'Выберите значение' }} />}
						{type === 'input' && <InputText />}
					</As>
				);
			})}
		</Fragment>
	);
};
