import React from 'react';
import { MainLayout } from 'layouts/Main';
import { Home } from 'components/Content/Home';

export const HomePage = () => {
	return (
		<MainLayout>
			<Home />
		</MainLayout>
	);
};
