import React from 'react';
import styles from './emptySupport.module.scss';
import EmptyImg from 'assets/images/admin/empty.png';
import useBreakpoint from 'services/hooks/useBreakpoint';

export const EmptySupport = () => {
	const breakpoints = useBreakpoint();
	const isMobile = ['xs'].includes(breakpoints);

	return (
		<div className={styles.emptySupport}>
			<p className={styles.emptySupport__text}>Новых обращений нет</p>

			<div className={styles.emptySupport__image}>
				<img src={EmptyImg} width={isMobile ? 200 : 300} height={isMobile ? 120 : 180} alt="Список обращений пуст" />
			</div>
		</div>
	);
};
