import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IStoreState } from '../store/interfaces';
import { useSelector } from 'react-redux';

export const useHiddenScroll = () => {
	const { type } = useSelector((state: IStoreState) => state.events);
	const { mode } = useSelector((state: IStoreState) => state.downTimes);
	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname.includes('events') && type === 'downTimes' && mode === 'month') {
			document.body.style.height = '100vh';
			document.body.style.overflowY = 'hidden';
			document.body.style.overflowX = 'hidden';
		} else {
			document.body.style.height = 'unset';
			document.body.style.overflowY = 'unset';
			document.body.style.overflowX = 'unset';
		}
	}, [pathname, type, mode]);
};
