import React from 'react';
import { ReactComponent as SupportSVG } from 'assets/images/sidebar/support.svg';
import styles from '../userMenu.module.scss';

interface ISupportProps {
	title: string;
	onClick: () => void;
}
export const Support = ({ title, onClick }: ISupportProps) => {
	return (
		<a className={styles.list__item} onClick={onClick}>
			<SupportSVG />

			<span className={styles.list__item_text}>{title}</span>
		</a>
	);
};
