import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import jwtReducer from './jwt';
import userReducer from './user';
import goodsReducer from './goods';
import ordersReducer from './orders';
import settingReducer from './setting';
import filterReducer from './filter';
import eventsReducer from './events';
import notificationsReducer from './notifications';
import multiOrdersReducer from './multiorders';
import divideOrdersReducer from './divideOrders';
import supportReducer from './support';
import outsideReducer from './outside';
import errorsReducer from './errors';
import addressReducer from './address';
import cookiesReducer from './cookies';
import reportsReducer from './reports';
import downTimesReducer from './downTimes';
import groupingReducer from './grouping';

const store = configureStore({
	reducer: {
		jwt: jwtReducer,
		user: userReducer,
		goods: goodsReducer,
		orders: ordersReducer,
		setting: settingReducer,
		filter: filterReducer,
		events: eventsReducer,
		support: supportReducer,
		notifications: notificationsReducer,
		outside: outsideReducer,
		multiOrders: multiOrdersReducer,
		divideOrders: divideOrdersReducer,
		errors: errorsReducer,
		address: addressReducer,
		cookies: cookiesReducer,
		reports: reportsReducer,
		downTimes: downTimesReducer,
		grouping: groupingReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			thunk: {
				extraArgument: {
					url: 'https://jsonplaceholder.typicode.com/',
					version: '1',
				},
			},
		}),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ExtraParams = { url: string; version: string };
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, ExtraParams, any>;
