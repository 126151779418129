import React, { FC } from 'react';
import { TSVGProps } from './PromoSVG';

export const CalendarSVG: FC<TSVGProps> = ({ width = 16, height = 16, color }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.9243 0C11.2833 0 11.5743 0.291016 11.5743 0.650002V1.63311H12.6368C13.9121 1.63311 14.9992 2.6266 14.9992 3.91622V7.18243V13.7149C14.9992 15.0045 13.9121 15.998 12.6368 15.998H2.36239C1.08706 15.998 0 15.0045 0 13.7149V7.18243V3.91622C0 2.6266 1.08706 1.63311 2.36239 1.63311H3.42472V0.650002C3.42472 0.291016 3.71573 0 4.07472 0C4.4337 0 4.72472 0.291016 4.72472 0.650002V1.63311H10.2743V0.650002C10.2743 0.291016 10.5653 0 10.9243 0ZM13.6992 3.91622V6.53243H1.3V3.91622C1.3 3.40196 1.74627 2.93311 2.36239 2.93311H3.42472V3.91622C3.42472 4.2752 3.71573 4.56622 4.07472 4.56622C4.4337 4.56622 4.72472 4.2752 4.72472 3.91622V2.93311H10.2743V3.91622C10.2743 4.2752 10.5653 4.56622 10.9243 4.56622C11.2833 4.56622 11.5743 4.2752 11.5743 3.91622V2.93311H12.6368C13.2529 2.93311 13.6992 3.40196 13.6992 3.91622ZM13.6992 7.83243H1.3V13.7149C1.3 14.2291 1.74627 14.698 2.36239 14.698H12.6368C13.2529 14.698 13.6992 14.2291 13.6992 13.7149V7.83243ZM4 9H6C6.55228 9 7 9.44772 7 10V12C7 12.5523 6.55228 13 6 13H4C3.44772 13 3 12.5523 3 12V10C3 9.44772 3.44772 9 4 9Z"
				fill={color}
			/>
		</svg>
	);
};
