import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import styles from './slider.module.scss';
import classNames from 'classnames';
import { ReactComponent as ArrowPrev } from 'assets/images/slider/prev.svg';
import { ReactComponent as ArrowNext } from 'assets/images/slider/next.svg';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import { useWithHelp } from 'services/hooks/useWithHelp';

const step = 70;
export const Slider: FC<PropsWithChildren> = ({ children }) => {
	const activeItems = useSelector((state: IStoreState) => state.orders.activeOrders);
	const [count, setCount] = useState(activeItems.length);
	const [positionSlider, setPositionSlider] = useState(0);
	const [isSlider, setIsShowSlider] = useState(false);
	const [isDisableArrow, setIsDisableArrow] = useState(false);
	const list = useRef<HTMLUListElement>(null);
	const withHelp = useWithHelp();

	useEffect(() => {
		if (!list.current) return;
		setCount(activeItems.length);

		const scrollWidth = list.current.scrollWidth;
		const listWidth = list.current.offsetWidth;

		setIsShowSlider(scrollWidth > 0.6 * window.innerWidth);
		setIsDisableArrow(listWidth - scrollWidth === positionSlider || listWidth - scrollWidth > positionSlider);
	});

	useEffect(() => {
		if (!isSlider) setPositionSlider(0);
	}, [isSlider]);

	useEffect(() => {
		if (!isSlider || !list.current) return;

		const scrollWidth = list.current.scrollWidth;
		const listWidth = list.current.offsetWidth;

		if (count > activeItems.length) {
			setPositionSlider(listWidth - positionSlider > scrollWidth ? listWidth - scrollWidth : positionSlider);
		}

		if (count < activeItems.length) {
			setPositionSlider(
				listWidth + Math.abs(positionSlider) + activeItems.length * 40 > scrollWidth
					? listWidth - scrollWidth
					: positionSlider
			);
		}
	}, [activeItems.length]);

	return (
		<div
			className={classNames(styles.slider, {
				[styles.slider_visible]: isSlider,
			})}
			style={{ zIndex: withHelp ? 0 : 1000 }}
		>
			<button
				onClick={(e) => {
					e.stopPropagation();

					if (!isDisableArrow) setPositionSlider((prev) => prev - step);
				}}
				className={classNames(styles.slider_button, styles.slider_button_prev, {
					[styles.visible]: isSlider,
				})}
				style={{ zIndex: withHelp ? 0 : 100 }}
				disabled={isDisableArrow}
			>
				<ArrowPrev />
			</button>

			<div
				className={classNames(styles.slider_block, {
					[styles.slider_block__visible]: isSlider,
				})}
			>
				<ul
					ref={list}
					className={styles.slider_list}
					style={{
						transform: `translate(${positionSlider}px, 0px)`,
						transition: `all .7s ease`,
					}}
				>
					{children}
				</ul>
			</div>

			<button
				onClick={(e) => {
					e.stopPropagation();
					if (positionSlider !== 0) setPositionSlider((prev) => prev + step);
				}}
				className={classNames(styles.slider_button, styles.slider_button_next, {
					[styles.visible]: isSlider,
				})}
				disabled={positionSlider + step / 2.6 > 0}
			>
				<ArrowNext />
			</button>
		</div>
	);
};
