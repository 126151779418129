import { request } from 'services/utils/request';
import { TCancelOrderPayload, TCancelConfirmOrderResponse } from 'services/store/interfaces';

export const cancelOrderBuyGroup = async (payload: TCancelOrderPayload): Promise<TCancelConfirmOrderResponse[]> => {
	return await request.post(
		'SupplierPortal.CancelOrderBuyGroup',
		{
			...payload,
		},
		localStorage.token
	);
};
