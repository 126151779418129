import React, { FC, PropsWithChildren, useState, useEffect } from 'react';
import GroupsContext from '../contexts/GroupsContext';
import { TCoordinate } from '../contexts/GroupsContext';
import { getColumnsGroupData } from '../utils/react/getColumnsGroupData';
import { IStoreState } from '../store/interfaces';
import { useSelector } from 'react-redux';

export const GroupsProvider: FC<PropsWithChildren<NonNullable<unknown>>> = ({ children }) => {
	const { listOrders } = useSelector((state: IStoreState) => state.setting);
	const [coordinates, setPoints] = useState<Array<TCoordinate>>([{ id: '', width: 0, left: 0 }]);
	const [width, setWidth] = useState<string>('auto');

	const setCoordinates = (arr: TCoordinate[]) => setPoints([...arr]);
	const setAllWidth = (value: string) => setWidth(value);

	const setDataContext = () => {
		const res = getColumnsGroupData();

		if (res) {
			setCoordinates(res.points as TCoordinate[]);
			setAllWidth(String(res.width));
		}
	};

	useEffect(() => {
		setDataContext();

		window.addEventListener('resize', setDataContext, true);

		return () => window.removeEventListener('resize', setDataContext, true);
	}, []);

	useEffect(() => {
		setDataContext();
	}, [JSON.stringify(listOrders)]);

	return (
		<GroupsContext.Provider value={{ width, coordinates, setCoordinates, setAllWidth }}>
			{children}
		</GroupsContext.Provider>
	);
};
