import React, { FC, useRef, useState, useEffect } from 'react';
import styles from './down.module.scss';
import { useSizesDayCalendar } from 'services/hooks/useHeightDayCalendar';
import classNames from 'classnames';
import { getPeriod, getStyles, removeStyle, setStyle } from 'services/utils/helpers/stylesDownTimesGroup';

type TDownProps = {
	DateStart: string;
	DateEnd: string;
	Reason: string;
	days: number[];
	Days: number[];
	currCount: number;
	DayId: string;
	order: number;
	isOne: boolean;
	isVisible: boolean;
	onEdit: () => void;
	prevIndex: number;
};

export const Down: FC<TDownProps> = ({
	DayId,
	currCount,
	DateStart,
	DateEnd,
	Reason,
	Days,
	days,
	order,
	isOne,
	isVisible,
	onEdit,
	prevIndex,
}) => {
	const [height, setHeight] = useState<number>();
	const ref = useRef<HTMLDivElement>(null);
	const size = useSizesDayCalendar();

	useEffect(() => {
		if (ref.current) setHeight(ref.current.offsetHeight);
	}, [ref.current]);

	return (
		<div
			ref={ref}
			data-id={DayId}
			className={classNames(styles.down, {
				[styles.down_one]: isOne,
			})}
			onMouseOver={() => setStyle(DayId)}
			onMouseOut={() => removeStyle(DayId)}
			onClick={onEdit}
			style={
				isVisible
					? {
							...getStyles(isOne, Days, Number(height), days, currCount, Number(size?.width || 0), order, prevIndex),
							opacity: 1,
					  }
					: {
							...getStyles(isOne, Days, Number(height), days, currCount, Number(size?.width || 0), order, prevIndex),
							opacity: 0,
					  }
			}
		>
			<div className={styles.text}>
				{DateStart !== DateEnd && getPeriod(DateStart, DateEnd)} {Reason}
			</div>
		</div>
	);
};
