import React, { Fragment } from 'react';
import { IStoreState, TCurrentOrder } from 'services/store/interfaces';
import styles from '../infoCard.module.scss';
import classNames from 'classnames';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';
import { useSelector } from 'react-redux';
import { Cards } from './Cards';

interface IOrdersCardsProps {
	setActive: (value: string) => void;
	onChange: (value: boolean) => (value: string) => void;
	count: number;
}
export const OrdersCards = ({ onChange, setActive, count }: IOrdersCardsProps) => {
	const ordersIdActive = useSelector((state: IStoreState) => state.orders.activeOrders);
	const removedOrder = useSelector((state: IStoreState) => state.orders.cancelOrder);
	const ordersList = useSelector((state: IStoreState) => state.orders.ordersList);

	return (
		<Fragment>
			{ordersList.map((item: TCurrentOrder, index) => {
				const { OrderBuyGroupId } = item;

				return index < count ? (
					<div
						className={classNames(styles.item, {
							[styles.item__hidden]: removedOrder && removedOrder?.includes(String(OrderBuyGroupId)),
						})}
						key={index}
					>
						<div className={styles.property}>
							<div className={classNames(styles.name, styles.header)}>
								Заказ № <span className={styles.header__lacky}>{item.OrderBuyGroupId}</span>
							</div>
							<div className={styles.value}>
								<InputCheckbox
									isActive={ordersIdActive.includes(String(item.OrderBuyGroupId))}
									onChange={(isActive) => onChange(isActive)(String(item.OrderBuyGroupId))}
								/>
							</div>
						</div>

						<Cards order={item} setActive={setActive} />
					</div>
				) : null;
			})}
		</Fragment>
	);
};
