import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const Portal: FC<PropsWithChildren<object>> = ({ children }) => {
	const [modal, setModal] = useState<HTMLElement>();

	useEffect(() => {
		const modal = document.getElementById('modal-root');
		if (modal) setModal(modal);
	}, []);

	return modal ? createPortal(children, modal) : null;
};
