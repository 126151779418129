import React, { Fragment } from 'react';
import { IStoreState, TCurrentGood } from 'services/store/interfaces';
import classNames from 'classnames';
import styles from '../infoCard.module.scss';
import { PropertyBlock } from '../PropertyBlock';
import { useDispatch, useSelector } from 'react-redux';
import { QuantityGood } from 'components/Content/Orders/EditMany/QuantityGood';
import { setQuantityOrder } from 'services/store/orders';

interface IOrderCardsProps {
	count: number;
}

export const OrderCards = ({ count }: IOrderCardsProps) => {
	const orderGoodsList = useSelector((state: IStoreState) => state.orders.currentGoods);
	const settingsOrder = useSelector((state: IStoreState) => state.setting.listOrder);
	const currentOrder = useSelector((state: IStoreState) => state.orders.current);
	const currentOrderInfo = useSelector((state: IStoreState) => state.orders.currentInfo);
	const dispatch = useDispatch();

	const onChange = (OrderBuyId: string, QuantitySupplier: string, OrderId: string) => {
		dispatch(setQuantityOrder({ OrderBuyId, QuantitySupplier, OrderId, Info1: 'Изменение количества продукции' }));
	};

	return (
		<Fragment>
			{orderGoodsList.map((item: TCurrentGood, index) => {
				return index < count ? (
					<div
						className={classNames(styles.item, styles.order)}
						key={`${item.Barcode1}${orderGoodsList.length - index}`}
					>
						<div className={styles.property}>
							<div className={classNames(styles.name, styles.header)}>{item.GoodsName}</div>

							{/*<div className={styles.value}>*/}
							{/*<InputCheckbox*/}
							{/*    isActive={ordersIdActive.includes(String(item.GoodsId))}*/}
							{/*    onChange={(isActive) => mapping[type](isActive)(String(item.GoodsId))}*/}
							{/*/>*/}
							{/*</div>*/}
						</div>

						{settingsOrder.map(({ id, isShow, text }) => {
							const isQuantity = id === 'QuantitySupplier' && currentOrderInfo.Checkx === '10';
							const quantity = item.QuantitySupplier || '';
							const lineId = item.OrderBuyId || '';
							const inPack = item.QuantityMultiple || '';
							const limitQuantity = item.LimitQuantity || '100';
							const isControlled = item.ControlWeight !== '1';

							const value = isQuantity ? (
								<QuantityGood
									lineId={lineId}
									orderId={currentOrder}
									endQuantity={limitQuantity}
									value={quantity}
									inPack={inPack}
									onChange={onChange}
									isControlled={isControlled}
									isMobile={true}
									isEdit={true}
								/>
							) : (
								item[id as keyof TCurrentGood]
							);

							return isShow && id !== 'GoodsName' ? (
								<PropertyBlock key={id} name={text} value={value} isQuantity={isQuantity} />
							) : null;
						})}
					</div>
				) : null;
			})}
		</Fragment>
	);
};
