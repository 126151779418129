import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IGroupingState, TFilterOrders } from './interfaces';
import { getOrderBuyGroup } from '../api/getOrderBuyGroup';
import { setCancelOrder, setConfirmOrder, setOrders, setReviseOrder } from './orders';
import { AppState } from './index';
import { TPropItemList } from 'components/HOCS/WithGroupInfoOrders';

const initialState: IGroupingState = {
	isStartGroup: true,
	isGroupingOrders: false,
	groupingProps: [],
	groups: [],
	isLoading: false,
	isRemovingOrder: false,
	message: null,
};

export const fetchOrderBuyGroupWithGroup = createAsyncThunk(
	'grouping/fetchOrderBuyGroupWithGroup',
	async (data: TFilterOrders, ThunkAPI) => {
		const { filter } = ThunkAPI.getState() as unknown as AppState;

		getOrderBuyGroup({ ...filter.listOrders, IsGrouping: '0', GroupParamLevel: [] }).then((res) =>
			ThunkAPI.dispatch(setOrders(res.table))
		);

		return await getOrderBuyGroup(data);
	}
);

export const fetchUpdateListOrdersAndGroups = createAsyncThunk(
	'grouping/updateGroupsListOrders',
	async (_, ThunkAPI) => {
		const { filter, grouping } = ThunkAPI.getState() as unknown as AppState;
		ThunkAPI.dispatch(setIsRemovingOrder(true));

		await getOrderBuyGroup({
			...filter.listOrders,
			IsGrouping: '1',
			GroupParamLevel: (grouping.groupingProps as TPropItemList[]).map(({ id }) => id),
		})
			.then((res) => ThunkAPI.dispatch(setGroups(res.table)))
			.then(() => ThunkAPI.dispatch(setCancelOrder()))
			.then(() =>
				document.querySelector("[class*='actions']")?.scrollIntoView({ behavior: 'smooth', block: 'center' })
			);

		await getOrderBuyGroup({
			...filter.listOrders,
			IsGrouping: '0',
			GroupParamLevel: [],
		})
			.then((res) => ThunkAPI.dispatch(setOrders(res.table)))
			.then(() => ThunkAPI.dispatch(setIsRemovingOrder(false)));
	}
);

export const fetchUpdateOrdersAfterRewise = createAsyncThunk(
	'grouping/fetchUpdateOrdersAfterRewise',
	async (id: string, ThunkAPI) => {
		const { filter } = ThunkAPI.getState() as unknown as AppState;

		return await getOrderBuyGroup({
			...filter.listOrders,
			OrderBuyGroupId: [],
			IsGrouping: '0',
			GroupParamLevel: [],
		}).then((res) => {
			ThunkAPI.dispatch(setOrders(res.table));
			ThunkAPI.dispatch(setReviseOrder());

			return res.table.filter(({ OrderBuyGroupId }) => id === String(OrderBuyGroupId))[0];
		});
	}
);

export const fetchUpdateOrdersAfterConfirm = createAsyncThunk(
	'grouping/fetchUpdateOrdersAfterConfirm',
	async (id: string, ThunkAPI) => {
		const { filter } = ThunkAPI.getState() as unknown as AppState;

		return await getOrderBuyGroup({
			...filter.listOrders,
			OrderBuyGroupId: [],
			IsGrouping: '0',
			GroupParamLevel: [],
		}).then((res) => {
			ThunkAPI.dispatch(setOrders(res.table));
			ThunkAPI.dispatch(setConfirmOrder());

			return res.table.filter(({ OrderBuyGroupId }) => id === String(OrderBuyGroupId))[0];
		});
	}
);

const grouping = createSlice({
	name: 'grouping',
	initialState,
	reducers: {
		setIsGroupingOrders: (state, action) => {
			state.isGroupingOrders = action.payload;
		},
		setGroupingProps: (state, action) => {
			state.groupingProps = [...action.payload];
		},
		setGroups: (state, action) => {
			state.groups = action.payload;
		},
		setIsStartGroup: (state, action) => {
			state.isStartGroup = action.payload;
		},
		setIsRemovingOrder: (state, action) => {
			state.isRemovingOrder = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchOrderBuyGroupWithGroup.pending, (state) => {
				state.message = null;
				state.isLoading = true;
			})
			.addCase(fetchOrderBuyGroupWithGroup.fulfilled, (state, action) => {
				const data = action.payload;
				state.isLoading = false;

				if (!data.table) {
					state.message = 'Проверьте правильность введенных данных ...';
					return;
				}

				const result = data.table;

				if (result.length === 0) {
					state.message = 'У вас нет заказов ...';
					return;
				}

				if (result && !result[0]?.Error) {
					state.groups = data.table;
				}

				if (result && result[0]?.Error) {
					state.message = 'Нет заказов для группировки...';
				}
			})
			.addCase(fetchOrderBuyGroupWithGroup.rejected, (state) => {
				state.isLoading = false;
				state.message = 'Нет заказов для группировки...';
			});
	},
});

export const { setIsRemovingOrder, setIsStartGroup, setGroups, setIsGroupingOrders, setGroupingProps } =
	grouping.actions;
export default grouping.reducer;
