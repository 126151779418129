import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISupportState, TSupport } from './interfaces';
import { getSupportRequestList } from '../api/getSupportRequestList';
import { updateSupportRequest } from '../api/updateSupportRequest';

const initialState: ISupportState = {
	list: [],
	error: '',
	adminJWT: '',
	loading: false,
	isAdmin: null,
};

export const fetchGetSupportRequestList = createAsyncThunk('support/fetchGetSupportRequestList', async () => {
	return await getSupportRequestList();
});

export const fetchUpdateSupportRequest = createAsyncThunk(
	'support/fetchUpdateSupportRequest',
	async (data: TSupport) => {
		return await updateSupportRequest(data);
	}
);

const support = createSlice({
	name: 'support',
	initialState,
	reducers: {
		setAdminMode: (state) => {
			state.isAdmin = true;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGetSupportRequestList.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchGetSupportRequestList.fulfilled, (state, action) => {
				const data = action.payload;

				if (data.length === 0) {
					state.isAdmin = true;
					state.list = [];
				}

				if (data[0]?.Error) {
					state.isAdmin = false;
				}

				if (data[0]?.ContractorId) {
					state.adminJWT = localStorage.getItem('token');

					state.list = data;
					state.isAdmin = true;
				}

				state.loading = false;
			})
			.addCase(fetchGetSupportRequestList.rejected, () => {})
			.addCase(fetchUpdateSupportRequest.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchUpdateSupportRequest.fulfilled, (state, action) => {
				const data = action.payload;

				if (data[0].Result) {
					localStorage.setItem('activeSession', 'yes');
					state.isAdmin = false;
				}

				state.loading = false;
			})
			.addCase(fetchUpdateSupportRequest.rejected, () => {});
	},
});

export default support.reducer;
