import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { RequestItem } from './RequestItem';
import { fetchUpdateSupportRequest } from 'services/store/support';
import { setSuperToken } from 'services/store/jwt';

export const RequestList = () => {
	const requests = useSelector((state: IStoreState) => state.support.list);
	const dispatch = useDispatch();

	const supportClick = (ContractorId: string, token: string) => {
		dispatch(fetchUpdateSupportRequest({ ContractorId }));
		dispatch(setSuperToken(token));
	};

	return (
		<Fragment>
			{requests.map(({ ContractorJWT, ContractorId, ContractorName, Timex }, index) => (
				<RequestItem
					key={index}
					title={ContractorName}
					date={Timex}
					onClick={() => supportClick(String(ContractorId), String(ContractorJWT))}
				/>
			))}
		</Fragment>
	);
};
