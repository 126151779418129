import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IDivideOrderState, TDividePayload, TFilterOrders } from './interfaces';
import { divideOrderBuy } from '../api/divideOrderBuy';
import { getOrderBuy } from '../api/getOrderBuy';
import { groupByIdOrder } from '../utils/js/groupByDate';

const initialState: IDivideOrderState = {
	orderBuyIdList: [],
	quantityList: [],
	message: { text: '', type: 'success', links: '' },
	ordersInfo: [],
	goodsInfo: [],
	allData: [],
	isLoading: false,
	isLoadingDivide: false,
	isDivided: false,
};

export const fetchDivideOrderBuy = createAsyncThunk(
	'divideOrders/fetchDivideOrderBuy',
	async (data: TDividePayload) => {
		return await divideOrderBuy(data);
	}
);

export const fetchGetOrderBuy = createAsyncThunk('divideOrders/fetchGetOrderBuy', async (data: TFilterOrders) => {
	return await getOrderBuy({ ...data, Limit: 150 });
});

const divideOrders = createSlice({
	name: 'divideOrders',
	initialState,
	reducers: {
		addGoodItem: (state, action) => {
			if (action.payload) state.orderBuyIdList = [...state.orderBuyIdList, action.payload];
		},
		removeGoodItem: (state, action) => {
			state.orderBuyIdList = state.orderBuyIdList.filter((id: string) => id !== action.payload);
		},
		setAllActiveItem: (state, action) => {
			const idsGroup = state.goodsInfo.filter((group) => group[0].OrderBuyGroupId === action.payload);
			state.orderBuyIdList = idsGroup[0].map((good) => String(good.OrderBuyId));
		},
		removeAllActiveItem: (state) => {
			state.orderBuyIdList = [];
			state.quantityList = [];
		},
		closeModal: (state) => {
			state.message = { ...state.message, text: '', links: '' };
		},
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		setDivided: (state, action) => {
			state.isDivided = action.payload;
			state.message = { text: '', type: '', links: '' };
		},
		setDefault: (state) => {
			state.isDivided = false;
			state.ordersInfo = [];
			state.goodsInfo = [];
		},
		setQuantityDivideEmpty: (state) => {
			state.quantityList = [];
		},
		setQuantityDivideRow: (state, action) => {
			const data = action.payload;
			// state.quantityList = [...state.quantityList, data];

			const filterList = state.quantityList.filter((order) => order.OrderBuyId !== data.OrderBuyId);
			state.quantityList = [...filterList, data];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchDivideOrderBuy.pending, (state) => {
			state.isLoadingDivide = true;
		});
		builder.addCase(fetchDivideOrderBuy.fulfilled, (state, action) => {
			const data = action.payload[0];
			state.isLoadingDivide = false;

			const isError = (data.Result === '99' && data.ResultDescription) || data.Error;

			if (!data.ErrorDescription && data.Result !== '99') {
				const orders = action.payload
					.filter(({ Checkx }) => Checkx === '10')
					.map(({ OrderBuyGroupId }) => OrderBuyGroupId);

				const links = orders.join(' ');

				state.message = { text: 'Заказ разделен успешно.', type: 'success', links };
				state.isDivided = true;
				state.orderBuyIdList = [];
			}

			if (isError) {
				state.message = { text: data.ResultDescription || data.ErrorDescription || 'Ошибка', type: 'error' };
				state.isDivided = false;
			}
		});
		builder.addCase(fetchDivideOrderBuy.rejected, (state) => {
			state.isLoadingDivide = false;
			state.isDivided = false;
			state.message = { text: 'Техническая ошибка! Обратитесь в колл-центр', type: 'error' };
		});
		builder.addCase(fetchGetOrderBuy.pending, (state) => {
			state.isLoading = true;
			state.ordersInfo = [];
			state.goodsInfo = [];
			state.allData = [];
		});
		builder.addCase(fetchGetOrderBuy.fulfilled, (state, action) => {
			state.isLoading = false;
			const data = action.payload;
			const res = groupByIdOrder(data);

			state.ordersInfo = res.map((order) => order[0]);
			state.allData = data;
			state.goodsInfo = res;
		});
		builder.addCase(fetchGetOrderBuy.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const {
	addGoodItem,
	removeGoodItem,
	setMessage,
	setDefault,
	setAllActiveItem,
	removeAllActiveItem,
	setQuantityDivideRow,
	setQuantityDivideEmpty,
	closeModal,
	setDivided,
} = divideOrders.actions;
export default divideOrders.reducer;
