import React, { Fragment, useEffect, useState } from 'react';
import { fetchJWT } from 'services/store/jwt';
import styles from './loginPage.module.scss';
import { AuthLayout } from 'layouts/Auth';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { getValue } from 'services/utils/react/pickFromSyntheticEvent';
import { Loader } from 'components/Common/Loading/Loader';
import { v4 as uuidv4 } from 'uuid';

export const LoginPage = () => {
	const [name, setName] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState('');

	const error = useSelector((state: IStoreState) => state.jwt.error);
	const loading = useSelector((state: IStoreState) => state.jwt.loading);
	const dispatch = useDispatch();

	useEffect(() => setErrors(error), [error]);

	const handlerSubmit = (name: string, password: string): void => {
		const payload = {
			LoginName: name,
			Password: password,
			LoginNameTypeId: 1,
			SourceName: process.env.REACT_APP_SOURCE_NAME || '',
			UserAgent: navigator.userAgent,
			UUID: uuidv4(),
		};

		dispatch(fetchJWT(payload));
	};

	return (
		<AuthLayout>
			<form
				className={styles.form}
				onSubmit={(e) => {
					e.preventDefault();
					handlerSubmit(name, password);
				}}
			>
				<div className={styles.form__header}>Личный кабинет</div>
				<div className={styles.container}>
					<label htmlFor="uname" className={styles.form__label}>
						Имя пользователя
					</label>

					<input
						value={name}
						type="text"
						onChange={getValue((value) => {
							setName(value);
							setErrors('');
						})}
						name="uname"
						className={styles.form__input}
					/>

					<label htmlFor="psw" className={styles.form__label}>
						Пароль
					</label>

					<input
						type="password"
						value={password}
						onChange={getValue((value) => {
							setPassword(value);
							setErrors('');
						})}
						name="psw"
						className={styles.form__input}
					/>

					<div
						className={classNames(styles.form__error, {
							[styles.form__error_visible]: errors.length > 0,
						})}
					>
						{errors.length > 0 && <img src="/assets/icons/error.svg" alt="error" className={styles.form__error_icon} />}
						{errors}
					</div>

					<div className={styles.form__buttons}>
						<button
							type="submit"
							className={classNames(styles.form__button, {
								[styles.form__button_error]: errors.length > 0,
								[styles.form__button_loading]: loading,
							})}
							disabled={loading || errors.length > 0}
						>
							{loading ? (
								<Fragment>
									<Loader isLogin={true} />
									Войти
								</Fragment>
							) : (
								'Войти'
							)}
						</button>
						{/*<button className={styles.form__button}>*/}
						{/*	<Link to="/registration">Регистрация</Link>*/}
						{/*</button>*/}
					</div>
					{/*<button className={classNames(styles.form__button, styles.form__button_remember)}>Не помню пароль</button>*/}
				</div>
			</form>
		</AuthLayout>
	);
};
