import React, { FC, useState } from 'react';
import { addOrder, removeOrder } from 'services/store/orders';
import { useDispatch, useSelector } from 'react-redux';
import styles from './withGroupInfoOrders.module.scss';
import { IStoreState } from 'services/store/interfaces';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';
import { ReactComponent as DragSVG } from 'assets/images/grouping/drag.svg';
import classNames from 'classnames';
import { Groups } from './Groups';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { InfoTable } from 'components/InfoTable';
import { GroupsProvider } from 'services/providers/GroupsProvider';
import { DragZone } from './DragZone';
import { motion } from 'framer-motion';
import { useIsSticky } from 'services/hooks/useIsSticky';
import { setGroupingProps } from 'services/store/grouping';
import { Loading } from 'components/Common/Loading';
import { Message } from 'components/Common/Message';
import { mapActiveTDSList, mapRemoveAllActive, mapSetAllActive } from '../../InfoTable/HeadTable/map';
import { getIsActiveCheckboxHeader } from 'services/utils/helpers/getIsActiveCheckboxHeader';

type TWithGroupProps = {
	onChange: (arg: boolean) => (arg: string) => void;
};
export type TPropItemList = {
	id: string;
	title: string;
};
const FieldsForGrouping = ['TTTime', 'Datex', 'Checkx', 'WarehouseId'];
const isDragProp = (prop: string) => FieldsForGrouping.includes(prop);

const WithGroup = () => {
	const { activeList, IDS } = mapActiveTDSList();

	const [isSticky, scrollHeight] = useIsSticky('orderGroup');
	const { ordersList } = useSelector((state: IStoreState) => state.orders);
	const { message, groupingProps, isLoading, isGroupingOrders } = useSelector((state: IStoreState) => state.grouping);
	const settingOrders = useSelector((state: IStoreState) => state.setting.listOrders);

	const [dragged, setDragged] = useState<HTMLElement | null>(null);
	const dispatch = useDispatch();

	const setPropsResult = (values: Array<TPropItemList>) => dispatch(setGroupingProps(values));
	const handleOrder = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addOrder({ id })) : dispatch(removeOrder({ id })));
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.3 }}
			className={styles.withGroup}
		>
			{isGroupingOrders && ordersList.length > 0 && (
				<DragZone dragged={dragged} propsResult={groupingProps} setPropsResult={setPropsResult} />
			)}

			{groupingProps.length > 0 && ordersList.length > 0 && (
				<div
					className={classNames(styles.table, {
						[styles.table_sticky]: isSticky,
					})}
					style={isSticky ? { top: `${scrollHeight}px` } : {}}
				>
					<div className={styles.table_heading}>
						<div
							className={classNames(styles.tr, {
								[styles.tr__sticky]: isSticky,
							})}
						>
							<div className={styles.check}>
								<InputCheckbox
									mix={styles.checkmark}
									isActive={getIsActiveCheckboxHeader(activeList.orders, IDS.orders)}
									onChange={
										getIsActiveCheckboxHeader(activeList.orders, IDS.orders)
											? mapRemoveAllActive('orders', dispatch)
											: mapSetAllActive('orders', dispatch)
									}
								/>
							</div>

							{settingOrders.map(({ text, id, isShow }) => {
								const isWarehouse = id === 'DepartmentAddress';
								const isCheckx = id === 'Datex';

								return isShow ? (
									isDragProp(id) ? (
										<div
											key={id}
											data-id={id}
											data-title={text}
											className={classNames('dropzone', styles.th, styles.th_drag, {
												[styles.th_warehouse]: isWarehouse,
												[styles.th_checkx]: isCheckx,
											})}
											onDragStart={(e) => {
												setDragged(e.target as HTMLElement);
												(e.target as Element).classList.add(styles.selected);
											}}
											onDragEnd={(e) => {
												(e.target as Element).classList.remove(styles.selected);
												setDragged(null);
											}}
											draggable={true}
										>
											{text}
											<DragSVG />
										</div>
									) : (
										<div
											key={id}
											className={classNames(styles.th, {
												[styles.th_warehouse]: isWarehouse,
											})}
											data-id={id}
										>
											{text}
										</div>
									)
								) : null;
							})}
						</div>
					</div>
				</div>
			)}

			{groupingProps.length === 0 ? (
				<InfoTable type="orders" onChange={handleOrder} onDrag={setDragged} dragEffect />
			) : (
				<GroupsProvider>{isLoading ? <Loading /> : message ? <Message text={message} /> : <Groups />}</GroupsProvider>
			)}
		</motion.div>
	);
};

export const WithGroupInfoOrders: FC<TWithGroupProps> = ({ onChange }) => {
	const breakpoints = useBreakpoint();
	const isDesktop = ['lg', 'xl'].includes(breakpoints);

	return <>{isDesktop ? <WithGroup /> : <InfoTable type="orders" onChange={onChange} />}</>;
};
