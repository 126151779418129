import React, { useCallback, useEffect, useState } from 'react';
import { ActionButton } from '../ActionButton';
import styles from './clearFilter.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearEvents,
	clearGoods,
	clearOrder,
	clearOrders,
	clearReportsAllGoods,
	clearReportsCurrent,
	clearReportsPlanning,
	clearReportsQuality,
	defaultListEvents,
	defaultListGoods,
	defaultListOrderGoods,
	defaultListOrders,
	defaultListReportsAllGoods,
	defaultListReportsCurrent,
	defaultListReportsPlanning,
	defaultListReportsQuality,
	setFiltersEvents,
	setFiltersGoods,
	setFiltersOrderGoods,
	setFiltersOrders,
	setFiltersReportsAllGoods,
	setFiltersReportsCurrent,
	setFiltersReportsPlanning,
	setFiltersReportsQuality,
} from 'services/store/filter';
import classNames from 'classnames';
import { equalObjectsCount } from 'services/utils/js/equalObjectsCount';
import { IStoreState } from 'services/store/interfaces';
import { removeObjKeys } from 'services/utils/helpers/removeObjKeys';

interface IClearFilterProps {
	type:
		| 'goods'
		| 'orders'
		| 'events'
		| 'order'
		| 'multiOrders'
		| 'notifications'
		| 'divide'
		| 'allGoods'
		| 'quality'
		| 'downTimes'
		| 'planning'
		| 'current';
	text?: string;
	mobile?: boolean;
	isOpen?: boolean;
}
export const ClearFilter = ({ type, text, mobile, isOpen }: IClearFilterProps) => {
	const dispatch = useDispatch();
	const [isDisable, setIsDisable] = useState(true);
	const {
		listReportsCurrent,
		listReportsQuality,
		listReportsAllGoods,
		listReportsPlanning,
		listOrders,
		listEvents,
		listGoods,
		listMessages,
		listOrderGoods,
		listMultiOrders,
	} = useSelector((state: IStoreState) => state.filter);

	const mapFilters = {
		orders: listOrders,
		goods: listGoods,
		events: listEvents,
		notifications: listMessages,
		order: listOrderGoods,
		multiOrders: listMultiOrders,
		divide: listOrderGoods,
		allGoods: listReportsAllGoods,
		current: listReportsCurrent,
		quality: listReportsQuality,
		planning: listReportsPlanning,
		downTimes: {},
	};

	const defaultFilters = {
		orders: defaultListOrders,
		goods: defaultListGoods,
		events: defaultListEvents,
		notifications: defaultListEvents,
		order: defaultListOrderGoods,
		multiOrders: defaultListOrders,
		divide: defaultListOrderGoods,
		allGoods: defaultListReportsAllGoods,
		current: defaultListReportsCurrent,
		quality: defaultListReportsQuality,
		planning: defaultListReportsPlanning,
		downTimes: {},
	};

	const clearFilterOrders = useCallback(() => {
		dispatch(setFiltersOrders({ filters: defaultListOrders }));
		dispatch(clearOrders());
	}, []);

	const clearFilterGoods = useCallback(() => {
		dispatch(setFiltersGoods({ filters: defaultListGoods }));
		dispatch(clearGoods());
	}, []);

	const clearFilterOrder = useCallback(() => {
		dispatch(setFiltersOrderGoods({ filters: defaultListOrderGoods }));
		dispatch(clearOrder());
	}, []);

	const clearFilterEvents = useCallback(() => {
		dispatch(setFiltersEvents({ filters: defaultListEvents }));
		dispatch(clearEvents());
	}, []);

	const clearFilterReportsAllGoods = useCallback(() => {
		dispatch(setFiltersReportsAllGoods({ filters: defaultListReportsAllGoods }));
		dispatch(clearReportsAllGoods());
	}, []);
	const clearFilterReportsCurrent = useCallback(() => {
		dispatch(setFiltersReportsCurrent({ filters: defaultListReportsCurrent }));
		dispatch(clearReportsCurrent());
	}, []);
	const clearFilterReportsQuality = useCallback(() => {
		dispatch(setFiltersReportsQuality({ filters: defaultListReportsQuality }));
		dispatch(clearReportsQuality());
	}, []);

	const clearFilterReportsPlanning = useCallback(() => {
		dispatch(setFiltersReportsPlanning({ filters: defaultListReportsPlanning }));
		dispatch(clearReportsPlanning());
	}, []);

	const mapClickFunc = {
		orders: clearFilterOrders,
		goods: clearFilterGoods,
		order: clearFilterOrder,
		events: clearFilterEvents,
		notifications: clearFilterEvents,
		allGoods: clearFilterReportsAllGoods,
		current: clearFilterReportsCurrent,
		quality: clearFilterReportsQuality,
		planning: clearFilterReportsPlanning,
		multiOrders: () => {},
		divide: () => {},
		downTimes: () => {},
	};

	useEffect(() => {
		setIsDisable(
			equalObjectsCount(
				removeObjKeys(mapFilters[type], ['PageNumber']),
				removeObjKeys(defaultFilters[type], ['PageNumber'])
			) === 0
		);
	}, [isOpen, JSON.stringify(mapFilters[type])]);

	return (
		<div
			className={classNames(styles.clean, {
				[styles.clean__active]: isOpen,
			})}
		>
			<ActionButton
				type="clean"
				text={text}
				onClick={isDisable ? () => {} : mapClickFunc[type]}
				mix={
					mobile
						? undefined
						: classNames(styles.clean_button, {
								[styles.clean_active]: isOpen,
								[styles.clean_disable]: isDisable,
						  })
				}
				mobile={mobile}
			/>

			<div className={styles.clean_hint} style={{ top: -20, left: 15 }}>
				{isDisable ? '' : 'Отменить все фильтры'}
			</div>
		</div>
	);
};
