import React from 'react';
import { HomeText } from './HomeText';
import { HomeDocs } from './HomeDocs';
import { HomeBanners } from './HomeBanners';
import styles from './userHome.module.scss';

export const UserHome = () => {
	return (
		<div className={styles.userHome}>
			<HomeText />
			<HomeDocs />
			<HomeBanners />
		</div>
	);
};
