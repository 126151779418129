import React, { FC, useEffect, useState, memo } from 'react';
import { IStoreState, TGetOrderBuyGroupResponse } from 'services/store/interfaces';
import { TGroupProps, TItemGroup } from 'services/store/interfaces/ordersGrouping';
import styles from './groups.module.scss';
import { ParentRow } from '../ParentRow';
import classnames from 'classnames';
import { OrderRow } from '../OrderRow';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { Message } from 'components/Common/Message';
import { fetchUpdateListOrdersAndGroups } from 'services/store/grouping';

const variants = {
	visible: { opacity: 1 },
	hidden: { opacity: 0.7 },
};

const Group: FC<TGroupProps> = ({ group, isLast }) => {
	const { Valuex, TableData } = group as TItemGroup;
	const [isActive, setIsActive] = useState(true);
	const isChild = !Object.keys(group).includes('Namex');

	return (
		<div
			key={JSON.stringify(TableData)}
			className={classnames(styles.group, {
				[styles.group_last]: isChild && isLast,
			})}
		>
			{Valuex ? (
				<ParentRow order={group as TItemGroup} isActive={isActive} onActive={setIsActive} />
			) : (
				<AnimatePresence>
					<OrderRow order={group as TGetOrderBuyGroupResponse} />
				</AnimatePresence>
			)}

			<AnimatePresence>
				{isActive && !!TableData && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.2 }}
						className={classnames(styles.group_body, {
							[styles.group_body__last]: isLast,
						})}
					>
						{isActive &&
							!!TableData &&
							TableData.map((group, n) => <Group group={group} key={n} isLast={n === TableData.length - 1} />)}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export const Groups = memo(() => {
	const { isRemovingOrder, message, groups } = useSelector((state: IStoreState) => state.grouping);
	const { cancelOrder } = useSelector((state: IStoreState) => state.orders);
	const dispatch = useDispatch();

	useEffect(() => {
		if (cancelOrder.length > 0) dispatch(fetchUpdateListOrdersAndGroups());
	}, [cancelOrder.length]);

	return message ? (
		<Message text={message} />
	) : (
		<motion.div
			animate={isRemovingOrder ? 'hidden' : 'visible'}
			transition={{ duration: 0.3 }}
			variants={variants}
			className={styles.groups}
		>
			{!!groups && groups.map((group, i) => <Group group={group} key={i} isLast={i === groups.length - 1} />)}
		</motion.div>
	);
});
