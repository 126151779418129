import { generateId } from '../react/generateRandomIndex';

type Page = {
	text: string;
	id: string;
};

export const fill = (num: number): Page[] => {
	const arr = [];
	for (let i = 0; i < num; i++) {
		arr.push({ text: `${i + 1}` });
	}

	return arr.map(generateId);
};
