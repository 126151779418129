import { request } from 'services/utils/request';
import { TMergeOrderBuy, TMergeOrderBuyResponse } from 'services/store/interfaces';

export const mergeOrderBuy = async (payload: TMergeOrderBuy): Promise<TMergeOrderBuyResponse[]> => {
	return await request.post(
		'SupplierPortal.MergeOrderBuy',
		{
			...payload,
		},
		localStorage.token
	);
};
