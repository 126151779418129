import React, { FC, Fragment, useEffect, useState } from 'react';
import styles from './iconsDesktop.module.scss';
import { Submenu } from '../../Submenu';
import { UserMenu } from '../../UserMenu';
import { MessagesMenu } from '../../MessagesMenu';
import { Nullable } from 'services/store/interfaces';
import { ReactComponent as HelpSVG } from 'assets/images/header/help.svg';
import { ReactComponent as UserSVG } from 'assets/images/header/user.svg';
import classNames from 'classnames';
import { OutsideClicker } from 'components/HOCS/OutsideClicker';
import { HelpMenu } from '../../HelpMenu';
import { EventsIcon } from '../EventsIcon';

const ICONSDESKTOP = [
	{ id: 1, note: 'messages', element: <EventsIcon /> },
	{ id: 2, note: 'help', element: <HelpSVG /> },
	{ id: 3, note: 'user', element: <UserSVG /> },
];

interface IIconsItems {
	type: Nullable<string>;
	onChange: (value: Nullable<string>) => void;
}

const IconsItems: FC<IIconsItems> = ({ type, onChange }) => {
	return (
		<Fragment>
			{ICONSDESKTOP.map(({ id, note, element }) => {
				return (
					<div
						key={id}
						className={classNames(styles.item, { [styles.active]: type === note })}
						onClick={(e) => {
							e.stopPropagation();
							onChange(note);
						}}
					>
						{element}
					</div>
				);
			})}
		</Fragment>
	);
};

export const IconsDesktop = () => {
	const [type, setTypeSubmenu] = useState<Nullable<string>>(null);

	useEffect(() => {
		document.addEventListener('scroll', () => setTypeSubmenu(null));

		return () => {
			document.removeEventListener('scroll', () => setTypeSubmenu(null));
		};
	}, []);

	return (
		<div className={styles.icons}>
			<IconsItems type={type} onChange={setTypeSubmenu} />

			<OutsideClicker onOutside={() => setTypeSubmenu('close')}>
				<Submenu type={type}>
					<div className={styles.submenu}>
						<IconsItems type={type} onChange={setTypeSubmenu} />
					</div>

					<div className={styles.submenu__list}>
						{type === 'user' && <UserMenu />}
						{type === 'messages' && <MessagesMenu />}
						{type === 'help' && <HelpMenu />}
					</div>
				</Submenu>
			</OutsideClicker>
		</div>
	);
};
