import React, { useEffect, memo } from 'react';
import { Modal } from 'antd';
import styles from './modal.module.scss';
import { ReactComponent as SuccessSVG } from 'assets/images/modal/success.svg';
import { ReactComponent as ErrorSVG } from 'assets/images/modal/error.svg';
import { ReactComponent as CloseSVG } from 'assets/images/modal/close.svg';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Loader } from 'components/Common/Loading/Loader';

interface IModalProps {
	isOpen: boolean;
	message: string;
	title?: string;
	onCancel: () => void;
	isRight?: boolean;
	isCentered?: boolean;
	type?: string;
	onConfirm?: () => void;
	isLoading?: boolean;
	links?: string;
}

export const Links = memo(({ links, isRight }: { links: string; isRight?: boolean }) => {
	const [link1, link2] = links.split(' ');

	return (
		<p className={classNames(styles.message, styles.links, { [styles.side]: isRight })}>
			<span>{link2 !== '' ? 'Новые заказы - ' : 'Новый заказ - '}</span>
			<Link to={`/orders/${link1}`}>{link1}</Link>

			{link2 !== '' ? (
				<>
					<span>и</span>
					<Link to={`/orders/${link2}`}>{link2}</Link>
				</>
			) : null}
		</p>
	);
});

export const ModalW = memo(
	({ links, isOpen, message, onCancel, title = '', isRight, isCentered, type, onConfirm, isLoading }: IModalProps) => {
		const unsaveClose = () => {
			document.onmouseout = null;
			onCancel();
		};

		const isSuccess = type === 'success';
		const isError = type === 'error';
		const isDivide = type === 'divide';
		const isSeparate = type === 'separate';
		const isUnsave = type === 'unsave' || type === '';

		useEffect(() => {
			if (isRight) {
				document.body.style.overflowY = 'auto';
				document.body.style.width = 'unset';
			}
		}, [isRight]);

		return (
			<Modal
				title=""
				styles={{ mask: isRight ? { background: 'transparent' } : {} }}
				style={isRight ? { position: 'fixed', right: 16 } : {}}
				closeIcon={<CloseSVG />}
				open={isOpen}
				footer={null}
				onCancel={isUnsave ? unsaveClose : onCancel}
				width={isRight ? 330 : isCentered ? 420 : 280}
				centered={!isRight}
			>
				{isRight && isSuccess && (
					<div className={styles.success}>
						<SuccessSVG />
						<p className={styles.status}>Сохранено!</p>
					</div>
				)}

				{isRight && isError && (
					<div className={styles.error}>
						<ErrorSVG />
						<p className={classNames(styles.status, styles.status_error)}>Внимание!</p>
					</div>
				)}

				<p className={classNames(styles.title)}>{title}</p>

				<div className={classNames(styles.message, { [styles.side]: isRight })}>{message}</div>

				{links && <Links links={links} isRight={isRight} />}

				{isDivide && (
					<div className={classNames(styles.actions, styles.divide)}>
						<div className={classNames(styles.action, styles.cancel)} onClick={onCancel}>
							Отменить
						</div>

						<div
							className={classNames(styles.action, styles.ok, {
								[styles.action_disabled]: isLoading,
							})}
							onClick={isLoading ? () => {} : onConfirm}
						>
							{isLoading ? <Loader /> : 'Разделить заказ'}
						</div>
					</div>
				)}

				{isSeparate && (
					<div className={classNames(styles.actions, styles.divide)}>
						<div className={classNames(styles.action, styles.cancel)} onClick={onCancel}>
							Отменить
						</div>

						<div className={classNames(styles.action, styles.ok)} onClick={onConfirm}>
							{isLoading ? <Loader /> : 'Разбить строку'}
						</div>
					</div>
				)}

				{isUnsave && (
					<div className={classNames(styles.actions, styles.unsave)}>
						<div className={classNames(styles.action, styles.cancel)} onClick={unsaveClose}>
							Покинуть страницу
						</div>

						<div className={classNames(styles.action, styles.ok)} onClick={onConfirm}>
							{isLoading ? <Loader /> : 'Сохранить'}
						</div>
					</div>
				)}
			</Modal>
		);
	}
);
