import React, { useEffect, useRef } from 'react';

interface IOutsideClickerProps {
	children: React.ReactNode;
	onOutside: () => void;
}

export const OutsideClicker = ({ children, onOutside }: IOutsideClickerProps) => {
	const list = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent): void => {
			event.stopImmediatePropagation();

			if (list.current && !list.current.contains(event.target as Node)) onOutside();
		};

		document.addEventListener('click', listener);

		return () => {
			document.removeEventListener('click', listener);
		};
	}, [list]);

	return <div ref={list}>{children}</div>;
};
