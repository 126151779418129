import { createSlice } from '@reduxjs/toolkit';
import { IAddressState } from './interfaces';
import { clearOrders } from './filter';

const initialState: IAddressState = {
	RegionId: [],
	DistrictId: [],
	CityId: [],
	Checkx: [],
	CurrentRegionId: [],
	QualityRegionId: [],
};

export const address = createSlice({
	name: 'address',
	initialState,
	reducers: {
		setAddress: (state, action) => {
			const { id, value } = action.payload;
			return { ...state, [id]: value };
		},
		setAddressEmpty: (state) => {
			return { ...state, DistrictId: [], CityId: [] };
		},
		setAddressDefault: (state) => {
			return {
				...state,
				RegionId: [],
				DistrictId: [],
				CityId: [],
				Checkx: [],
				CurrentRegionId: [],
				QualityRegionId: [],
			};
		},
		setCityEmpty: (state) => {
			return { ...state, CityId: [] };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(clearOrders, (state) => {
			return { ...state, RegionId: [], DistrictId: [], CityId: [], Checkx: [] };
		});
	},
});

export const { setAddressDefault, setAddress, setAddressEmpty, setCityEmpty } = address.actions;
export default address.reducer;
