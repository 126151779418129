import { TReportForExportPayload } from 'services/store/interfaces';
import { requestFile } from 'services/utils/requestFile';

export const getDataReportsForExport = async (payload: TReportForExportPayload): Promise<any> => {
	return await requestFile.post(
		'SupplierPortal.GetDataReportsForExport',
		{
			...payload,
		},
		localStorage.token
	);
};
