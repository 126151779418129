import React from 'react';
import styles from './loading.module.scss';
import { Spinner } from './Spinner';

interface ILoadingProps {
	type?: 'content' | 'button' | 'row';
}

export const Loading = ({ type = 'content' }: ILoadingProps) => {
	return <div className={styles.loading}>{type === 'content' && <Spinner delay={0.2} />}</div>;
};
