import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IGoodsListState, IGoodAction, TFilterGoods } from './interfaces';
import { getContractorGoods } from '../api/getContractorGoods';

const initialState: IGoodsListState = {
	message: '',
	clipboard: { text: '' },
	isLoading: false,
	goodsList: [],
	activeGoods: [],
	count: 0,
};

export const fetchGetContractorGoods = createAsyncThunk('goods/fetchGetContractorGoods', async (data: TFilterGoods) => {
	return await getContractorGoods(data);
});

const goods = createSlice({
	name: 'goods',
	initialState,
	reducers: {
		setEmptyGoods: (state: IGoodsListState) => {
			state.goodsList = [];
		},
		addGood: (state: IGoodsListState, action: IGoodAction) => {
			if (action.payload) state.activeGoods = [...state.activeGoods, action.payload.id];
		},
		removeGood: (state: IGoodsListState, action) => {
			state.activeGoods = state.activeGoods.filter((id) => id !== action.payload.id);
		},
		setClipboard: (state, action) => {
			state.clipboard = { ...state.clipboard, ...action.payload };
		},
		setAllGoodsActive: (state) => {
			const newSet = new Set([...state.activeGoods, ...state.goodsList.map(({ Id }) => Id)]);
			state.activeGoods = Array.from(newSet);
		},
		removeAllGoodsActive: (state) => {
			const goodsIds = state.goodsList.map(({ Id }) => Id);
			state.activeGoods = state.activeGoods.filter((id) => !goodsIds.includes(id));
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchGetContractorGoods.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchGetContractorGoods.fulfilled, (state, action) => {
			const data = action.payload;
			state.count = 0;

			if (data.length === 0) {
				state.message = 'У вас нет товаров ...';
				state.isLoading = false;

				return;
			}

			if (data[0].Error || data[0].ErrorDescription) {
				state.message = 'Проверьте правильность вводимых данных ...';
				state.isLoading = false;

				return;
			}

			if (!data[0].Error) {
				state.count = data[0].Amount as unknown as number;
				state.goodsList = data;
				state.isLoading = false;
			}
		});
		builder.addCase(fetchGetContractorGoods.rejected, (state) => {
			state.message = 'У вас нет таких товаров ...';
			state.isLoading = false;
		});
	},
});

export const { addGood, removeGood, setEmptyGoods, setClipboard, setAllGoodsActive, removeAllGoodsActive } =
	goods.actions;
export default goods.reducer;
