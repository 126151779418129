import { request } from 'services/utils/request';
import { TMonthDemandPlanData, TMonthDemandPlanPayload } from 'services/store/interfaces/reports';

export const getLastMonthDemandPlan = async (
	payload: TMonthDemandPlanPayload
): Promise<Array<TMonthDemandPlanData>> => {
	return await request.post(
		'SupplierPortal.DemandPlanLastExportDateGet',
		{
			...payload,
		},
		localStorage.token
	);
};
