import React, { FC, useEffect, memo, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { Header } from 'components/Header';
import styles from './main.module.scss';
import { fetchContractorInfo } from 'services/store/user';
import { defaultListMessages } from 'services/store/filter';
import { fetchGetNotifications, setUpdateSidebarCount } from 'services/store/notifications';
import { useIsAdminSession } from 'services/hooks/useIsAdminSession';
import { useHandlerMetrics } from 'services/hooks/useHandlerMetrics';
import { CookiesModal } from 'components/Cookies';

const Main: FC<PropsWithChildren<unknown>> = ({ children }) => {
	const jwt = useSelector((state: IStoreState) => state.jwt.value);
	const isMergedOrders = useSelector((state: IStoreState) => state.orders.isMergedOrders);
	const isDivideOrders = useSelector((state: IStoreState) => state.divideOrders.isDivided);
	const [isAdminSession] = useIsAdminSession();

	const token = localStorage.token;
	const isAuthorized = token || jwt;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setUpdateSidebarCount(true));
	}, []);

	useEffect(() => {
		dispatch(fetchContractorInfo(token));
	}, [isAdminSession]);

	useEffect(() => {
		dispatch(fetchGetNotifications(defaultListMessages));
	}, [isMergedOrders, isDivideOrders, isAdminSession]);

	useHandlerMetrics();

	return (
		<div className={styles.main}>
			<Header isAuthorized={isAuthorized} />

			{children}

			<CookiesModal />
		</div>
	);
};

export const MainLayout = memo(Main);
