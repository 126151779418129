import React, { FC } from 'react';
import styles from './list.module.scss';
import { MONTHS, YEARS } from '../Month/data';
import { useDispatch } from 'react-redux';
import { setMonth, setYear } from 'services/store/downTimes';
import { useSelector } from 'react-redux';
import { TMonth } from 'services/store/interfaces/downTimes';
import { IStoreState } from 'services/store/interfaces';

type TListProps = {
	onClose?: () => void;
};

export const List: FC<TListProps> = ({ onClose = () => {} }) => {
	const dispatch = useDispatch();
	const { month, mode, year } = useSelector((state: IStoreState) => state.downTimes);

	const onMonth = (month: TMonth) => {
		dispatch(setMonth(month));
		onClose();
	};
	const onYear = (year: number) => {
		dispatch(setYear(year));
		onClose();
	};

	const isMonth = mode === 'month';

	return (
		<div className={styles.list}>
			{isMonth &&
				MONTHS.filter(({ id }) => id !== month.id).map((month) => (
					<div key={month.id} className={styles.list_item} onClick={() => onMonth(month)}>
						{month.name}
					</div>
				))}

			{!isMonth &&
				YEARS.filter((item) => item !== year).map((year) => (
					<div key={String(year)} className={styles.list_item} onClick={() => onYear(year)}>
						{year}
					</div>
				))}
		</div>
	);
};
