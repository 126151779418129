import React from 'react';
import styles from './left.module.scss';
import classNames from 'classnames';

interface IRightProps {
	children?: React.ReactNode;
	events?: boolean;
}

export const Left = ({ children, events }: IRightProps) => {
	return (
		<div
			className={classNames(styles.left, {
				[styles.left_events]: events,
			})}
			style={{ paddingTop: 16, paddingBottom: 16 }}
		>
			{children}
		</div>
	);
};
