import React from 'react';
import styles from './submenu.module.scss';
import { Nullable } from 'services/store/interfaces';
import classNames from 'classnames';

interface ISubmenu {
	children: React.ReactNode;
	type?: Nullable<string>;
}

export const Submenu = ({ children, type }: ISubmenu) => {
	const isOpen = type && type !== 'close';

	return <div className={classNames(styles.submenu, { [styles.submenu__closed]: !isOpen })}>{children}</div>;
};
