import React from 'react';

export function pickFromSyntheticEvent<T extends HTMLElement>() {
	return <K extends keyof T>(key: K) =>
		<F extends (t: T[K]) => void>(fn: F) =>
		(e: React.SyntheticEvent<T>) =>
			fn(e.currentTarget[key]);
}

export const getValue = pickFromSyntheticEvent<HTMLInputElement>()('value');
export const getChecked = pickFromSyntheticEvent<HTMLInputElement>()('checked');
