import { request } from 'services/utils/request';
import { TDividePayload, TGetOrderCheckxResponse } from 'services/store/interfaces';

export const divideOrderBuy = async (payload: TDividePayload): Promise<TGetOrderCheckxResponse[]> => {
	return await request.post(
		'SupplierPortal.DivideOrderBuy',
		{
			...payload,
		},
		localStorage.token
	);
};
