export const getLinerStyles = (checkx: number | boolean, cities: number | boolean) => {
	if (checkx || cities) {
		if (Number(checkx) < Number(cities)) {
			return { height: Math.abs(Number(checkx) - Number(cities)) };
		}

		if (Number(checkx) === Number(cities)) {
			return { height: Number(checkx) / 2 };
		}
	}

	return { height: 0.1 };
};

export const getAddressLinerStyles = (isCities: boolean, checkx: number | boolean, cities: number | boolean) => {
	if (isCities && (checkx || cities)) {
		if (Number(checkx) > Number(cities)) {
			return { height: Math.abs(Number(checkx) - Number(cities)) };
		}

		if (Number(checkx) === Number(cities)) {
			return { height: Number(checkx) / 2 };
		}
	}

	return { height: 0.1 };
};
