import { createSlice } from '@reduxjs/toolkit';
import { IOutsideState } from './interfaces';

const initialState: IOutsideState = {
	target: null,
};

const outside = createSlice({
	name: 'outside',
	initialState,
	reducers: {
		setTarget: (state, action) => {
			state.target = action.payload;
		},
	},
});

export const { setTarget } = outside.actions;
export default outside.reducer;
