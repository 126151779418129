export const cookies = `
    <p>
        1. Куки (от англ. cookies) являются текстовым файлом, сохраненным в устройстве (компьютере, мобильном
устройстве) пользователя интернет-сайта contractor-evroopt.by (далее – Сайт) ООО «Евроторг» при его посещении
пользователем для отражения совершенных им действий. Этот файл позволяет не вводить заново или выбирать те же
параметры при повторном посещении Сайта (например, проверка установки браузера в отношении принятия или
отклонения cookie-файлов).
</p>

<p>Целями обработки куки являются:</p>
<li>- улучшение работы Сайта и повышение удобства его использования;</li>
<li>- сбор аналитической информации в обобщенном виде для дальнейшего улучшения работы Сайта.</li>

<p>
При этом ООО «Евроторг» (далее – Общество) не использует куки для идентификации субъектов персональных данных
					– конкретных пользователей Сайта и не передает куки третьим лицам.
</p>
<p>
Политика в отношении обработки cookie на интернет-сайте contractor-evroopt.by является общедоступной и
размещается в глобальной компьютерной сети Интернет на сайте Общества по адресу:
<a href="https://contractor-evroopt.by">https://contractor-evroopt.by.</a>
</p>
<p>
Общество имеет право вносить изменения в настоящую Политику. В этом случае обновленная Политика также
размещается в открытом доступе на Сайте.
</p>

<p>2. Данные, которые обрабатываются на Сайте:</p>
<li>- IP-адрес, тип браузера и операционной системы устройства;</li>
<li>- дата и время посещения Сайта;</li>
<li>- адрес посещаемых страниц Сайта.</li>
<p>
3. В зависимости от сроков хранения в устройстве пользователя Общество использует сеансовые и постоянные файлы
куки.
</p>
<p>
<span>Сеансовые</span> файлы куки хранятся только во время использования Сайта, а постоянные – в течение
времени, указанного в параметрах файлов куки, или до момента их удаления пользователем.
</p>
<p>
Общество предлагает пользователю при первом посещении Сайта дать свое согласие на использование файлов куки
либо отказаться от их использования посредством нажатия кнопок «Принять» или «Отклонить» на всплывающем
информационном баннере.
</p>
<p>
Срок, на который дается настоящее согласие, составляет 2 года, поскольку некоторые аналитические куки могут
храниться в течение такого времени.
</p>
<p>
Посетитель Сайта вправе в любое время без объяснения причин отозвать данное им согласие в интерфейсе Сайта
путем открытия текста Политики в отношении обработки cookie в нижней части страницы Сайта, проставления
соответствующей отметки об отклонении использования файлов куки и подтверждения выбора настроек cookies.
</p>
<p>
4. На Сайте обрабатываются следующие типы файлов куки:
<span>необходимые, функциональные, аналитические.</span>
</p>
<p>
<span>Необходимые</span> – используются с целью правильного функционирования Сайта и корректного использования
предлагаемых на нем возможностей и услуг.
</p>

<p>
Отключение данного типа файлов куки может привести к ограничению доступа пользователя к функциональным
возможностям Сайта.
</p>

<p>
<span>Функциональные (или технические)</span> – позволяют обеспечить индивидуальный опыт использования Сайта и
устанавливаются в ответ на действия субъекта персональных данных путем сохранения настроек (параметров или
конфигураций) Сайта, произведенных пользователем (например, проверки установки браузера в отношении принятия
или отклонения cookie-файлов).
</p>

<p>
Отключение данного типа файлов куки может привести к ограничению доступа пользователя к функциональным
возможностям Сайта.
</p>
<p>
Поскольку Общество может обеспечить полноценное и корректное функционирование Сайта, в том числе безопасность
его использования, только с использованием необходимых и функциональных (технических) файлов куки, которые
являются обязательными, данные типы файлов куки не подлежат отключению.
</p>
<p>
<span>Аналитические</span> – позволяют хранить историю посещений страниц Сайта в целях повышения качества его
функционирования, чтобы определить наиболее и наименее популярные страницы, то есть позволяют определять
предпочтения пользователей Сайта.
</p>
<p>
Общество использует с целью совершенствования Сайта следующий сервис статистики, обрабатывающий информацию с
помощью файлов куки:
    </p>
    <li>
    - Яндекс.Метрика
<a href="https://yandex.ru/legal/confidential/">Политика конфиденциальности Яндекс.</a>
</li>
<li>
- Яндекс.Метрика применяется в целях улучшения взаимодействия пользователя с Сайтом, использует файлы куки для
сбора информации о поведении пользователей на Сайте без их идентификации.
</li>
<p>
Отключение аналитических файлов куки не позволяет определять предпочтения пользователей Сайта, в том числе
наиболее и наименее популярные страницы, и принимать меры по совершенствованию его работы исходя из
предпочтений пользователей без их идентификации.
</p>
<p>
5. Помимо настроек куки на Сайте субъекты персональных данных могут принять или отклонить сбор всех или
некоторых куки в настройках своего браузера.
</p>
<p>
При этом некоторые браузеры позволяют посещать интернет-сайты в режиме «инкогнито», чтобы ограничить хранимый
на компьютере объем информации и автоматически удалять сессионные куки.
</p>
<p>
Кроме того, субъект персональных данных может удалить ранее сохраненные куки, выбрав соответствующую опцию в
истории браузера.
</p><p>
Подробнее о параметрах управления куки можно ознакомиться, перейдя по внешним ссылкам, ведущим на
соответствующие страницы интернет-сайтов основных браузеров:
</p>`;

export const block1 = `<p><span>Необходимые и функциональные</span> (или технические) – используются с целью правильного функционирования Сайта и корректного использования предлагаемых на нем возможностей и услуг; позволяют обеспечить индивидуальный опыт использования Сайта и устанавливаются в ответ на действия субъекта персональных данных путем сохранения настроек (параметров или конфигураций) Сайта, произведенных пользователем (например, проверки установки браузера в отношении принятия или отклонения cookie-файлов).</p>`;

export const block2 = `<p><span>Аналитические</span> – позволяют хранить историю посещений страниц Сайта в целях повышения качества его функционирования, чтобы определить наиболее и наименее популярные страницы, то есть позволяют определять предпочтения пользователей Сайта.</p>`;
