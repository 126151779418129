import { request } from 'services/utils/request';
import { TFilterOrders, TGetOrderBuyGroupResponse } from 'services/store/interfaces';

export const getOrderBuy = async (payload: TFilterOrders): Promise<TGetOrderBuyGroupResponse[]> => {
	return await request.post(
		'SupplierPortal.GetOrderBuy',
		{
			...payload,
		},
		localStorage.token
	);
};
