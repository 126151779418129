import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOffline, setOnline } from 'services/store/errors';

export const CheckOffline = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const checkOffline = () => {
			if (!navigator.onLine) dispatch(setOffline());
			if (navigator.onLine) dispatch(setOnline());
		};

		window.addEventListener('offline', checkOffline);
		window.addEventListener('online', checkOffline);

		return () => {
			window.removeEventListener('offline', checkOffline);
			window.removeEventListener('online', checkOffline);
		};
	}, []);

	return null;
};
