import React, { useState } from 'react';
import styles from './infoCard.module.scss';
import { addGood, removeGood } from 'services/store/goods';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { addOrder, removeOrder, setCurrent } from 'services/store/orders';
import { OrdersCards } from './OrdersCards';
import { GoodsCards } from './GoodsCards';
import { OrderCards } from './OrderCards';

interface IInfoCardProps {
	type?: 'orders' | 'goods' | 'order';
}

export const InfoCard = ({ type = 'goods' }: IInfoCardProps) => {
	const [count, setCount] = useState(10);
	const ordersIdActive = useSelector((state: IStoreState) => state.orders.activeOrders);

	const orderGoodsList = useSelector((state: IStoreState) => state.orders.currentGoods);
	const ordersList = useSelector((state: IStoreState) => state.orders.ordersList);
	const goodsList = useSelector((state: IStoreState) => state.goods.goodsList);

	const dispatch = useDispatch();

	const mapListType = {
		goods: goodsList,
		orders: ordersList,
		events: null,
		order: orderGoodsList,
	};

	const handleGood = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addGood({ id })) : dispatch(removeGood({ id })));
	};

	const handleOrder = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addOrder({ id })) : dispatch(removeOrder({ id })));
	};

	const clickId = (id: string) => {
		dispatch(setCurrent({ id }));

		if (!ordersIdActive.includes(id)) dispatch(addOrder({ id }));
	};

	const mapCardsBody = {
		order: <OrderCards count={count} />,
		orders: <OrdersCards setActive={clickId} onChange={handleOrder} count={count} />,
		goods: <GoodsCards onChange={handleGood} count={count} />,
	};

	return (
		<div className={styles.card}>
			{mapCardsBody[type]}

			{mapListType[type].length > 10 && (
				<div className={styles.more} onClick={() => setCount(count + 10)}>
					Загрузить еще 10
				</div>
			)}
		</div>
	);
};
