export const getArrFilter = (value: string) => {
	const isEmptyStr = value === '';
	return isEmptyStr
		? []
		: value
				.trim()
				.split(',')
				.map((item) => item.trim());
};

export const getGoodsFilter = (prop: string, value: string) => {
	const isGoodsId = prop === 'GoodsId';
	return isGoodsId ? getArrFilter(value) : value;
};
