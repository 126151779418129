import { useEffect, useRef } from 'react';

export const useComponentDidMount = () => {
	const ref = useRef();

	useEffect(() => {
		(ref.current as unknown as boolean) = true;
	}, []);

	return ref.current;
};
