export const COUNTER = 96547976;

export const sendHitPathname = (path: string) => {
	(window as any).ym(COUNTER, 'hit', path);
};

export const sendUserParams = () => {
	const username = String(localStorage.getItem('userName'));
	const user_id = String(localStorage.getItem('userId'));

	(window as any).ym(COUNTER, 'userParams', { username, user_id });
};
