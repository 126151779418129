import { TOrderBuyForExportPayload } from 'services/store/interfaces';
import { requestFile } from 'services/utils/requestFile';

export const getOrderBuyForExport = async (payload: TOrderBuyForExportPayload): Promise<any> => {
	return await requestFile.post(
		'SupplierPortal.GetOrderBuyForExport',
		{
			...payload,
		},
		localStorage.token
	);
};
