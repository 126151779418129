import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { MainLayout } from 'layouts/Main';
import { Orders } from 'components/Content/Orders';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TCurrentGood, TGetOrderBuyGroupResponse } from 'services/store/interfaces';
import { Footer } from 'components/Footer';
import { ActionButton } from 'components/Common/ActionButton';
import {
	addOrder,
	fetchCancelOrder,
	fetchConfirmOrder,
	fetchOrderBuyGroup,
	fetchSaveUpdateOrderBuy,
	fetchUpdateWithConfirmOrder,
	removeAllActive,
	setCancelOrder,
	setCurrent,
	setIsAttention,
	setIsNotAttention,
	setMergedOrders,
	setOrderMessage,
} from 'services/store/orders';
import { PrintItems } from 'components/Print/PrintItems';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { defaultListOrders, setFilterOrderGoods, setFiltersOrders } from 'services/store/filter';
import { EnableActions } from 'components/HOCS/EnableActions';
import { MultiEditFooter } from 'components/Footer/MultiEditFooter';
import { DivideOrderFooter } from 'components/Footer/DivideOrderFooter';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ClearFilter } from 'components/Common/ActionButton/ClearFilter';
import { getOrderBuy } from 'services/api/getOrderBuy';
import { ExportOrders } from 'components/HOCS/ExportWithAdd';
import styles from 'components/Mass/List/list.module.scss';
import { ModalListResult, AttentionModal } from 'components/Modals';

type idOrderPage = {
	id: string;
};
export const OrdersPage = () => {
	const { id }: idOrderPage = useParams();
	const { pathname } = useLocation();
	const history = useHistory();
	const [resExpOrds, setExportOrders] = useState<(TGetOrderBuyGroupResponse | TCurrentGood)[]>([]);

	const {
		current,
		isMergedOrders,
		cancelOrder,
		ordersList,
		quantityList,
		currentInfo,
		activeOrders,
		messageOrder,
		currentGoods,
		isLoading,
		isAttention,
	} = useSelector((state: IStoreState) => state.orders);

	const { listOrders, listOrder } = useSelector((state: IStoreState) => state.setting);
	const { isShowOrders, isShowOrder } = useSelector((state: IStoreState) => state.filter);
	const isSettingsOrders = useSelector((state: IStoreState) => state.setting.isShowOrders);
	const isSettingsOrder = useSelector((state: IStoreState) => state.setting.isShowOrder);
	const isOrderPage = id && !['divide', 'edit'].includes(id);
	const isNeedRemoveOrder = cancelOrder.length > 0;

	const OrderBuys = quantityList.filter((quantity) => quantity.QuantitySupplier !== '');
	const isActive = quantityList.length > 0 && OrderBuys.length !== 0;

	useEffect(() => {
		if (activeOrders.length < 1) return;

		getOrderBuy({ OrderBuyGroupId: activeOrders, IsForExport: '1' }).then(setExportOrders);
	}, [activeOrders.length]);

	useEffect(() => {
		if (isOrderPage) dispatch(setFilterOrderGoods());
	}, [pathname]);

	useEffect(() => {
		dispatch(setCurrent({ id: id || '0' }));
		dispatch(setFilterOrderGoods());

		if (isOrderPage) dispatch(addOrder({ id }));
	}, [id]);

	const isEditMode = current === 'edit';
	const isOrdersMode = current === '0';
	const isDivideMode = current === 'divide';

	const isCurrent = !isOrdersMode && !isEditMode && !isDivideMode;
	const isDefaultMode = isOrdersMode || isCurrent;

	const breakpoints = useBreakpoint();
	const dispatch = useDispatch();
	const isMobile = breakpoints.includes('xs');

	const isFooterOrdersMobile = !isShowOrders && !isSettingsOrders && isMobile;
	const isFooterOrderMobile = isMobile && !isShowOrder && !isSettingsOrder;

	const isFooterDesktop = !isMobile && activeOrders.length > 0;
	const isFooterMobile = isCurrent ? isFooterOrderMobile : isOrdersMode ? isFooterOrdersMobile : isMobile;
	const isInit = (isFooterMobile || isFooterDesktop) && isDefaultMode;

	const filteredOrders = activeOrders
		.map((id) => ordersList.filter(({ OrderBuyGroupId }) => String(OrderBuyGroupId) === id))
		.flat();

	const filteredOrdersSettings = listOrders.filter((setting) => setting.isShow);
	const filteredOrderSettings = listOrder.filter((setting) => setting.isShow);

	const resOrders = useMemo(
		() =>
			filteredOrders.map((order) => {
				return filteredOrdersSettings.reduce(
					(acc, { id }) => ({ ...acc, [id]: order[id as keyof TGetOrderBuyGroupResponse] }),
					{}
				);
			}),
		[filteredOrders, JSON.stringify(activeOrders)]
	);

	const resGoods = useMemo(() => resExpOrds.filter((pos) => pos.OrderBuyGroupId === current), [resExpOrds]);

	const updateOrder = useCallback(() => {
		const OrderBuys = quantityList.filter(
			({ OrderId, QuantitySupplier }) => OrderId === current && QuantitySupplier !== ''
		);

		dispatch(
			fetchUpdateWithConfirmOrder({ OrderBuyGroupInfoId: '38', OrderBuyGroupId: [current], OrderBuys }, current)
		);
	}, [JSON.stringify(quantityList), current]);

	const saveOrder = useCallback(() => {
		const OrderBuys = quantityList.filter(
			({ OrderId, QuantitySupplier }) => OrderId === current && QuantitySupplier !== ''
		);

		dispatch(
			fetchSaveUpdateOrderBuy({ IsForDraft: '1', OrderBuyGroupInfoId: '38', OrderBuyGroupId: [current], OrderBuys })
		);
	}, [JSON.stringify(quantityList), current]);

	const handleOpen = () => dispatch(setIsAttention());
	const handleClose = () => dispatch(setIsNotAttention());
	const cancelOrders = () => {
		handleClose();
		dispatch(
			fetchCancelOrder({
				OrderBuyGroupId: isCurrent ? [current] : activeOrders,
				OrderBuyGroupInfoId: '3',
				Info: 'Отменить заказ',
				CancelType: isCurrent ? '1' : '2',
			})
		);
	};

	const confirmOrders = () => {
		dispatch(fetchConfirmOrder({ OrderBuyGroupId: activeOrders, ConfirmType: '2' }));
	};

	const onClose = (type: string) => {
		dispatch(setOrderMessage(type));

		if (isCurrent && isNeedRemoveOrder) {
			dispatch(setCurrent({ id: '0' }));
			dispatch(fetchOrderBuyGroup({ ...defaultListOrders }));
			dispatch(setCancelOrder());
			history.push('/orders');
		}

		if (isDefaultMode && isMergedOrders) {
			dispatch(fetchOrderBuyGroup({ ...defaultListOrders }));
			dispatch(setMergedOrders(false));
			dispatch(removeAllActive());
		}

		if (isOrdersMode && cancelOrder.length === ordersList.length) {
			dispatch(setFiltersOrders({ filters: defaultListOrders }));
			dispatch(setCancelOrder());
			dispatch(removeAllActive());
		}
	};

	return (
		<MainLayout>
			<Orders />

			<Footer
				text={isCurrent ? 'Операции с выбранным заказом' : 'Операции с выбранными заказами'}
				fixed={isCurrent ? isCurrent : isLoading}
				visible={isInit}
			>
				<EnableActions />

				{isCurrent && (
					<ActionButton
						text="Сохранить"
						type="save"
						onClick={saveOrder}
						mix={isActive ? styles.save : {}}
						disabled={!isActive}
						hided={isCurrent ? currentInfo.Checkx !== '10' : true}
					/>
				)}

				<ActionButton
					text="Подтвердить поставку"
					type="confirm"
					onClick={isCurrent ? updateOrder : confirmOrders}
					hided={isCurrent ? currentInfo.Checkx !== '10' : false}
				/>

				<ExportOrders
					data={isCurrent ? resGoods : resExpOrds}
					filename={isCurrent ? `Заказ №${current} ` : 'Экспорт заказов'}
				/>

				<PrintItems
					headers={isCurrent ? filteredOrderSettings : filteredOrdersSettings}
					body={isCurrent ? currentGoods : resOrders}
					isMobile={isMobile}
				/>

				<ActionButton text="Отменить заказ" type="cancel" onClick={handleOpen} />

				{isFooterMobile && (
					<ClearFilter type={isCurrent ? 'order' : 'orders'} text="Очистить все фильтры" mobile={true} />
				)}
			</Footer>

			<MultiEditFooter isEnable={(isFooterMobile || isFooterDesktop) && isEditMode} />
			<DivideOrderFooter isEnable={(isFooterMobile || isFooterDesktop) && isDivideMode} />

			<AttentionModal
				title="Отменить заказ"
				description="Данное действие необратимо! Вы ТОЧНО хотите отменить заказ?"
				onClose={handleClose}
				onConfirm={cancelOrders}
				isAttention={isAttention}
			/>

			<ModalListResult messages={messageOrder} onClose={onClose} />
		</MainLayout>
	);
};
