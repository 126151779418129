import React, { FC, PropsWithChildren, DragEvent } from 'react';
import styles from './propDesk.module.scss';
import { ReactComponent as OpenSVG } from 'assets/images/grouping/openwith.svg';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

type TPropsDeskProps = {
	isVisible: boolean;
	onDragOver?: (e: DragEvent<HTMLDivElement>) => void;
};

export const PropsDesc: FC<PropsWithChildren<TPropsDeskProps>> = ({ isVisible, children }) => {
	return isVisible ? (
		<AnimatePresence>
			<motion.div
				className={classNames('dropzone', styles.box__center)}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.7 }}
			>
				<OpenSVG />

				<div className={classNames('dropzone', styles.box__right_text)}>ПЕРЕТАЩИТЕ В ЭТУ ОБЛАСТЬ НАЗВАНИЕ СТОЛБЦА</div>
			</motion.div>
		</AnimatePresence>
	) : (
		<>{children}</>
	);
};
