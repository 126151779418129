import React, { Fragment, useState } from 'react';
import styles from '../goodsDetail.module.scss';
import classNames from 'classnames';
import { GoodRow } from '../Good';
import { IStoreState, TCurrentGood } from 'services/store/interfaces';
import { Arrow } from 'components/InfoCard/Arrow';
import { useDispatch, useSelector } from 'react-redux';
import { setAllActive, setQuantityOrder, removeAllActive } from 'services/store/multiorders';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';
import { removeAllActiveItem, setAllActiveItem, setQuantityDivideRow } from 'services/store/divideOrders';
import { Hint } from '../Hint';
import { fetchCancelOrder } from 'services/store/orders';

interface IOrderTable {
	group: TCurrentGood[];
	type: 'multi' | 'divide';
}
export const OrderTable = ({ group, type }: IOrderTable) => {
	const settingMultiGoods = useSelector((state: IStoreState) => state.setting.listMultiOrder);
	const settingDivideGoods = useSelector((state: IStoreState) => state.setting.listDivide);
	const quantitiesMulti = useSelector((state: IStoreState) => state.multiOrders.quantityList);

	const activeDivide = useSelector((state: IStoreState) => state.divideOrders.orderBuyIdList);
	const divideGoodsList = useSelector((state: IStoreState) => state.divideOrders.goodsInfo);

	const activeMulti = useSelector((state: IStoreState) => state.multiOrders.activeGoods);
	const multiGoodsList = useSelector((state: IStoreState) => state.multiOrders.goodsInfo);

	const orderId = String(group[0].OrderBuyGroupId);
	const divideList = divideGoodsList.filter((group) => group[0].OrderBuyGroupId === orderId);
	const multiList = multiGoodsList.filter((group) => group[0].OrderBuyGroupId === orderId);

	const [visible, setVisible] = useState(true);

	const amountQuantitiesEmpty = quantitiesMulti.filter(
		(quantity) => quantity.OrderId === orderId && quantity.QuantitySupplier === '0'
	).length;
	const isCanceled = type === 'multi' && Number(group[0].Amount) === amountQuantitiesEmpty;

	const dispatch = useDispatch();

	const getIsMultiAllActiveOrderGoods = () => {
		const ids = multiList[0].map((good) => good.OrderBuyId);

		const res = activeMulti.filter((id) => ids.includes(id));
		return res.length === ids.length;
	};

	const getIsChangeMulti = () => {
		const ids = multiList[0].map((good) => good.OrderBuyId);

		const res = activeMulti.filter((id) => ids.includes(id));
		return res.length < ids.length;
	};

	const handlerMultiQuantity = (OrderBuyId: string, QuantitySupplier: string, OrderId: string) => {
		dispatch(setQuantityOrder({ OrderBuyId, QuantitySupplier, OrderId, Info1: 'Изменение количества продукции' }));
	};

	const handlerDivideQuantity = (OrderBuyId: string, QuantitySupplier: string, OrderId: string) => {
		dispatch(setQuantityDivideRow({ OrderBuyId, QuantitySupplier, OrderId }));
	};

	const handlerChange = {
		multi: handlerMultiQuantity,
		divide: handlerDivideQuantity,
	};

	const settings = {
		multi: settingMultiGoods,
		divide: settingDivideGoods,
	};

	const mapIsActive = {
		multi: getIsMultiAllActiveOrderGoods,
		divide: () => activeDivide.length === divideList[0].length,
	};

	const setAllActiveGoods = {
		multi: (id: string) => dispatch(setAllActive(id)),
		divide: (id: string) => dispatch(setAllActiveItem(id)),
	};

	const hideAllActiveGoods = {
		multi: (id: string) => dispatch(removeAllActive(id)),
		divide: () => dispatch(removeAllActiveItem()),
	};

	const handleChangeCheck = {
		multi: () => (getIsChangeMulti() ? setAllActiveGoods.multi(orderId) : hideAllActiveGoods.multi(orderId)),
		divide: () =>
			activeDivide.length < divideList[0].length ? setAllActiveGoods.divide(orderId) : hideAllActiveGoods.divide(),
	};

	return (
		<Fragment>
			<div className={styles.wrapper}>
				<div className={styles.title}>
					Товары - заказ № <span className={styles.title_number}>{orderId}</span>
				</div>
				<div className={styles.tab}>
					{isCanceled && (
						<p
							className={classNames(styles.tab_text, styles.tab_cancel)}
							onClick={() =>
								dispatch(
									fetchCancelOrder({
										OrderBuyGroupId: [orderId],
										OrderBuyGroupInfoId: '3',
										Info: 'Отменить заказ',
										CancelType: '1',
									})
								)
							}
						>
							Отменить заказ
						</p>
					)}

					<p className={styles.tab_text} onClick={() => setVisible(!visible)}>
						{visible ? 'Скрыть' : 'Показать'}
					</p>

					<span className={classNames(styles.tab_arrow, { [styles.rotate]: visible })}>
						<Arrow />
					</span>
				</div>
			</div>

			{visible && (
				<div className={styles.goodsWrapper}>
					<div className={styles.editTable}>
						<div className={styles.editThead}>
							<div className={classNames(styles.editTh, styles.checkGood)}>
								<InputCheckbox isActive={mapIsActive[type]()} onChange={handleChangeCheck[type]} />
							</div>

							{settings[type].map(({ text, id, isShow }) => {
								const isName = id === 'GoodsName';
								const isQuantityWithHint = id === 'QuantitySupplier' && type === 'divide' && activeDivide.length > 0;

								return isShow ? (
									<div
										key={id}
										className={classNames(styles.editTh, {
											[styles.goodName]: isName,
											[styles.hint]: isQuantityWithHint,
										})}
									>
										{text}
										{isQuantityWithHint && (
											<Hint
												text={
													'Если необходимо разделить строку товара на разные заказы, в ячейке справа укажите количество, которое нужно перенести в новый заказ, оставшееся количество (слева) останется в текущем заказе.'
												}
											/>
										)}
									</div>
								) : null;
							})}
						</div>

						<div className={styles.editTbody}>
							{group.map((good, index) => {
								return <GoodRow good={good} key={index} onChange={handlerChange[type]} type={type} />;
							})}
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};
