import { TError } from 'services/store/interfaces';

export const getIsExcel = (blob: Blob): boolean => {
	const { type } = blob;
	return type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
};

export const getJSON = async (blob: Blob): Promise<TError> => {
	return blob.text().then((res: string) => JSON.parse(res).Table[0]);
};
