import { request } from 'services/utils/request';
import { TFilterMessages, TMessagesResponse } from 'services/store/interfaces';

export const portalGetMessage = async (payload: TFilterMessages): Promise<TMessagesResponse[]> => {
	return await request.post(
		'SupplierPortal.GetMessage',
		{
			...payload,
		},
		localStorage.token
	);
};
