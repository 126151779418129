import React, { FC, useRef, useEffect, useState } from 'react';
import styles from './item.module.scss';
import classNames from 'classnames';
import { Points } from 'components/Common/Loading/Points';

type TItemProps = {
	text: string;
	help?: string;
	onClick: () => void;
	loading?: boolean;
};

export const Item: FC<TItemProps> = ({ text, help, onClick, loading }) => {
	const ref = useRef<HTMLDivElement>(null);
	const [left, setLeft] = useState<number>();
	const [isVisibleHelp, setIsVisibleHelp] = useState(false);

	useEffect(() => {
		if (!ref.current) return;

		setLeft(ref.current.getBoundingClientRect().width);
	}, [ref?.current?.getBoundingClientRect().width]);

	return (
		<div
			className={classNames(styles.item, {
				[styles.item__withHelp]: help && isVisibleHelp,
				[styles.item__withoutHelp]: !help || !isVisibleHelp,
			})}
			key="confirm"
			onClick={onClick}
			onMouseOver={() => setIsVisibleHelp(true)}
			onMouseLeave={() => setIsVisibleHelp(false)}
			ref={ref}
		>
			{loading ? (
				<>
					{text}
					<Points />
				</>
			) : (
				text
			)}

			{help && isVisibleHelp && (
				<div className={styles.item__help} style={{ left, height: ref?.current?.style.height }}>
					{help}
				</div>
			)}
		</div>
	);
};
