import React, { FC } from 'react';
import { TSVGProps } from './PromoSVG';

export const ArrowSVG: FC<TSVGProps> = ({ width = 14, height = 8, isVisible }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M2.14541 0.355353L7.00626 5.0691L11.8671 0.355353C12.3557 -0.118451 13.145 -0.118451 13.6336 0.355353C14.1221 0.829157 14.1221 1.59453 13.6336 2.06834L7.88322 7.64465C7.39463 8.11845 6.60537 8.11845 6.11678 7.64465L0.366443 2.06834C-0.122148 1.59453 -0.122148 0.829157 0.366443 0.355353C0.855033 -0.106302 1.65682 -0.118451 2.14541 0.355353Z"
				fill={isVisible ? '#8DC640' : '#8A9099'}
			/>
		</svg>
	);
};
