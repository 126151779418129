import React, { Fragment, useState } from 'react';
import { IStoreState, TCurrentGood } from 'services/store/interfaces';
import styles from '../infoCard.module.scss';
import classNames from 'classnames';
import { InputCheckbox } from '../../Common/Input/InputCheckbox';
import { PropertyBlock } from '../PropertyBlock';
import { More } from '../MoreRow';
import { useSelector } from 'react-redux';

interface IGoodsCardsProps {
	onChange: (value: boolean) => (value: string) => void;
	count: number;
}
export const GoodsCards = ({ onChange, count }: IGoodsCardsProps) => {
	const goodsList = useSelector((state: IStoreState) => state.goods.goodsList);
	const goodsIdActive = useSelector((state: IStoreState) => state.goods.activeGoods);
	const settingsGoods = useSelector((state: IStoreState) => state.setting.listGoods);

	const [isOpen, setIsOpen] = useState(false);
	const onClickMore = () => setIsOpen(!isOpen);

	return (
		<Fragment>
			{goodsList.map((item: TCurrentGood, index) => {
				return index < count ? (
					<div className={styles.item} key={index}>
						<div className={styles.property}>
							<div className={classNames(styles.name, styles.header)}>{item.GoodsName}</div>
							<div className={styles.value}>
								<InputCheckbox
									isActive={goodsIdActive.includes(String(item.Id))}
									onChange={(isActive) => onChange(isActive)(String(item.Id))}
								/>
							</div>
						</div>

						{settingsGoods.map(({ id, isShow, text }, index) => {
							const count = isOpen ? settingsGoods.length : 3;

							return (
								isShow && index < count && <PropertyBlock key={id} name={text} value={item[id as keyof TCurrentGood]} />
							);
						})}

						<More isOpen={isOpen} onClickMore={onClickMore} />
					</div>
				) : null;
			})}
		</Fragment>
	);
};
