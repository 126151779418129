import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import styles from './card.module.scss';
import { TCurrentOrder } from 'services/store/interfaces';
import { Arrow } from 'components/InfoCard/Arrow';
import { CardGoodsMobile } from '../CardGoodsMobile';

interface ICardOrderProps {
	order: TCurrentOrder;
	properties: Record<string, string>[];
	type: 'multi' | 'divide';
}
export const CardOrderMobile = ({ order, properties, type }: ICardOrderProps) => {
	const [visible, setVisible] = useState(true);
	const [visibleGoods, setVisibleGoods] = useState(false);

	const ids = properties.filter((property) => property.id === 'OrderBuyGroupId');
	const others = properties.filter((property) => property.id !== 'OrderBuyGroupId');

	return (
		<div className={styles.card}>
			{ids.map((property) => {
				return (
					<div
						key={property.id}
						className={classNames(styles.card_property, styles.card_id, { [styles.card_margin]: !visible })}
					>
						<Fragment>
							<div className={styles.card_name}>
								Заказ №<span className={styles.card_number}>{order[property.id as keyof TCurrentOrder]}</span>
							</div>
							<div
								className={classNames(styles.card_arrow, { [styles.card_visible]: visible })}
								onClick={() => setVisible(!visible)}
							>
								<Arrow />
							</div>
						</Fragment>
					</div>
				);
			})}

			{visible && (
				<Fragment>
					{others.map((property) => {
						return (
							<div
								key={property.id}
								className={classNames(styles.card_property, {
									[styles.card_margin]: !visible,
								})}
							>
								<Fragment>
									<div className={styles.card_property_text}>{property.value}</div>
									<div className={styles.card_property_value}>{order[property.id as keyof TCurrentOrder]}</div>
								</Fragment>
							</div>
						);
					})}
					<div className={styles.goodsTitle} onClick={() => setVisibleGoods(!visibleGoods)}>
						<span className={styles.goodsHeader}>Товары в заказе</span>
						<span className={classNames(styles.goodsTitle_arrow, { [styles.goodsTitle_visible]: visibleGoods })}>
							<Arrow />
						</span>
					</div>

					{visibleGoods && <CardGoodsMobile type={type} id={String(order.OrderBuyGroupId)} />}
				</Fragment>
			)}
		</div>
	);
};
