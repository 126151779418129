import React from 'react';
import styles from './table.module.scss';
import { InfoTable } from '../../../../../InfoTable';

export const Table = () => {
	return (
		<div className={styles.table} style={{ minHeight: `calc(100vh - 495px)` }}>
			<InfoTable type="planning" />
		</div>
	);
};
