import React from 'react';
import styles from './mobile.module.scss';
import useBreakpoint from 'services/hooks/useBreakpoint';

interface IMobileProps {
	children?: React.ReactNode;
}

export const Mobile = ({ children }: IMobileProps) => {
	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');

	return isMobile ? <div className={styles.mobile}>{children}</div> : null;
};
