import { TReportPlanningExport } from 'services/store/interfaces/reports';
import { requestFile } from '../../utils/requestFile';

export const getContractorDemandPlanForExport = async (payload: TReportPlanningExport): Promise<any> => {
	return await requestFile.post(
		'SupplierPortal.DemandPlanForExportGet',
		{
			...payload,
		},
		localStorage.token
	);
};
