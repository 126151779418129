export const getLicense = (value: string) => {
	switch (value) {
		case '1':
			return { id: '1', value: 'Табак' };
		case '2':
			return { id: '2', value: 'Алкоголь' };
		case '0':
		default:
			return { id: '0', value: 'Нет контроля' };
	}
};
