import React, { memo } from 'react';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import styles from 'components/Content/Events/FilterEvents/filterEvents.module.scss';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { Dayjs } from 'dayjs';

interface IDateFieldsProps {
	onBegin: (value: unknown, dateString: string) => void;
	onEnd: (value: unknown, dateString: string) => void;
	begin?: null | Dayjs;
	end?: null | Dayjs;
}
export const DateFields = memo(({ onBegin, onEnd, begin, end }: IDateFieldsProps) => {
	return (
		<div className={classNames(styles.item_line)}>
			<DatePicker
				onChange={onBegin}
				value={begin}
				className={styles.item_line__item}
				placeholder=""
				locale={locale}
				allowClear={false}
				showToday={false}
			/>

			<DatePicker
				onChange={onEnd}
				value={end}
				className={styles.item_line__item}
				placeholder=""
				locale={locale}
				allowClear={false}
				showToday={false}
			/>
		</div>
	);
});
