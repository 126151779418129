import React, { FC } from 'react';
import styles from '../../Mass/List/list.module.scss';
import { Modal } from '../../Mass/Modal';

interface IAttentionProps {
	title: string;
	description: string;
	onClose: () => void;
	onConfirm: () => void;
	isAttention: boolean;
}
export const AttentionModal: FC<IAttentionProps> = ({ title, description, onClose, onConfirm, isAttention }) => {
	return isAttention ? (
		<Modal title={title} description={description} onClose={onClose}>
			<div className={styles.close} onClick={onClose}>
				Закрыть
			</div>

			<div className={styles.confirm} onClick={onConfirm}>
				Да
			</div>
		</Modal>
	) : null;
};
