import React, { memo } from 'react';
import styles from './property.module.scss';
import { Loader } from 'components/Common/Loading/Loader';
import { UpdateDate } from '../../../../../UpdateDate';
import classNames from 'classnames';
import { isAvaibleDate } from 'services/utils/helpers/date';

interface IPropertyProps {
	name?: string;
	value?: string | React.ReactNode;
	type?: 'update' | 'correct' | 'other';
	dateEnd?: string;
	dateStart?: string;
	id?: string;
	mix?: string;
}

export const Property = memo(({ mix, name, value, type = 'other', dateEnd, dateStart, id }: IPropertyProps) => {
	const isUpdate = type === 'update';
	const isCorrect = type === 'correct';

	const isEdit = (isUpdate || isCorrect) && isAvaibleDate(dateStart || '');

	const item = isEdit ? (
		<UpdateDate type={type} dateStart={String(dateStart)} dateEnd={String(dateEnd)} id={String(id)} isOrder={true} />
	) : (
		value
	);

	return (
		<div className={styles.wrapper}>
			<div className={styles.property}>
				<div className={styles.property__name}>{name}</div>
				<div className={classNames(styles.property__value, item ? mix : '')}>{item ? item : <Loader />}</div>
			</div>
		</div>
	);
});
