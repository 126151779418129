import React, { useState } from 'react';
import styles from './eventItem.module.scss';
import { ReactComponent as BellSVG } from 'assets/images/events/bell.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setCurrentEvent } from 'services/store/events';
import {
	fetchPortalUpdateCheckxMessage,
	hideMessage,
	hideMessageSidebar,
	setCurrentNotification,
} from 'services/store/notifications';
import { ReactComponent as ArrowSVG } from 'assets/images/events/arrow.svg';
import { Mobile } from 'components/HOCS/Mobile';
import { CalendarSVG } from 'assets/images/events';

interface TEventProps {
	id: string;
	DayId?: string;
	title?: string;
	description?: string;
	status?: string;
	date?: string;
	type: 'events' | 'notifications';
	checkx?: string;
	isCurrent?: boolean;
	mix?: object;
}

const setImage = {
	events: <CalendarSVG />,
	notifications: <BellSVG />,
};

export const EventItem = ({
	id,
	title,
	description = '',
	date,
	type,
	checkx,
	isCurrent = false,
	status = '',
}: TEventProps) => {
	const [isVisible, setIsVisible] = useState(false);

	// const activeEvents = useSelector((state: IStoreState) => state.events.activeEvents);
	// const activeNotifications = useSelector((state: IStoreState) => state.notifications.activeNotifications);
	// const checkedEvent = (value: string) => {
	// 	const isActive = activeEvents.includes(value);
	// 	const payload = { id: value };
	// 	dispatch(isActive ? removeEvent(payload) : addEvent(payload));
	// };
	// const checkedNotification = (value: string) => {
	// 	const isActive = activeNotifications.includes(value);
	// 	const payload = { id: value };
	// 	dispatch(isActive ? removeNotification(payload) : addNotification(payload));
	// };

	const setCurrent = {
		events: (id: string) => dispatch(setCurrentEvent(id)),
		notifications: (id: string) => dispatch(setCurrentNotification(id)),
	};

	// const handleChecked = {
	// 	events: checkedEvent,
	// 	notifications: checkedNotification
	// };

	const isReadEvents = checkx === 'read';
	const dispatch = useDispatch();

	const handleClick = {
		events: () => setIsVisible((prevState) => !prevState),
		notifications: async (MessageId: string) => {
			if (isVisible) {
				await dispatch(fetchPortalUpdateCheckxMessage({ MessageId }));
				await dispatch(hideMessageSidebar(id));
				await dispatch(hideMessage(id));

				setIsVisible(false);
			}

			if (!isVisible) setIsVisible(true);
		},
	};

	// const checkbox = (
	// 	<div className={styles.eventItemBlock__checked}>
	// 		<InputCheckbox onChange={() => handleChecked[type](String(id))} isActive={activeItems[type].includes(String(id))} />
	// 	</div>
	// );

	const textMobile = (
		<div
			className={classNames(styles.eventItem__title, {
				[styles.active]: isVisible,
				[styles.eventItem__current]: isCurrent,
			})}
			onClick={() => (description?.length > 60 ? setCurrent[type](id) : {})}
			dangerouslySetInnerHTML={{ __html: String(description) }}
		/>
	);

	return (
		<OutsideClickHandler onOutsideClick={() => setIsVisible(false)}>
			<div className={styles.eventItemBlock}>
				{/*<Desktop>{checkbox}</Desktop>*/}

				<div
					className={classNames(styles.eventItem, {
						[styles.eventItem__read]: isReadEvents,
						[styles.eventItem__current]: isCurrent,
					})}
				>
					<div
						className={classNames(styles.top, {
							[styles.visible]: isVisible,
						})}
						onClick={() => handleClick[type](id)}
					>
						<div className={styles.left}>
							<div
								className={classNames(styles.eventItem__status, {
									[styles.error]: ['4', '5', '7'].includes(status),
									[styles.success]: ['1', '2', '3'].includes(status),
									[styles.warning]: ['6'].includes(status),
								})}
							>
								{setImage[type]}
							</div>
							<div className={styles.eventItem__number} dangerouslySetInnerHTML={{ __html: String(title) }} />
						</div>

						<Mobile>{textMobile}</Mobile>

						<div className={styles.right}>
							<div className={styles.eventItem__date}>{date}</div>

							<div
								className={classNames(styles.eventItem__arrow, {
									[styles.visible]: isVisible,
								})}
							>
								<ArrowSVG />
							</div>
						</div>
					</div>

					{isVisible && <div className={styles.bottom} dangerouslySetInnerHTML={{ __html: String(description) }} />}
				</div>
			</div>
		</OutsideClickHandler>
	);
};
