import React, { useState } from 'react';
import { Dropdown } from '../Common/Dropdown';
import { Button } from './Button';
import styles from './mass.module.scss';
import { List } from './List';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

export const Mass = () => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
			<div
				className={classNames(styles.mass, {
					[styles.mass_opened]: isOpen,
				})}
			>
				<Dropdown button={<Button onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />} isOpen={isOpen}>
					<List onClose={() => setIsOpen(false)} />
				</Dropdown>
			</div>
		</OutsideClickHandler>
	);
};
