import React from 'react';
import { exportToCSV } from 'services/utils/js/exportToCSV';
import { ActionButton } from 'components/Common/ActionButton';
import { TCurrentGood, TGetOrderBuyGroupResponse } from 'services/store/interfaces';
import { getOrderStatus } from 'services/utils/js/getOrderStatus';

type TUnionKeys = TCurrentGood | TGetOrderBuyGroupResponse;
interface IExportDataProps {
	data: TUnionKeys[];
	filename: string;
}

export const fieldsOrdersTable = [
	{ id: '0', name: 'OrderBuyGroupId', title: 'Код заказа поставщику' },
	{ id: '1', name: 'Checkx', title: 'Статус заказа' },
	{ id: '2', name: 'Datex', title: 'Дата поставки' },
	{ id: '3', name: 'WarehouseId', title: 'Номер склада' },
	{ id: '4', name: 'LineNumber', title: 'Номер строки' },
	{ id: '5', name: 'GoodsId', title: 'Код товара' },
	{ id: '6', name: 'GoodsName', title: 'Наименование товара' },
	{ id: '7', name: 'Barcode1', title: 'Штрих-код товара' },
	{ id: '8', name: 'QuantityOrder', title: 'Кол-во подтвержденного товара' },
	{ id: '9', name: 'OrderSum', title: 'Сумма подтвержденных товаров без НДС' },
	{ id: '10', name: 'QuantityAdd2', title: 'Кол-во заказанного товара' },
	{ id: '11', name: 'Sum2Add2', title: 'Сумма заказанных товаров без НДС' },
	{ id: '12', name: 'Firstx', title: 'Закупочная цена без НДС' },
	{ id: '13', name: 'Price', title: 'Закупочная цена' },
	{ id: '14', name: 'Price2', title: 'Отпускная цена с учетом скидки' },
	{ id: '15', name: 'ShipmentDateLast', title: 'Дата крайней поставки' },
	{ id: '16', name: 'DepartmentAddress', title: 'Адрес склада' },
	{ id: '17', name: 'QuantityInPack', title: 'Кол-во в упаковке (ед.)' },
	{ id: '18', name: 'QuantityInPallet', title: 'Кол-во на паллете (ед.)' },
	{ id: '19', name: 'GLNWarehouse', title: 'GLN номер склада' },
];

export const getResultPropsTable = (data: TUnionKeys[]) => {
	return data.map((item) =>
		fieldsOrdersTable.reduce((acc, field) => {
			const value = field.name === 'Checkx' ? getOrderStatus(item)?.text : item[field.name as keyof TUnionKeys];

			acc = { ...acc, [field.title]: value };
			return acc;
		}, {})
	);
};

export const ExportOrders = ({ data, filename }: IExportDataProps) => {
	return (
		<ActionButton
			text="Экспорт"
			type="export"
			onClick={() => exportToCSV(getResultPropsTable(data), filename, [6, 16], [1, 2, 6, 14, 15, 16, 19, 7])}
		/>
	);
};
