import React from 'react';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import styles from './goodsDetail.module.scss';
import { Loading } from 'components/Common/Loading';
import { OrderTable } from './OrderTable';

interface IGoodsDetailProps {
	type: 'multi' | 'divide';
}
export const GoodsDetail = ({ type }: IGoodsDetailProps) => {
	const isLoadingMulti = useSelector((state: IStoreState) => state.multiOrders.isLoading);
	const isLoadingDivide = useSelector((state: IStoreState) => state.divideOrders.isLoading);

	const multiGoods = useSelector((state: IStoreState) => state.multiOrders.goodsInfo);
	const divideGoods = useSelector((state: IStoreState) => state.divideOrders.goodsInfo);

	const goods = {
		multi: multiGoods,
		divide: divideGoods,
	};

	const isLoading = {
		multi: isLoadingMulti,
		divide: isLoadingDivide,
	};

	return (
		<div className={styles.goodsList}>
			{isLoading[type] ? (
				<Loading />
			) : (
				goods[type].map((group, inx) => {
					return <OrderTable key={inx} group={group} type={type} />;
				})
			)}
		</div>
	);
};
