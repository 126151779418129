import React, { Fragment, useRef } from 'react';
import styles from './index.module.scss';
import { IStoreState } from 'services/store/interfaces';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { UserTable } from 'components/UserTable';
import { profileFields, setClipboard } from 'services/store/user';
import { MainLayout } from 'layouts/Main';
import { Loading } from 'components/Common/Loading';
import { Footer } from 'components/Footer';
import { ActionButton } from 'components/Common/ActionButton';
import { clipboardProfile } from 'components/Clipboard/ClipboardProfile';
import ReactToPrint from 'react-to-print';
import { ModalW } from 'components/Modals';

export const ProfilePage = () => {
	const user = useSelector((state: IStoreState) => state.user.value);
	const loading = useSelector((state: IStoreState) => state.user.loading);
	const clipboard = useSelector((state: IStoreState) => state.user.clipboard);
	const componentRef = useRef<HTMLDivElement>(null);
	const message = useSelector((state: IStoreState) => state.user.message);

	const modalIsOpen = clipboard.text !== '';
	const dispatcher = useDispatch();

	const onClose = () => dispatcher(setClipboard({ text: '' }));
	const setMes = () => dispatcher(setClipboard({ text: 'Данные скопированы успешно' }));

	return (
		<MainLayout>
			<div className={styles.profile}>
				<h1>
					Информация <span className={styles.header}>о поставщике </span>
				</h1>
				{loading ? (
					<Loading />
				) : (
					<Fragment>
						<div className={styles.main} ref={componentRef}>
							{user ? <UserTable user={user} /> : <p className={styles.empty}>{message.text}</p>}
						</div>

						<Footer text={'© 2022 ООО «Евроторг». Все права защищены.'} visible={true}>
							<ReactToPrint
								content={() => componentRef.current}
								trigger={() => <ActionButton text={'Распечатать'} type={'print'} />}
							/>

							<ActionButton
								text={'Копировать в буфер'}
								type={'buffer'}
								onClick={user ? () => clipboardProfile(profileFields, user, setMes) : () => {}}
							/>
						</Footer>
					</Fragment>
				)}
			</div>

			<ModalW isOpen={modalIsOpen} message={clipboard.text} onCancel={onClose} />
		</MainLayout>
	);
};
