import React from 'react';
import classNames from 'classnames';
import styles from '../messagesMenu.module.scss';
import { ReactComponent as CloseSVG } from 'assets/images/header/close.svg';
import { ReactComponent as BellSVG } from 'assets/images/events/bell.svg';
import { fetchSidebarUpdateCheckxMessage, hideMessage, hideMessageSidebar } from 'services/store/notifications';
import { useDispatch } from 'react-redux';

interface IMessage {
	MessageId: string;
	Timex: string;
	MessageText: string;
	MessageTypeId: string;
}
export const Message = ({ MessageId = '', Timex, MessageText, MessageTypeId = '' }: IMessage) => {
	const dispatch = useDispatch();

	const handlerClick = async () => {
		await dispatch(fetchSidebarUpdateCheckxMessage({ MessageId }));
		await dispatch(hideMessageSidebar(MessageId));
		await dispatch(hideMessage(MessageId));
	};

	return (
		<div className={styles.event}>
			<div className={styles.event_actions}>
				<div className={styles.event_date_block}>
					<div
						className={classNames(styles.event_type, {
							[styles.success]: ['1', '2', '3'].includes(MessageTypeId),
							[styles.warning]: ['6'].includes(MessageTypeId),
							[styles.error]: ['4', '5', '7'].includes(MessageTypeId),
						})}
					>
						<BellSVG />
					</div>

					<div className={styles.event_date}>{Timex}</div>
				</div>

				<div className={styles.event_close} onClick={handlerClick}>
					<CloseSVG />
				</div>
			</div>

			<div className={styles.event_title} dangerouslySetInnerHTML={{ __html: String(MessageText) }} />
		</div>
	);
};
