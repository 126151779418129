import React from 'react';
import styles from './inputText.module.scss';
import classNames from 'classnames';

interface IInputTextProps {
	onChange?: (value: string) => void;
	value?: string;
	mix?: string;
	label?: string;
	onFocus?: () => void;
}

export const InputText = ({ mix, label, value = '', onChange = () => {}, onFocus = () => {} }: IInputTextProps) => (
	<div className={classNames(styles.inputText, mix)}>
		<input
			placeholder={label}
			type="text"
			value={value}
			onChange={(e) => onChange(e.target.value)}
			onFocus={onFocus}
			className={styles.inputText__input}
		/>
	</div>
);
