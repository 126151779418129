import { request } from 'services/utils/request';
import { TConfirmOrderPayload, TCancelConfirmOrderResponse } from 'services/store/interfaces';

export const confirmOrderBuyGroup = async (payload: TConfirmOrderPayload): Promise<TCancelConfirmOrderResponse[]> => {
	return await request.post(
		'SupplierPortal.ConfirmOrderBuyGroup',
		{
			...payload,
		},
		localStorage.token
	);
};
