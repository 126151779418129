import React, { useState } from 'react';
import styles from './updateDate.module.scss';
import { DatePicker } from 'antd';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import type { RangePickerProps } from 'antd/es/date-picker';
import { ReactComponent as EditSVG } from 'assets/images/actions/edit.svg';
import { ReactComponent as CorrectSVG } from 'assets/images/events/bell.svg';
import dayjs from 'dayjs';
import { getValidDate } from 'services/utils/js/getValidDate';
import { useDispatch } from 'react-redux';
import { fetchOrderBuyGroupIsRevised, fetchUpdateOrderBuyGroup } from 'services/store/orders';
import classNames from 'classnames';
import { endPoint, startPoint } from 'services/utils/helpers/date';

interface IUpdateDateProps {
	isOrder?: boolean;
	dateEnd: string;
	dateStart: string;
	id: string;
	type: 'correct' | 'update';
}

export const UpdateDate = ({ id, type, dateStart, isOrder }: IUpdateDateProps) => {
	const [isShow, setIsShow] = useState(false);
	const [date, setDate] = useState(dateStart);
	const dispatch = useDispatch();

	const isUpdate = type === 'update';
	const isCorrect = type === 'correct';

	const isDate = !isShow;
	const isPicker = isShow;

	const openDatePicker = () => setIsShow(true);
	const disabledDate: RangePickerProps['disabledDate'] = (current) => {
		return (
			current.isBefore(dayjs().subtract(1, 'day')) ||
			current.isBefore(startPoint(dateStart), 'day') ||
			current.isAfter(endPoint(dateStart), 'day')
		);
	};

	const updateData = () => setIsShow(false);
	const onUpdateDate = (value: unknown, dateString: string) => {
		dispatch(
			fetchUpdateOrderBuyGroup({
				OrderBuyGroupId: id,
				Datex: dateString,
				Info: `Просим изменить дату поставки на ${dateString}`,
				OrderBuyGroupInfoId: 10,
			})
		).then(() => setDate(dateString.split('-').reverse().join('.')));

		updateData();
	};

	const onCorrectDate = (value: unknown, dateString: string) => {
		dispatch(
			fetchOrderBuyGroupIsRevised({
				OrderBuyGroupInfoId: '40',
				Info: dateString,
				OrderBuyGroupId: id,
			})
		).then();

		updateData();
	};

	return (
		<div
			className={classNames(styles.update, {
				[styles.order]: isOrder,
			})}
		>
			{isDate && (
				<div className={styles.date} onClick={openDatePicker}>
					{date}
				</div>
			)}

			{isPicker && (
				<DatePicker
					locale={locale}
					placeholder=""
					disabledDate={disabledDate}
					defaultValue={dayjs(getValidDate(date))}
					allowClear={false}
					onChange={isUpdate ? onUpdateDate : onCorrectDate}
					showToday={false}
				/>
			)}

			<div className={styles.edit} onClick={() => setIsShow(!isShow)}>
				{isUpdate && <EditSVG />}
				{isCorrect && <CorrectSVG />}
			</div>
		</div>
	);
};
