import { request } from 'services/utils/request';
import { TResponse } from 'services/store/interfaces';
import { TCheckNextOrderDatePayload } from 'services/store/interfaces/checkNextOrder';

export const checkNextOrderDate = async (payload: TCheckNextOrderDatePayload): Promise<TResponse[]> => {
	return await request.post(
		'SupplierPortal.CheckNextOrderDate',
		{
			...payload,
		},
		localStorage.token
	);
};
