import React, { FC } from 'react';
import styles from './message.module.scss';
import { TDownTimesMessage } from 'services/store/interfaces/downTimes';
import classNames from 'classnames';

type TMessageProps = {
	message: TDownTimesMessage[];
	isHidden: boolean;
	isSuccess: boolean;
	isError: boolean;
};
export const Message: FC<TMessageProps> = ({ message, isHidden, isError, isSuccess }) => {
	return (
		<div className={styles.message}>
			{message.map(({ text }, inx) => {
				return (
					<div
						key={inx}
						className={classNames({
							[styles.message_hidden]: isHidden,
							[styles.message_success]: isSuccess,
							[styles.message_error]: isError,
						})}
					>
						{text}
					</div>
				);
			})}
		</div>
	);
};
