import React from 'react';
import styles from './homeText.module.scss';

export const HomeText = () => {
	return (
		<div className={styles.homeText}>
			<h1 className={styles.homeText__header}>Добро пожаловать в личный кабинет!</h1>
			<p className={styles.homeText__text}>
				Скоро здесь появится график и монитор ваших показателей, также здесь вы можете ознакомиться с инструкциями и
				пройти бесплатные онлайн-уроки обучения работе на портале.
			</p>
		</div>
	);
};
