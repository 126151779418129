import React, { FC } from 'react';
import styles from './textarea.module.scss';

type TTextareaProps = {
	value: string;
	onChange: (value: string) => void;
};
export const Textarea: FC<TTextareaProps> = ({ value, onChange }) => {
	return (
		<div className={styles.textarea}>
			<textarea
				rows={6}
				value={value}
				onChange={(event) => onChange(event.target.value)}
				className={styles.textarea_textarea}
				style={{ resize: 'none' }}
			/>
		</div>
	);
};
