import { TReportsData, TReportsFilters } from 'services/store/interfaces/reports';
import { request } from 'services/utils/request';

export const getDataReports = async (payload: TReportsFilters): Promise<TReportsData> => {
	return await request.post(
		'SupplierPortal.GetDataReports',
		{
			...payload,
		},
		localStorage.token
	);
};
