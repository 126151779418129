import React, { Fragment, useEffect } from 'react';
import { Footer } from '../index';
import { DivideAction } from './DivideAction';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { useHistory } from 'react-router';
import { removeAllActive, setCurrent } from 'services/store/orders';
import { defaultListOrders, setFiltersOrders } from 'services/store/filter';
import { closeModal, fetchDivideOrderBuy, setDefault } from 'services/store/divideOrders';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { ModalW } from '../../Modals';

interface IDivideOrderFooter {
	isEnable: boolean;
}
export const DivideOrderFooter = ({ isEnable }: IDivideOrderFooter) => {
	const breakpoint = useBreakpoint();
	const isMobile = ['xs'].includes(breakpoint);

	const message = useSelector((state: IStoreState) => state.divideOrders.message);
	const activeBuys = useSelector((state: IStoreState) => state.divideOrders.orderBuyIdList);
	const quantities = useSelector((state: IStoreState) => state.divideOrders.quantityList);
	const OrderBuyGroupId = useSelector((state: IStoreState) => state.orders.activeOrders)[0];
	const isDivided = useSelector((state: IStoreState) => state.divideOrders.isDivided);
	const isLoadingDivide = useSelector((state: IStoreState) => state.divideOrders.isLoadingDivide);
	const quantitiesList = activeBuys.map((OrderBuyId) => quantities.filter((item) => item.OrderBuyId === OrderBuyId)[0]);
	const isOpenModal = message.text !== '';

	const isSuccess = message.type === 'success';
	const isError = message.type === 'error';
	const isCentered = ['', 'divide', 'separate', 'unsave'].includes(message.type);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (isDivided && message.text === '') {
			history.push('/orders');
			dispatch(setCurrent({ id: '0' }));
			dispatch(setFiltersOrders({ filters: defaultListOrders }));
			dispatch(removeAllActive());
			dispatch(setDefault());
		}
	});

	const onClose = () => dispatch(closeModal());
	const onConfirmDivide = () => {
		const OrderBuys = isMobile ? quantities : quantitiesList.filter((quantity) => quantity.QuantitySupplier !== '');

		dispatch(fetchDivideOrderBuy({ OrderBuyGroupId, OrderBuys }));
	};

	return isEnable ? (
		<Fragment>
			<Footer text="Операции с выбран. заказами" visible={true} fixed={true}>
				<DivideAction disabled={isMobile ? quantities.length === 0 : quantitiesList.length === 0} />
			</Footer>

			<ModalW
				onConfirm={onConfirmDivide}
				isOpen={isOpenModal}
				title={message.title}
				message={message.text}
				type={message.type}
				links={message.links}
				onCancel={onClose}
				isCentered={isCentered}
				isRight={isSuccess || isError}
				isLoading={isLoadingDivide}
			/>
		</Fragment>
	) : null;
};
