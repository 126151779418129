import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-color';
import { getCurrentDate } from 'services/utils/js/getFilterDate';
import { TCurrentGood, TGetOrderBuyGroupResponse } from 'services/store/interfaces';

type TWidthProp = {
	width: number;
};

const stlHeaders = {
	fill: {
		patternType: 'solid',
		fgColor: { rgb: 'ffffa5' },
		bgColor: { rgb: 'ffffa5' },
	},
	alignment: {
		vertical: 'center',
		horizontal: 'center',
		wrapText: '1',
	},
	font: {
		sz: 11,
		bold: true,
	},
	border: {
		right: {
			style: 'thin',
			color: '000000',
		},
		left: {
			style: 'thin',
			color: '000000',
		},
		bottom: {
			style: 'thin',
			color: '000000',
		},
	},
};

export const exportToCSV = (
	csvData: (TGetOrderBuyGroupResponse | TCurrentGood)[],
	fileName: string,
	colsBig: number[],
	numeric?: number[]
) => {
	if (csvData.length < 1) return;

	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
	const fileExtension = '.xlsx';
	const date = getCurrentDate();

	const ws = XLSX.utils.json_to_sheet(csvData);
	let widths: TWidthProp[] = [];

	for (const i in ws) {
		if (typeof ws[i] != 'object') continue;
		const cell = XLSX.utils.decode_cell(i);

		widths = colsBig.includes(cell.c) ? [...widths, { width: 70 }] : [...widths, { width: 20 }];

		ws[i].s = {
			font: {
				sz: '10',
			},
			alignment: {
				vertical: 'center',
				horizontal: 'center',
			},
		};

		if (cell.r === 0) ws[i].s = stlHeaders;
		if (cell.r !== 0 && cell.c === 16)
			ws[i].s = {
				font: {
					sz: '10',
				},
				alignment: {
					vertical: 'center',
					horizontal: 'left',
				},
			};

		if (numeric && cell.r !== 0 && !numeric.includes(cell.c)) {
			ws[i].v = Number(ws[i].v);
			ws[i].t = 'n';
		}
	}

	ws['!cols'] = [...widths];
	ws['!autofilter'] = { ref: 'A1:S1' };

	const wb = { Sheets: { List1: ws }, SheetNames: ['List1'] };
	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, `${fileName}, ${date}` + fileExtension);
};

export const exportFile = (data: Blob, title = 'Экспорт заказов') => {
	const file = window.URL.createObjectURL(data);
	const link = document.createElement('a');
	link.href = file;
	link.download = `${title} - ${getCurrentDate()}.xlsx`;

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
