export const title = {
	confirm: 'Подтвердить поставки',
	cancel: 'Отменить поставки',
	export: 'Экспорт заказов',
};

export const helps = {
	confirm: 'Подтверждение заказов в статусе «На сайте»',
	cancel: 'Отмена заказов в статусе «На сайте»',
	export: undefined,
};

export const description = {
	confirm: 'Будет произведено подтверждение заказов, находящихся в статусе "На сайте"',
	cancel: 'Будет произведена отмена заказов, находящихся \n' + 'в статусе "На сайте"',
	export: 'Экспорт заказов может занять какое-то время',
};

export const text = {
	confirm: 'Подтвердить',
	cancel: 'Да',
	export: 'Продолжить',
};

export const ACTIONS = [{ id: 'confirm' }, { id: 'cancel' }, { id: 'export' }];
