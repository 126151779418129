import { request } from 'services/utils/request';
import { TOrderBuyGroupIsRevisedPayload, TOrderBuyGroupIsRevisedResponse } from 'services/store/interfaces';
export const requestOrderBuyGroupIsRevised = async (
	payload: TOrderBuyGroupIsRevisedPayload
): Promise<TOrderBuyGroupIsRevisedResponse[]> => {
	return await request.post(
		'SupplierPortal.RequestOrderBuyGroupIsRevised',
		{
			...payload,
		},
		localStorage.token
	);
};
