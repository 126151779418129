import { TGetContractorResponse } from 'services/api/getContractorInfo';

export function clipboardProfile(
	headers: { type: string; value: string; editable: boolean }[],
	user: TGetContractorResponse,
	func: () => void
) {
	const result = headers
		.map(({ type, value }) => {
			const dataString = user[type as keyof TGetContractorResponse] || '---';

			return `${value}${' '.repeat(60 - value.length)}${dataString}`;
		})
		.join('\n');

	navigator.clipboard.writeText(result).then(func);
}
