import React, { Fragment } from 'react';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import { OrdersRow } from '../OrdersRow';

interface IInfoOrdersProps {
	onChange: (value: boolean) => (value: string) => void;
}
export const InfoOrders = ({ onChange }: IInfoOrdersProps) => {
	const { ordersList } = useSelector((state: IStoreState) => state.orders);

	return (
		<Fragment>
			{ordersList.map((order) => (
				<OrdersRow order={order} onChange={onChange} key={String(order.OrderBuyGroupId)} />
			))}
		</Fragment>
	);
};
