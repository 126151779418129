import React, { Fragment } from 'react';
import { InfoCard } from 'components/InfoCard';
import { Desktop } from 'components/HOCS/Desktop';
import { Mobile } from 'components/HOCS/Mobile';
import { WithGroupInfoOrders } from 'components/HOCS/WithGroupInfoOrders';
import { useDispatch } from 'react-redux';
import { addOrder, removeOrder } from 'services/store/orders';
import styles from './ordersTable.module.scss';

export const OrdersTable = () => {
	const dispatch = useDispatch();

	const handleOrder = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addOrder({ id })) : dispatch(removeOrder({ id })));
	};

	return (
		<Fragment>
			<Desktop>
				<div className={styles.scrollBar}>
					<WithGroupInfoOrders onChange={handleOrder} />
				</div>
			</Desktop>

			<Mobile>
				<InfoCard type="orders" />
			</Mobile>
		</Fragment>
	);
};
