import React, { FC, useEffect, useState } from 'react';
import styles from './InputRegions.module.scss';
import { InputSelect, TSelectField } from '../InputSelect';
import { useDispatch } from 'react-redux';
import { getListAddress } from 'services/utils/js/getListAddress';
import { getAddresses } from 'services/api/getAddresses';
import { TResultBlock } from 'services/store/interfaces';
import { setAddressEmpty, setCityEmpty } from 'services/store/address';
import { useComponentDidMount } from 'services/hooks/useComponentDidMount';

interface IInputRegionsProps {
	RegionId?: TSelectField[];
	DistrictId?: TSelectField[];
	onChange?: (value: TSelectField[]) => void;
	onResultBlock?: (value: TResultBlock) => void;
	onSetHidden?: (value: boolean | string) => void;
	isEmpty?: boolean;
	text: string;
	prop?: string;
}
export const InputRegions: FC<IInputRegionsProps> = ({
	DistrictId,
	RegionId,
	onSetHidden,
	onChange,
	onResultBlock,
	prop,
	text,
}) => {
	const [regions, setRegions] = useState<TSelectField[]>(RegionId || []);
	const [message, setMessage] = useState('');
	const isComponentMounted = useComponentDidMount();
	const dispatch = useDispatch();

	useEffect(() => {
		const getRegions = async () => await getAddresses({ RegionId: [], DistrictId: [] });

		getRegions()
			.then((res) => {
				setRegions(getListAddress(res));
			})
			.catch(() => setMessage('Идет загрузка данных...'));
	}, []);

	useEffect(() => {
		if (isComponentMounted) dispatch(setAddressEmpty());
	}, [JSON.stringify(RegionId)]);

	useEffect(() => {
		if (isComponentMounted) dispatch(setCityEmpty());
	}, [JSON.stringify(DistrictId)]);

	return (
		<div className={styles.search}>
			<InputSelect
				title={prop}
				initial={{ id: '0', text }}
				options={regions}
				error={message}
				onChange={onChange}
				onResultBlock={onResultBlock}
				onSetHidden={onSetHidden}
				values={RegionId}
				withSearch
			/>
		</div>
	);
};
