import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	IGetOrderBuyPayload,
	IOrderAction,
	IOrdersListState,
	TCancelOrderPayload,
	TCheckX,
	TConfirmOrderPayload,
	TFilterOrders,
	TMergeOrderBuy,
	TOrderBuyGroupIsRevisedPayload,
	TOrderId,
	TResponse,
	TUpdateOrderBuyGroupPayload,
	TUpdateOrderBuyMultiPayload,
} from './interfaces';
import { getOrderBuyGroup } from '../api/getOrderBuyGroup';
import { getOrderBuy } from '../api/getOrderBuy';
import { updateOrderBuyGroup } from '../api/updateOrderBuyGroup';
import { requestOrderBuyGroupIsRevised } from '../api/requestOrderBuyGroupIsRevised';
import { cancelOrderBuyGroup } from '../api/cancelOrderBuyGroup';
import { confirmOrderBuyGroup } from '../api/confirmOrderBuyGroup';
import { mergeOrderBuy } from '../api/mergeOrderBuy';
import { getOrderBuyGroupCheckx } from '../api/getOrderBuyGroupCheckx';
import { updateOrderBuyMulti } from '../api/updateOrderBuyMulti';
import { AppDispatch, AppState } from './index';
import { setEmptyQuantityList } from './multiorders';
import { setQuantityDivideEmpty } from './divideOrders';
import { fetchGetNotifications } from './notifications';
import { defaultListMessages } from './filter';
import { checkNextOrderDate } from '../api/checkNextOrderDate';
import { TCheckNextOrderDatePayload } from './interfaces/checkNextOrder';

const initialState: IOrdersListState = {
	ordersList: [],
	quantityList: [],
	message: '',
	messageOrderGroup: '',
	messageOrder: [{ type: '', text: '', links: '' }],
	cancelOrder: [],
	confirmOrder: [],
	savedOrder: false,
	revisedOrder: {},
	countOrderGoods: 0,
	activeOrders: [],
	current: '0',
	currentInfo: {},
	currentGoods: [],
	isLoading: true,
	activeFullOrders: [],
	isMergedOrders: false,
	isAttention: false,
	count: 0,
};

export const checkX: TCheckX = {
	10: 'На сайте',
	50: 'Подтвержден',
	90: 'Выполнен',
};

export const defaultErrorText = 'Извините, возникла ошибка. Попробуйте позже...';

export const fetchManyUpdateOrderBuy = createAsyncThunk(
	'multiOrders/fetchUpdateOrderBuy',
	async (data: TUpdateOrderBuyMultiPayload) => {
		return await updateOrderBuyMulti(data);
	}
);

export const fetchSaveUpdateOrderBuy = createAsyncThunk(
	'multiOrders/fetchSaveUpdateOrderBuy',
	async (data: TUpdateOrderBuyMultiPayload) => {
		return await updateOrderBuyMulti(data);
	}
);

export const fetchUpdateWithConfirmOrder =
	(data: TUpdateOrderBuyMultiPayload, current: string) => async (dispatch: AppDispatch) => {
		const res = await dispatch(fetchManyUpdateOrderBuy(data));
		const isSuccess = res.payload.length > 0 && res.payload[0].Result !== '99' && !res.payload[0].ErrorDescription;

		if (isSuccess) dispatch(fetchConfirmOrder({ OrderBuyGroupId: [current], ConfirmType: '1' }));
	};

export const fetchUpdateGoodsAfterSave = createAsyncThunk('orders/fetchUpdateGoodsAfterSave', async (_, ThunkAPI) => {
	const { orders } = ThunkAPI.getState() as unknown as AppState;

	getOrderBuy({ OrderBuyGroupId: [orders.current] })
		.then((res) => ThunkAPI.dispatch(setGoodsList(res)))
		.then(() => ThunkAPI.dispatch(setEmptySavedOrder()));
});
export const fetchMergeOrderBuy = createAsyncThunk('orders/fetchMergeOrderBuy', async (data: TMergeOrderBuy) => {
	return mergeOrderBuy(data);
});
export const fetchOrderBuyGroup = createAsyncThunk('orders/fetchOrderBuyGroup', async (data: TFilterOrders) => {
	return await getOrderBuyGroup(data);
});

export const fetchCheckNextOrderDate = createAsyncThunk(
	'orders/fetchCheckNextOrderDate',
	async (data: TCheckNextOrderDatePayload) => {
		return await checkNextOrderDate(data);
	}
);

export const fetchOrderBuyGroupWithMessages = (data: TFilterOrders) => async (dispatch: AppDispatch) => {
	const res = await dispatch(fetchOrderBuyGroup(data));

	const table = res?.payload?.table || [];
	const table2 = res?.payload?.table2 || [];

	if (table.length !== 0 && !table[0]?.Error) {
		await dispatch(fetchCheckNextOrderDate({ NextOrderDate: table2 }));
		await dispatch(fetchGetNotifications(defaultListMessages));
	}
};

export const fetchUpdateOrderBuyGroup = createAsyncThunk(
	'orders/fetchUpdateOrderBuyGroup',
	async (data: TUpdateOrderBuyGroupPayload) => {
		return await updateOrderBuyGroup(data);
	}
);

export const fetchOrderBuyGroupIsRevised = createAsyncThunk(
	'orders/fetchOrderBuyGroupIsRevised',
	async (data: TOrderBuyGroupIsRevisedPayload) => {
		return await requestOrderBuyGroupIsRevised(data);
	}
);

export const fetchOrderBuy = createAsyncThunk('orders/fetchOrderBuy', async (data: IGetOrderBuyPayload) => {
	return await getOrderBuy(data);
});

export const fetchOrderBuyCheckx = createAsyncThunk('orders/fetchOrderBuyCheckx', async (data: TOrderId) => {
	return await getOrderBuyGroupCheckx(data);
});

export const fetchCancelOrder = createAsyncThunk('orders/fetchCancelOrder', async (data: TCancelOrderPayload) => {
	return await cancelOrderBuyGroup(data);
});

export const fetchConfirmOrder = createAsyncThunk('orders/fetchConfirmOrder', async (data: TConfirmOrderPayload) => {
	return await confirmOrderBuyGroup(data);
});

const orders = createSlice({
	name: 'orders',
	initialState,
	reducers: {
		setIsAttention: (state) => {
			state.isAttention = true;
		},
		setIsNotAttention: (state) => {
			state.isAttention = false;
		},
		setOrderMessage: (state: IOrdersListState, action) => {
			const messages = state.messageOrder.filter((message) => message.type !== action.payload);
			state.messageOrder = action.payload === '' ? [] : messages;
		},
		setOrderExportMessage: (state: IOrdersListState, action) => {
			state.messageOrder = [action.payload];
		},
		setEmptyOrders: (state: IOrdersListState) => {
			state.ordersList = [];
		},
		setOrders: (state: IOrdersListState, action) => {
			state.ordersList = action.payload;
		},
		addOrder: (state: IOrdersListState, action: IOrderAction) => {
			const activeIds = state.activeOrders;
			const orderId = String(action.payload.id);

			if (orderId && !activeIds.includes(orderId)) state.activeOrders = [...activeIds, orderId];
		},
		removeOrder: (state: IOrdersListState, action) => {
			state.activeOrders = state.activeOrders.filter((id) => id !== action.payload.id);
			state.activeFullOrders = state.activeFullOrders.filter(
				({ OrderBuyGroupId }) => OrderBuyGroupId !== action.payload.id
			);
		},
		setCurrent: (state: IOrdersListState, action: IOrderAction) => {
			state.currentInfo = {};

			if (action.payload) state.current = String(action.payload.id);
		},
		disabledLoading: (state: IOrdersListState) => {
			state.isLoading = false;
		},
		removeCurrentGoods: (state: IOrdersListState) => {
			state.currentGoods = [];
		},
		setAllActive: (state) => {
			const newSet = new Set([
				...state.activeOrders,
				...state.ordersList.map(({ OrderBuyGroupId }) => String(OrderBuyGroupId)),
			]);
			state.activeOrders = Array.from(newSet);
		},
		removeAllActive: (state) => {
			const ordersIds = state.ordersList.map(({ OrderBuyGroupId }) => String(OrderBuyGroupId));

			state.activeOrders = state.activeOrders.filter((id) => !ordersIds.includes(id));
		},
		setCancelOrder: (state) => {
			state.cancelOrder = [];
		},
		setConfirmOrder: (state) => {
			state.confirmOrder = [];
		},
		setReviseOrder: (state) => {
			state.revisedOrder = {};
		},
		setMergedOrders: (state, action) => {
			state.isMergedOrders = action.payload;
			state.messageOrder = [{ text: '', type: '', links: '' }];
		},
		setQuantityEmpty: (state) => {
			state.quantityList = [];
		},
		setQuantityOrder: (state, action) => {
			const data = action.payload;
			state.quantityList = [...state.quantityList, data];

			const filterList = state.quantityList.filter((order) => order.OrderBuyId !== data.OrderBuyId);
			state.quantityList = [...filterList, data];
		},
		setGoodsList: (state, action) => {
			state.currentGoods = action.payload;
		},
		setEmptySavedOrder: (state) => {
			state.savedOrder = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchOrderBuyCheckx.fulfilled, (state, action) => {
				const data = action.payload;

				if (data[0].OrderBuyGroupId) state.activeFullOrders = [...state.activeFullOrders, data[0]];
			})
			.addCase(fetchOrderBuyGroup.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchOrderBuyGroup.fulfilled, (state, action) => {
				const data = action.payload;

				state.isLoading = false;
				state.count = 0;

				if (!data.table) {
					const result = data as unknown as TResponse[];
					state.message = result[0].ResultDescription || 'Проверьте правильность введенных данных ...';
					return;
				}

				const result = data.table;

				if (result.length === 0) {
					state.message = 'У вас нет заказов ...';
					return;
				}

				if (result[0].Result === '99' || result[0].Error) {
					state.message = result[0].ResultDescription || 'Проверьте правильность введенных данных ...';

					return;
				}

				state.message = '';
				state.cancelOrder = [];
				state.count = Number(data.table[0].Amount);
				state.ordersList = data.table;
			})
			.addCase(fetchOrderBuyGroup.rejected, (state) => {
				state.isLoading = false;
				state.count = 0;
				state.message = 'У вас нет заказов ...';
			})
			.addCase(setCurrent, () => {
				setQuantityEmpty();
				setEmptyQuantityList();
				setQuantityDivideEmpty();
			})
			.addCase(fetchOrderBuy.pending, (state) => {
				state.isLoading = true;
				state.countOrderGoods = 0;
			})
			.addCase(fetchOrderBuy.fulfilled, (state, action) => {
				const data = action.payload;
				state.isLoading = false;

				if (data[0].ErrorDescription) {
					state.countOrderGoods = 0;
					state.messageOrderGroup = 'Проверьте правильность введенных данных ...';
					return;
				}

				if (data[0].Result === '99') {
					state.currentInfo = {};
					state.countOrderGoods = 0;
					state.messageOrderGroup = data[0].ResultDescription || 'Проверьте правильность введенных данных ...';
					return;
				}

				state.countOrderGoods = Number(data[0].Amount);
				state.messageOrderGroup = '';
				state.currentGoods = data;
				state.currentInfo = data[0];
			})
			.addCase(fetchOrderBuy.rejected, (state) => {
				state.isLoading = false;
				state.messageOrderGroup = 'Проверьте правильность введенных данных ...';
			})
			.addCase(fetchUpdateOrderBuyGroup.pending, () => {})
			.addCase(fetchUpdateOrderBuyGroup.fulfilled, (state, action) => {
				const res = action.payload[0];
				if (!res) return;

				if (res.OrderBuyGroupId) state.revisedOrder = { [res.OrderBuyGroupId]: true };
			})
			.addCase(fetchUpdateOrderBuyGroup.rejected, (state) => {
				state.messageOrder = [{ type: 'error', text: defaultErrorText }];
			})
			.addCase(fetchOrderBuyGroupIsRevised.pending, () => {})
			.addCase(fetchOrderBuyGroupIsRevised.fulfilled, (state, action) => {
				const data = action.payload;
				const { OrderBuyGroupId, Error } = data[0];

				if (OrderBuyGroupId) state.revisedOrder = { [String(OrderBuyGroupId)]: true };
				if (Error) state.messageOrder = [{ type: 'error', text: defaultErrorText }];
			})
			.addCase(fetchOrderBuyGroupIsRevised.rejected, () => {})
			.addCase(fetchCancelOrder.pending, (state) => {
				state.messageOrder = [];
			})
			.addCase(fetchCancelOrder.fulfilled, (state, action) => {
				const data = action.payload;

				const messages = data.map(({ Result, ResultDescription, OrderBuyGroupId }) => {
					if (Result === 'Ok') {
						const cancelOrders = OrderBuyGroupId.split(',');
						state.cancelOrder = cancelOrders;
						state.activeOrders = state.activeOrders.filter((id) => {
							!cancelOrders.includes(id);
						});

						return { type: 'success', text: ResultDescription };
					}

					if (Result === 'Error') {
						return { type: 'error', text: ResultDescription };
					}
				});

				state.messageOrder = messages as Array<Record<string, string>>;
			})
			.addCase(fetchCancelOrder.rejected, (state) => {
				state.messageOrder = [{ type: 'error', text: defaultErrorText }];
			})
			.addCase(fetchConfirmOrder.pending, () => {})
			.addCase(fetchConfirmOrder.fulfilled, (state, action) => {
				const data = action.payload;

				const messages = data.map(({ Result, ResultDescription, OrderBuyGroupId }) => {
					if (Result === 'Ok') {
						state.confirmOrder = OrderBuyGroupId.split(',');

						return { type: 'success', text: ResultDescription };
					}

					if (Result === 'Error') {
						return { type: 'error', text: ResultDescription };
					}
				});

				state.messageOrder = messages as Array<Record<string, string>>;
			})
			.addCase(fetchConfirmOrder.rejected, (state) => {
				state.messageOrder = [{ type: 'error', text: defaultErrorText }];
			})
			.addCase(fetchMergeOrderBuy.pending, () => {})
			.addCase(fetchMergeOrderBuy.fulfilled, (state, action) => {
				const data = action.payload;
				const { ResultDescription, ErrorDescription, OrderBuyGroupId, Result } = data[0];

				if (Result !== '99') {
					state.messageOrder = [{ type: 'success', text: ResultDescription || '', links: `${OrderBuyGroupId} ` }];
					state.isMergedOrders = true;
				}

				if (ErrorDescription || Result === '99')
					state.messageOrder = [{ type: 'error', text: ResultDescription || ErrorDescription || defaultErrorText }];
			})
			.addCase(fetchMergeOrderBuy.rejected, (state) => {
				state.messageOrder = [{ type: 'error', text: defaultErrorText }];
			})
			.addCase(fetchManyUpdateOrderBuy.pending, () => {})
			.addCase(fetchManyUpdateOrderBuy.fulfilled, (state, action) => {
				const data = action.payload;
				const isErrorFull = data.length > 0 && data[0].ErrorDescription;
				const isErrorOrder = data.length > 0 && data[0].Result && data[0].Result === '99';

				const isError = isErrorFull || isErrorOrder;

				if (isError) {
					state.messageOrder = [{ text: data[0].ResultDescription || 'Проверьте правильность данных', type: 'error' }];
				}
			})
			.addCase(fetchManyUpdateOrderBuy.rejected, (state) => {
				state.messageOrder = [{ type: 'error', text: defaultErrorText }];
			})
			.addCase(fetchSaveUpdateOrderBuy.pending, () => {})
			.addCase(fetchSaveUpdateOrderBuy.fulfilled, (state, action) => {
				const data = action.payload;
				const isErrorFull = data.length > 0 && data[0].ErrorDescription;
				const isErrorOrder = data.length > 0 && data[0].Result && data[0].Result === '99';

				const isError = isErrorFull || isErrorOrder;

				if (isError) {
					state.messageOrder = [{ text: data[0].ResultDescription || 'Проверьте правильность данных', type: 'error' }];
				}

				if (!isError) {
					state.savedOrder = true;
					state.messageOrder = [{ text: 'Все изменения успешно сохранены...', type: 'success' }];
					state.quantityList = [];
				}
			})
			.addCase(fetchSaveUpdateOrderBuy.rejected, (state) => {
				state.messageOrder = [{ type: 'error', text: defaultErrorText }];
			})
			.addCase(fetchCheckNextOrderDate.pending, () => {})
			.addCase(fetchCheckNextOrderDate.fulfilled, () => {})
			.addCase(fetchCheckNextOrderDate.rejected, () => {});
	},
});

export const {
	addOrder,
	setEmptyOrders,
	removeOrder,
	setCurrent,
	removeCurrentGoods,
	setOrderMessage,
	setAllActive,
	removeAllActive,
	setCancelOrder,
	setConfirmOrder,
	setReviseOrder,
	setMergedOrders,
	setQuantityOrder,
	setQuantityEmpty,
	setOrders,
	setOrderExportMessage,
	setGoodsList,
	setEmptySavedOrder,
	setIsAttention,
	setIsNotAttention,
} = orders.actions;
export default orders.reducer;
