import React from 'react';
import styles from './adminHome.module.scss';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { EmptySupport } from './EmptySupport';
import { RequestList } from './RequestList';

export const AdminHome = () => {
	const supportList = useSelector((state: IStoreState) => state.support.list);
	const isEmptyListSupport = supportList.length === 0;
	const isNotEmptySupport = !isEmptyListSupport;

	return (
		<div className={styles.adminHome}>
			<h1 className={styles.adminHome__header}>Обращения</h1>

			{isNotEmptySupport && <RequestList />}
			{isEmptyListSupport && <EmptySupport />}
		</div>
	);
};
