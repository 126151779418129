import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { Header } from 'components/Header';
import styles from './auth.module.scss';
import { AuthLinks } from 'components/Personal';
import { useHandlerMetrics } from 'services/hooks/useHandlerMetrics';
import { CookiesModal } from 'components/Cookies';

interface Props {
	children: React.ReactNode | React.ReactElement;
}

export const AuthLayout: FC<Props> = ({ children }) => {
	useHandlerMetrics();

	const jwt = useSelector((state: IStoreState) => state.jwt.value);
	const token = localStorage.token;
	const isAuthorized = jwt || token;

	return (
		<div className={styles.auth}>
			<Header isAuthorized={isAuthorized} />

			{children}

			<CookiesModal />

			<AuthLinks />
		</div>
	);
};
