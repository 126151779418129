import { useEffect } from 'react';
import { IStoreState } from '../store/interfaces';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sendHitPathname, sendUserParams } from '../utils/metrics';
import { useDispatch } from 'react-redux';
import { getDateAgo } from '../utils/helpers/getDateAgo';
import { setSettings } from '../store/cookies';

const removeScripts = () => {
	const head = document.querySelector('head');
	const nodes = Array.from(head?.childNodes as unknown as Array<HTMLScriptElement>);
	const deleteScripts = ['https://mc.yandex.ru/metrika/tag.js'];

	const scripts = nodes.filter((elem) => elem?.tagName === 'SCRIPT');
	scripts.map((elem) => {
		const srcName = elem.src.slice(0, elem.src.indexOf('?') === -1 ? elem.src.length : elem.src.indexOf('?'));

		if (deleteScripts.includes(srcName)) {
			elem.src = '';
		}
	});

	document.cookie = document.cookie
		.split('; ')
		.map((key) => `${key}=; Max-Age=-1;secure';domain=${process.env.REACT_APP_COOKIE_DOMAIN};`)
		.join('');
};

export const useHandlerMetrics = () => {
	const { analytical } = useSelector((state: IStoreState) => state.cookies.settings);
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		const interval = setInterval(() => {
			const params = localStorage.getItem('cookies');
			const isTrust = params ? JSON.parse(params).analytical !== '1' : !params;

			if (isTrust) removeScripts();
		});

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const dateAgo = JSON.stringify(getDateAgo());
		const dateCookies = localStorage.startCookies;
		if (dateAgo === dateCookies) dispatch(setSettings(undefined));

		if (analytical === '1') {
			sendHitPathname(pathname);
			sendUserParams();
		}
	}, [pathname, analytical]);
};
