import React, { FC, PropsWithChildren, useRef, useEffect, useState, CSSProperties } from 'react';
import styles from './modal.module.scss';
import { ReactComponent as CloseSVG } from 'assets/images/modal/close.svg';
import { ReactComponent as SuccessSVG } from 'assets/images/modal/success.svg';
import { ReactComponent as ErrorSVG } from 'assets/images/modal/error.svg';

export interface IModalProps {
	onClose: (type: string) => void;
	type: string;
	inx: number;
}
export const Block: FC<PropsWithChildren<IModalProps>> = ({ children, onClose, type, inx }) => {
	const ref = useRef<HTMLDivElement>(null);
	const [style, setStyle] = useState<CSSProperties>({});
	const isError = type === 'error';

	useEffect(() => {
		if (!ref.current) return;

		setStyle({ top: `${inx === 1 ? 16 : (ref?.current as HTMLDivElement).offsetHeight + 32}px` });
	}, [ref]);

	return (
		<div className={styles.modal} ref={ref} style={style}>
			<div className={styles.body}>
				<div className={styles.body_status}>
					{isError ? <ErrorSVG /> : <SuccessSVG />}

					<div className={styles.body_text}>{isError ? 'Внимание!' : 'Сохранено!'}</div>
				</div>

				<div className={styles.body_content}>{children}</div>

				<div className={styles.close} onClick={() => onClose(type)}>
					<CloseSVG />
				</div>
			</div>
		</div>
	);
};
