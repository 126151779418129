import React from 'react';
import styles from './detailOrder.module.scss';
import { Property } from './Property';
import { TCurrentOrder } from 'services/store/interfaces';
import { is10Correct, isCorrected, isUpdate } from 'services/utils/js/getOrderStatus';
import { Status } from 'components/Common/StatusRow';

interface IDetailOrderProps {
	order: TCurrentOrder;
}

export const DetailOrder = ({ order }: IDetailOrderProps) => {
	const { OrderBuyGroupId, Datex, ShipmentDateLast, WarehouseId, OrderSum, DepartmentAddress } = order;

	const isEdited = isUpdate(order);
	const isCorrect = isCorrected(order) || is10Correct(order);

	return OrderBuyGroupId ? (
		<div className={styles.detail}>
			<Property name="Номер заказа" value={OrderBuyGroupId} />

			<Property
				name="Дата поставки"
				id={OrderBuyGroupId}
				value={Datex}
				dateStart={Datex}
				type={isEdited ? 'update' : isCorrect ? 'correct' : 'other'}
				dateEnd={ShipmentDateLast}
			/>

			<Property name="Статус" value={<Status order={order} type="detail" />} />
			<Property name="Номер склада" value={WarehouseId} />

			<Property name="Адрес склада" value={DepartmentAddress} />
			<Property name="Cумма заказа c НДС (BYN)" value={OrderSum ? `${OrderSum} руб.` : ''} />
		</div>
	) : null;
};

export default DetailOrder;
