import React, { useState } from 'react';
import styles from './hint.module.scss';
import { ReactComponent as HintSVG } from 'assets/images/hint/question.svg';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

interface IHintProps {
	text?: string;
}
export const Hint = ({ text }: IHintProps) => {
	const [visible, setVisible] = useState(false);

	return (
		<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
			<div className={styles.hint}>
				<div className={styles.hint__sign} onClick={() => setVisible(!visible)}>
					<HintSVG />
				</div>
				<div className={classNames(styles.hint__arrow, { [styles.visible]: visible })} />
				<div className={classNames(styles.hint__text, { [styles.visible]: visible })}>{text}</div>
			</div>
		</OutsideClickHandler>
	);
};
