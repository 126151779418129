import React, { useState, memo } from 'react';
import styles from './actionButton.module.scss';
import { ReactComponent as PrintSVG } from 'assets/images/actions/print.svg';
import { ReactComponent as BufferSVG } from 'assets/images/actions/buffer.svg';
import { ReactComponent as ExportSVG } from 'assets/images/actions/export1.svg';
import { ReactComponent as AllGoodsSVG } from 'assets/images/all-goods.svg';
import { ReactComponent as ConfirmSVG } from 'assets/images/actions/confirm.svg';
import { ReactComponent as EditSVG } from 'assets/images/actions/edit.svg';
import { ReactComponent as CancelSVG } from 'assets/images/actions/cancel.svg';
import { ReactComponent as MergeSVG } from 'assets/images/actions/merge.svg';
import { ReactComponent as SeparateSVG } from 'assets/images/actions/separate.svg';
import { ReactComponent as SaveSVG } from 'assets/images/actions/save.svg';
import { ReactComponent as DivideSVG } from 'assets/images/actions/divide.svg';
import { ReactComponent as ClearSVG } from 'assets/images/filter/trash.svg';
import { ReactComponent as ClearMobSVG } from 'assets/images/filter/clean-mob.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';

interface IActionButtonProps {
	text?: string;
	type?:
		| 'export'
		| 'clean'
		| 'print'
		| 'buffer'
		| 'all-goods'
		| 'cancel'
		| 'edit'
		| 'confirm'
		| 'merge'
		| 'separate'
		| 'save'
		| 'divide';
	onClick?: () => void;
	mix?: object | string;
	disabled?: boolean;
	hided?: boolean;
	mobile?: boolean;
}

export const ActionButton = memo(
	({ text, type, onClick = () => {}, mix, disabled, hided, mobile }: IActionButtonProps) => {
		const [isActive, setIsActive] = useState(false);

		const handleClick = () => {
			setIsActive(true);
			onClick();
		};

		return (
			<OutsideClickHandler onOutsideClick={() => setIsActive(false)}>
				<div
					className={classNames(styles.action, mix, {
						[styles.action_active]: isActive,
						[styles.action_disabled]: disabled,
						[styles.action_hided]: hided,
					})}
					onClick={disabled || hided ? () => {} : handleClick}
				>
					{type === 'print' && <PrintSVG />}
					{type === 'buffer' && <BufferSVG />}
					{type === 'export' && <ExportSVG />}
					{type === 'all-goods' && <AllGoodsSVG />}
					{type === 'confirm' && <ConfirmSVG />}
					{type === 'edit' && <EditSVG />}
					{type === 'cancel' && <CancelSVG />}
					{type === 'merge' && <MergeSVG />}
					{type === 'separate' && <SeparateSVG />}
					{type === 'save' && <SaveSVG />}
					{type === 'divide' && <DivideSVG />}
					{type === 'clean' && (mobile ? <ClearMobSVG /> : <ClearSVG />)}

					{text && (
						<span
							className={classNames(styles.text, {
								[styles.text_disabled]: disabled,
								[styles.text_mobile]: type === 'clean' && mobile,
							})}
						>
							{text}
						</span>
					)}
				</div>
			</OutsideClickHandler>
		);
	}
);
