import React, { memo } from 'react';
import styles from './groupTitle.module.scss';
import { ReactComponent as HelpSVG } from 'assets/images/grouping/help.svg';

export const GroupTitle = memo(() => (
	<div className={styles.box__left}>
		<HelpSVG />

		<div className={styles.box__left_text}>Группировка по столбцам</div>
	</div>
));
