import React, { Fragment, useEffect, useState } from 'react';
import styles from './counter.module.scss';
import { Input } from 'components/Common/Input';
import classNames from 'classnames';
import { getNumberWithStep } from 'services/utils/js/getNumberWithStep';

interface ICounterProps {
	step: string;
	start: string;
	end?: string;
	onChange: (value: string) => void;
	isEdit?: boolean;
	isMobile?: boolean;
	mix?: object;
}
export const Counter = ({ step, end, start, onChange, isEdit, isMobile, mix }: ICounterProps) => {
	const unit = Number(step);
	const [value, setValue] = useState(Number(isEdit ? start : end));
	const [endValue, setEndValue] = useState(Number(end) - Number(start));

	const incHandler = (num: number) => {
		const isMore = num > unit || num === unit;
		setValue(isMore ? num + unit : unit);
		setEndValue(isMore ? Number(end) - num - unit : Number(end));
	};
	const decHandler = (num: number) => {
		const isMore = num > unit || num === unit;
		setValue(isMore ? num - unit : 0);
		setEndValue(Number(end) - num + unit);
	};

	const changeHandler = (value: string) => {
		onChange(value);
		setValue(Number(value));
	};

	const changeEditHandler = (value: string) => {
		onChange(value === '' ? '' : getNumberWithStep(Number(value), Number(step), Number(end)));
	};

	useEffect(() => {
		if (isEdit) return;

		onChange(String(value));
		setEndValue(Number(end) - value);
	}, [value]);

	return (
		<Fragment>
			{isMobile && (
				<div className={styles.counter}>
					<div
						className={classNames(styles.counter__decrease, {
							[styles.counter__disabled]: value - unit < 0,
							[styles.counter__mobile]: isMobile,
						})}
						onClick={() => (value - unit === 0 || value - unit > 0 ? decHandler(value) : {})}
					>
						-
					</div>
				</div>
			)}
			{!isEdit && (
				<Fragment>
					<div className={styles.counter__endValue}>{endValue}</div>
					<div className={styles.counter__arrow}>➝</div>
				</Fragment>
			)}

			<Input
				name="quantity"
				min={0}
				max={Number(end)}
				onChange={isEdit ? changeEditHandler : changeHandler}
				mix={mix}
				value={String(value)}
				step={unit}
				isDivide={true}
				focused={true}
			/>

			{!isMobile && (
				<div className={styles.counter}>
					<div
						className={classNames(styles.counter__increase, {
							[styles.counter__disabled]: value + unit > Number(end),
						})}
						onClick={() => (value + unit < Number(end) + 1 ? incHandler(value) : {})}
					>
						+
					</div>
					<div
						className={classNames(styles.counter__decrease, {
							[styles.counter__disabled]: value - unit < 0,
						})}
						onClick={() => (value - unit === 0 || value - unit > 0 ? decHandler(value) : {})}
					>
						-
					</div>
				</div>
			)}

			{isMobile && (
				<div className={styles.counter}>
					<div
						className={classNames(styles.counter__increase, {
							[styles.counter__disabled]: value + unit > Number(end),
							[styles.counter__mobile]: isMobile,
						})}
						onClick={() => (value + unit < Number(end) + 1 ? incHandler(value) : {})}
					>
						+
					</div>
				</div>
			)}
		</Fragment>
	);
};
