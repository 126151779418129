import React, { Fragment, memo } from 'react';
import { InfoTable } from 'components/InfoTable';
import { InfoCard } from 'components/InfoCard';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { addGood, removeGood } from 'services/store/goods';
import { Desktop } from 'components/HOCS/Desktop';
import { Mobile } from 'components/HOCS/Mobile';
import { FilterList } from 'components/Filter/FilterList';
import { SettingList } from 'components/Settings/SettingList';
import { Message } from 'components/Common/Message';
import styles from '../../Orders/OrdersGroup/OrdersTable/ordersTable.module.scss';

export const GoodsTable = memo(() => {
	const count = useSelector((state: IStoreState) => state.goods.count);
	const message = useSelector((state: IStoreState) => state.goods.message);
	const goods = useSelector((state: IStoreState) => state.goods.goodsList);
	const isShowFilter = useSelector((state: IStoreState) => state.filter.isShowGoods);
	const isShowSetting = useSelector((state: IStoreState) => state.setting.isShowGoods);

	const isActions = isShowFilter || isShowSetting;
	const isShowGoods = !isActions && count > 0;
	const isShowMessage = !isActions && count === 0;

	const dispatcher = useDispatch();

	const handleGood = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatcher(addGood({ id })) : dispatcher(removeGood({ id })));
	};

	return (
		<Fragment>
			<Desktop>
				{goods.length > 0 ? (
					<div className={styles.scrollBar}>
						<InfoTable type="goods" onChange={handleGood} />
					</div>
				) : (
					<Message text={message} />
				)}
			</Desktop>

			<Mobile>
				{isShowFilter && <FilterList type="goods" />}
				{isShowSetting && <SettingList type="goods" />}
				{isShowMessage && <Message text={message} />}
				{isShowGoods && <InfoCard type="goods" />}
			</Mobile>
		</Fragment>
	);
});
