import React from 'react';
import styles from './linksAuth.module.scss';
import { LINKS } from '../links';
import { Link } from 'react-router-dom';

export const AuthLinks = () => {
	return (
		<div className={styles.links}>
			<div className={styles.links_group}>
				{LINKS.map(({ id, text, link }) => {
					const isExternal = link.includes('http');

					return (
						<div key={id} className={styles.links_item}>
							{isExternal ? (
								<a target="_blank" href={link} rel="noreferrer">
									{text}
								</a>
							) : (
								<Link to={link}>{text}</Link>
							)}
						</div>
					);
				})}
			</div>

			<div className={styles.links_copyright}>© {new Date().getFullYear()} ООО «Евроторг»</div>
		</div>
	);
};
