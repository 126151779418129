import React, { useEffect, useState } from 'react';
import { InputSelect, TSelectField } from '../InputSelect';
import { useSelector } from 'react-redux';
import { getListAddress } from 'services/utils/js/getListAddress';
import { getAddresses } from 'services/api/getAddresses';
import { IStoreState, TResultBlock } from 'services/store/interfaces';

interface IInputDistrictProps {
	onChange?: (value: TSelectField[]) => void;
	onResultBlock: (value: TResultBlock) => void;
	id: string[];
}

export const InputDistricts = ({ id, onChange, onResultBlock }: IInputDistrictProps) => {
	const { DistrictId } = useSelector((state: IStoreState) => state.address);
	const [districts, setDistricts] = useState<TSelectField[]>(DistrictId || []);
	const [message, setMessage] = useState('');

	useEffect(() => {
		const getCities = async () => await getAddresses({ RegionId: id, DistrictId: [] });
		getCities()
			.then((res) => setDistricts(getListAddress(res)))
			.catch(() => setMessage('Идет загрузка данных...'));
	}, [JSON.stringify(id)]);

	return (
		<InputSelect
			title="DistrictId"
			options={districts}
			error={message}
			onChange={onChange}
			withSearch={true}
			initial={{ id: '0', text: 'Выберите район(ы)' }}
			values={DistrictId}
			onResultBlock={onResultBlock}
		/>
	);
};
