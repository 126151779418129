import { request } from 'services/utils/request';
import { TFilterGoods, TGetContractorGoodsResponse } from 'services/store/interfaces';

export const getContractorGoods = async (payload: TFilterGoods): Promise<TGetContractorGoodsResponse[]> => {
	return await request.post(
		'SupplierPortal.GetContractorGoods',
		{
			...payload,
		},
		localStorage.token
	);
};
