import React from 'react';
import styles from './doc.module.scss';
import { Link } from 'react-router-dom';

interface IDocProps {
	title: string;
	text?: string;
	img?: string;
	link: string;
}

export const Doc: React.FC<IDocProps> = ({ title, text, img, link }) => {
	return (
		<Link to={link} target="_blank">
			<div className={styles.doc}>
				<div className={styles.doc__content}>
					<h2 className={styles.doc__title}>{title}</h2>
					<p className={styles.doc__text}>{text}</p>
				</div>

				<div className={styles.doc__image}>
					<img src={img} alt="photo" />
				</div>
			</div>
		</Link>
	);
};
