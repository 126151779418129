import { request } from 'services/utils/request';
import { TFilterEvents, TGetCalendarEventsResponse } from 'services/store/interfaces';

export const getCalendarEvents = async (payload: TFilterEvents): Promise<TGetCalendarEventsResponse[]> => {
	return await request.post(
		'SupplierPortal.GetCalendarEvents',
		{
			...payload,
		},
		localStorage.token
	);
};
