import React, { FC, PropsWithChildren } from 'react';
import styles from './filterButton.module.scss';
import { DropButton } from 'components/Common/DropButton';
import { ReactComponent as MobileSVG } from 'assets/images/filter/filterMobile.svg';
import { ReactComponent as EventsSVG } from 'assets/images/setting/settingDesktop.svg';

interface IFilterButtonProps {
	isMobile?: boolean;
	title?: string;
	onClick?: () => void;
	isEvents?: boolean;
}

export const FilterButton: FC<PropsWithChildren<IFilterButtonProps>> = ({
	isMobile,
	title,
	onClick = () => {},
	isEvents,
}) => {
	const icon = isEvents ? <EventsSVG /> : isMobile ? <MobileSVG /> : null;

	return (
		<DropButton
			icon={icon}
			mix={isEvents ? styles.events : styles.button}
			text={isEvents ? '' : title}
			onClick={onClick}
		/>
	);
};
