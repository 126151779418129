import React from 'react';

interface IArrowProps {
	width?: number;
	height?: number;
	color?: string;
}
export const Arrow = ({ width = 10, height = 6, color = '#8DC640' }: IArrowProps) => {
	return (
		<svg width={width} height={height} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.223393 0.232592C0.521251 -0.0775306 1.00417 -0.0775306 1.30203 0.232592L5 4.08283L8.69797 0.232592C8.99583 -0.0775306 9.47875 -0.0775306 9.77661 0.232592C10.0745 0.542714 10.0745 1.04552 9.77661 1.35564L5.53932 5.76741C5.24146 6.07753 4.75854 6.07753 4.46068 5.76741L0.223393 1.35564C-0.0744644 1.04552 -0.0744644 0.542714 0.223393 0.232592Z"
				fill={color}
			/>
		</svg>
	);
};
