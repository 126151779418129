import React from 'react';
import { IStoreState } from 'services/store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { addAllGoodsItem, removeAllGoodsItem } from 'services/store/reports';
import styles from 'components/Content/Orders/OrdersGroup/OrdersTable/ordersTable.module.scss';
import { InfoTable } from 'components/InfoTable';
import { Desktop } from 'components/HOCS/Desktop';
import { Pagination } from 'components/Common/Pagination';
import { fill } from 'services/utils/js/fill';
import { NoResult } from 'components/Common/NoResult';

export const AllGoodsScreen = () => {
	const { listReportsAllGoods } = useSelector((state: IStoreState) => state.filter);
	const { message, listAllGoods, countAllGoods } = useSelector((state: IStoreState) => state.reports);

	const pages = fill(Math.ceil(countAllGoods / (listReportsAllGoods.Limit || 30)));
	const dispatch = useDispatch();

	const handleGood = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addAllGoodsItem({ id })) : dispatch(removeAllGoodsItem({ id })));
	};

	return (
		<div>
			{message !== '' ? (
				<div className={styles.message}>{message}</div>
			) : countAllGoods === 0 ? (
				<NoResult />
			) : (
				<Desktop>
					<div className={styles.scrollBar}>
						<InfoTable type="allGoods" onChange={handleGood} />
					</div>
				</Desktop>
			)}

			{listAllGoods.length > 0 && <Pagination pages={pages} items={countAllGoods} type="allGoods" />}
		</div>
	);
};
