import React from 'react';
import styles from './propertyBlock.module.scss';
import classNames from 'classnames';
import { UpdateDate } from '../../UpdateDate';
import { ReactComponent as Edit } from 'assets/images/actions/edit-order.svg';

interface IPropertyBlockProps {
	name?: string;
	value?: string | React.ReactNode;
	isUpdate?: boolean;
	isCorrect?: boolean;
	mix?: object;
	id?: string;
	dateEnd?: string;
	dateStart?: string;
	isQuantity?: boolean;
}

export const PropertyBlock = ({
	name,
	value,
	isUpdate,
	mix,
	dateEnd,
	dateStart,
	isCorrect,
	isQuantity,
	id,
}: IPropertyBlockProps) => {
	const isNotChange = !isUpdate && !isCorrect;

	return (
		<div className={classNames(styles.property, { [styles.property__quantity]: isQuantity })}>
			<div className={styles.name}>{name}</div>

			{isNotChange && (
				<div className={classNames(styles.value, mix)}>
					{value} {isQuantity && <Edit />}
				</div>
			)}

			{isUpdate && <UpdateDate type="update" dateEnd={String(dateEnd)} dateStart={String(dateStart)} id={String(id)} />}
			{isCorrect && (
				<UpdateDate type="correct" dateEnd={String(dateEnd)} dateStart={String(dateStart)} id={String(id)} />
			)}
		</div>
	);
};
