import { request } from 'services/utils/request';
import { TAddressesPayload, TGetAddressesResponse } from 'services/store/interfaces';

export const getAddresses = async (payload: TAddressesPayload): Promise<TGetAddressesResponse[]> => {
	return await request.post(
		'SupplierPortal.GetAddresses',
		{
			...payload,
		},
		localStorage.token
	);
};
