import React, { FC } from 'react';
import styles from './day.module.scss';
import classNames from 'classnames';
import { TMonth, TNumDay, TNumRequired } from 'services/store/interfaces/downTimes';
import { ReactComponent as EyeSVG } from 'assets/images/downTimes/eye.svg';
import { ReactComponent as EditSVG } from 'assets/images/downTimes/cardEdit.svg';
import { Plus } from 'assets/images/downTimes/Plus';
import { useDispatch } from 'react-redux';
import { setCurrentGroup } from 'services/store/downTimes';
import { useSizesDayCalendar } from 'services/hooks/useHeightDayCalendar';
import { getIsFutureDown } from 'services/utils/helpers/dateDownTimes';
import { getIsFuture, getIsToday, isAvaibleDayDate } from 'services/utils/helpers/date';

type TDayProps = {
	month: TMonth;
	day: TNumDay | TNumRequired;
	isEdit: boolean;
};

export const Day: FC<TDayProps> = ({ month, day, isEdit }) => {
	const size = useSizesDayCalendar();

	const isToday = getIsToday(day);
	const isFuture = getIsFuture(day);
	const isAnother = day ? month.id !== day.month || (!isToday && !isFuture) : false;
	const isAnotherMonth = day && month.id !== day.month;
	const isDownTime = day && day.DayId;
	const isDownCreate = day && day.Info && day.Info.length < 2 && isAvaibleDayDate(day.Info[0].Datex);

	if (day && day.Info && isDownCreate) {
		isAvaibleDayDate(day.Info[0].Datex);
	}

	const dispatch = useDispatch();

	const onSet = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();

		return (type: 'create' | 'info' | 'edit') => {
			const month = day.month + 1 < 10 ? `0${day.month + 1}` : day.month + 1;
			const DateStart = `${day.year}-${month}-${day.num < 10 ? `0${day.num}` : day.num}`;
			const dayInfo =
				day && day.Info && type === 'edit' ? day.Info[0] : { ...day, DateStart, DateEnd: '', Reason: '', Descript: '' };

			dispatch(setCurrentGroup({ dayInfo, type }));
		};
	};

	return (
		<div
			data-day={day ? day.num : '0'}
			className={classNames(styles.day_num, {
				[styles.day_today]: !isDownTime && isToday && !isAnotherMonth,
				[styles.day_future]: isFuture && !isAnotherMonth && !isDownTime,
				[styles.day_another]: isAnother && !isDownTime,
				[styles.day_month]: isAnotherMonth && !isDownTime,
				[styles.day_downTimes]: isDownTime,
			})}
			style={{ width: Number(size?.width || 0) + 2, height: size?.height }}
		>
			{isFuture && (
				<div className={styles.day_plus} onClick={(e) => onSet(e)('create')}>
					<Plus />
				</div>
			)}

			{isDownTime && (
				<div className={styles.day_actions}>
					<div className={styles.day_show} onClick={(e) => onSet(e)('info')}>
						<EyeSVG />
					</div>

					{isEdit && getIsFutureDown(day.DateEnd || undefined) && (
						<div className={styles.day_edit} onClick={(e) => onSet(e)('edit')}>
							<EditSVG />
						</div>
					)}

					{isDownCreate && (
						<div className={styles.day_add} onClick={(e) => onSet(e)('create')}>
							<Plus />
						</div>
					)}
				</div>
			)}

			<div className={styles.day_date}>{day ? day.num : ''}</div>
		</div>
	);
};
