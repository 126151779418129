import React from 'react';
import styles from './toggle.module.scss';
import classNames from 'classnames';

interface IToggleProps {
	isOn: boolean;
	mix?: string;
	onClick?: () => void;
	id?: string;
}

export const Toggle = ({ isOn, mix, onClick, id }: IToggleProps) => (
	<div
		className={classNames(styles.toggle, mix, {
			[styles.toggle_off]: !isOn,
			[styles.toggle_on]: isOn,
		})}
	>
		<input type="checkbox" role="switch" id={`switch-${id}`} className={styles.toggle__input} onClick={onClick} />

		<span
			className={classNames(styles.button, {
				[styles.button_off]: !isOn,
				[styles.button_on]: isOn,
			})}
		/>
	</div>
);
