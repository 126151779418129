import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TCurrentGood, TSettings } from 'services/store/interfaces';
import { ReactComponent as Edit } from 'assets/images/actions/edit-order.svg';
import styles from './cardGoods.module.scss';
import { Arrow } from 'components/InfoCard/Arrow';
import classNames from 'classnames';
import { QuantityGood } from '../EditMany/QuantityGood';
import { addGood, removeGood, setQuantityOrder } from 'services/store/multiorders';
import { addGoodItem, removeGoodItem, setQuantityDivideRow } from 'services/store/divideOrders';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';

export const CardGoodsMobile = ({ id, type }: { id: string; type: 'multi' | 'divide' }) => {
	const settingsMulti = useSelector((state: IStoreState) => state.setting.listMultiOrder);
	const settingsDivide = useSelector((state: IStoreState) => state.setting.listDivide);

	const multiGoods = useSelector((state: IStoreState) => state.multiOrders.allData);
	const divideGoods = useSelector((state: IStoreState) => state.divideOrders.allData);

	const multiGoodsOrder = multiGoods.filter((good) => good.OrderBuyGroupId === id);
	const divideGoodsOrder = divideGoods.filter((good) => good.OrderBuyGroupId === id);

	const activeMultiGoods = useSelector((state: IStoreState) => state.multiOrders.activeGoods);
	const activeDivideGoods = useSelector((state: IStoreState) => state.divideOrders.orderBuyIdList);

	const goods = {
		multi: multiGoodsOrder,
		divide: divideGoodsOrder,
	};

	const settings = {
		multi: settingsMulti,
		divide: settingsDivide,
	};

	const dispatch = useDispatch();

	const handleActiveDivide = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addGoodItem(id)) : dispatch(removeGoodItem(id)));
	};
	const handleActiveMulti = (isActive: boolean) => {
		return (id: string) => (isActive ? dispatch(addGood(id)) : dispatch(removeGood(id)));
	};

	const handlerActive = {
		multi: handleActiveMulti,
		divide: handleActiveDivide,
	};

	return (
		<div className={styles.goodsCard}>
			{goods[type].map((good, inx) => {
				const isActiveMulti = activeMultiGoods.includes(String(good.OrderBuyId));
				const isActiveDivide = activeDivideGoods.includes(String(good.OrderBuyId));
				const [active, setActive] = useState(false);

				const isActive = {
					multi: isActiveMulti,
					divide: isActiveDivide,
				};

				const [visibleGoodInfo, setVisibleGoods] = useState(false);
				const setting = settings[type].filter((setting: TSettings) => setting.id !== 'GoodsName');

				const handlerMultiQuantity = (OrderBuyId: string, QuantitySupplier: string, OrderId: string) => {
					dispatch(
						setQuantityOrder({ OrderBuyId, QuantitySupplier, OrderId, Info1: 'Изменение количества продукции' })
					);
				};

				const handlerDivideQuantity = (OrderBuyId: string, QuantitySupplier: string, OrderId: string) => {
					dispatch(setQuantityDivideRow({ OrderBuyId, QuantitySupplier, OrderId, Info1: 'Разделение позиции' }));
				};

				const handlerChange = {
					multi: handlerMultiQuantity,
					divide: handlerDivideQuantity,
				};

				return (
					<Fragment key={inx}>
						<div className={styles.goodValue}>
							<div
								className={classNames(styles.goodsTitle, {
									[styles.darkMode]: visibleGoodInfo,
								})}
							>
								<InputCheckbox
									isActive={isActive[type]}
									onChange={(isActive) => handlerActive[type](isActive)(String(good.OrderBuyId))}
								/>

								<div className={classNames(styles.propertyHeader)} onClick={() => setVisibleGoods(!visibleGoodInfo)}>
									{good.GoodsName}
								</div>
							</div>
						</div>

						{visibleGoodInfo && (
							<Fragment>
								<div className={styles.goodValue}>
									{setting.map(({ isShow, id, text }, inx) => {
										const isQuantity = id === 'QuantitySupplier';
										const quantity = String(good.QuantitySupplier);
										const lineId = String(good.OrderBuyId);
										const orderId = String(good.OrderBuyGroupId);
										const limitQuantity = good.LimitQuantity || '100';
										const isControlled = good.ControlWeight !== '1';
										const inPack = good.QuantityInPack || '';
										const isMulti = type === 'multi';

										const value = isQuantity ? (
											<QuantityGood
												orderId={orderId}
												lineId={lineId}
												value={quantity}
												inPack={inPack}
												onChange={handlerChange[type]}
												endQuantity={limitQuantity}
												isControlled={isMulti ? isControlled : true}
												isActive={active}
												isMobile={true}
											/>
										) : (
											good[id as keyof TCurrentGood]
										);

										return isShow ? (
											<div
												key={inx}
												className={classNames(styles.goodLine, {
													[styles.active]: active && isQuantity,
												})}
												onClick={() => (isQuantity ? setActive(true) : setActive(false))}
											>
												<div className={styles.propertyTitle}>
													{text} {isQuantity && <Edit />}
												</div>
												<div className={styles.propertyValue}>{value}</div>
											</div>
										) : null;
									})}
								</div>

								<div
									className={classNames(styles.goodsTitle, {
										[styles.titleFooter]: visibleGoodInfo,
									})}
									onClick={() => setVisibleGoods(false)}
								>
									<span className={styles.goodsHider}>Скрыть</span>
									<span className={classNames(styles.goodsTitle_arrow)}>
										<Arrow />
									</span>
								</div>
							</Fragment>
						)}
					</Fragment>
				);
			})}
		</div>
	);
};
