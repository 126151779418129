import React, { useEffect, useState } from 'react';
import { InputSelect, TSelectField } from '../InputSelect';
import { useSelector } from 'react-redux';
import { getListAddress } from 'services/utils/js/getListAddress';
import { getAddresses } from 'services/api/getAddresses';
import { IStoreState, TResultBlock } from 'services/store/interfaces';

interface IInputCitiesProps {
	onChange?: (value: TSelectField[]) => void;
	onResultBlock: (value: TResultBlock) => void;
	RegionId: string[];
	DistrictId: string[];
}
export const InputCities = ({ RegionId, DistrictId, onChange, onResultBlock }: IInputCitiesProps) => {
	const { CityId } = useSelector((state: IStoreState) => state.address);
	const [cities, setCities] = useState<TSelectField[]>(CityId || []);
	const [message, setMessage] = useState('');

	useEffect(() => {
		const getCities = async () => await getAddresses({ RegionId, DistrictId });
		getCities()
			.then((res) => setCities(getListAddress(res)))
			.catch(() => setMessage('Идет загрузка данных...'));
	}, [DistrictId.length, JSON.stringify(DistrictId)]);

	return (
		<InputSelect
			title="CityId"
			options={cities}
			error={message}
			onChange={onChange}
			withSearch={true}
			values={CityId}
			initial={{ id: '0', text: 'Выберите город(а)' }}
			onResultBlock={onResultBlock}
		/>
	);
};
