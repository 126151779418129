export const groupByIdOrder = (orders: any[]) => {
	const temp = orders.reduce((acc, elem) => {
		const OrderBuyGroupId = elem.OrderBuyGroupId;

		if (!acc[OrderBuyGroupId]) acc[OrderBuyGroupId] = [];
		// ложим текущий элемент в соответствующий массив
		acc[OrderBuyGroupId].push(elem);
		return acc;
	}, {});

	return Object.getOwnPropertyNames(temp).map((k) => temp[k]);
};
