import React from 'react';
import styles from './requestItem.module.scss';
import { ReactComponent as SupportSVG } from 'assets/images/sidebar/support.svg';

interface IRequestItemProps {
	title?: string;
	date?: string;
	onClick?: () => void;
}
export const RequestItem = ({
	title = 'ООО “Наименование Юрлица”',
	date = '21 нояб. 2022, 15:43',
	onClick = () => {},
}: IRequestItemProps) => {
	return (
		<div className={styles.requestItem} onClick={onClick}>
			<div className={styles.requestItem__info}>
				<SupportSVG />

				<span className={styles.requestItem__textBlock}>
					<span className={styles.requestItem__text}>Обращение от </span>
					<span className={styles.requestItem__title}>{title}</span>
				</span>
			</div>

			<div className={styles.requestItem__date}>{date}</div>
		</div>
	);
};
