import React, { FC, ReactElement } from 'react';
import styles from './noResult.module.scss';
import { ReactComponent as EmptySVG } from 'assets/images/planning/empty.svg';

interface INoResultProps {
	text?: string;
	image?: ReactElement<SVGElement>;
}
export const NoResult: FC<INoResultProps> = ({ text = 'Нет результатов по фильтрам...', image = <EmptySVG /> }) => {
	return (
		<div className={styles.noResult}>
			<div className={styles.noResult_image}>{image}</div>

			<div className={styles.noResult_text}>{text}</div>
		</div>
	);
};
