import { TFilterEvents, TFilterGoods, TFilterMessages, TFilterOrders } from 'services/store/interfaces';

type TFilters = TFilterOrders | TFilterGoods | TFilterEvents | TFilterMessages;
export const equalObjectsCount = <T extends TFilters>(obj1: T, obj2: T): number => {
	let count = 0;

	for (const key in obj1) {
		if (JSON.stringify(obj1[key as keyof T]) !== JSON.stringify(obj2[key as keyof T])) {
			count = count + 1;
		}
	}

	return count;
};
