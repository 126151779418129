import React from 'react';
import styles from './checkMessage.module.scss';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import classNames from 'classnames';

export const CheckMessage = ({ text }: { text: string }) => {
	const isOffline = useSelector((state: IStoreState) => state.errors.isOffline);

	return (
		<div
			className={classNames(styles.message, {
				[styles.message__offline]: isOffline,
				[styles.message__onLine]: !isOffline,
			})}
		>
			{text}
		</div>
	);
};
