import React from 'react';
import styles from './right.module.scss';

interface IRightProps {
	children?: React.ReactNode;
}

export const Right = ({ children }: IRightProps) => {
	return <div className={styles.right}>{children}</div>;
};
