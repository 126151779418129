import React, { FC } from 'react';
import styles from './list.module.scss';
import { Link } from 'react-router-dom';
import { REPORTS } from '../data';
import { useDispatch, useSelector } from 'react-redux';
import { hideReportsAllGoods, hideReportsCurrent, hideReportsQuality } from 'services/store/setting';
import {
	defaultListReportsAllGoods,
	defaultListReportsCurrent,
	defaultListReportsPlanning,
	defaultListReportsQuality,
	setFiltersReportsAllGoods,
	setFiltersReportsCurrent,
	setFiltersReportsPlanning,
	setFiltersReportsQuality,
} from 'services/store/filter';
import { TFunc } from 'components/Menu';
import { useFetchContractorIsAllowedDemandPlanQuery } from 'services/store/api/RTKapi/planning.api';
import { IStoreState } from 'services/store/interfaces';

interface IListProps {
	onClose?: () => void;
	isOpen?: boolean;
}
export const List: FC<IListProps> = ({ onClose }) => {
	const dispatch = useDispatch();
	const links =
		localStorage.userIsAllowedDemandPlan === 'yes' ? REPORTS : REPORTS.filter(({ id }) => id !== 'planning');

	const hideActions = () => {
		dispatch(hideReportsAllGoods());
		dispatch(hideReportsCurrent());
		dispatch(hideReportsQuality());
	};

	const mapClick = {
		allGoods: () => {
			dispatch(setFiltersReportsAllGoods({ filters: defaultListReportsAllGoods }));
			hideActions();
		},
		current: () => {
			dispatch(setFiltersReportsCurrent({ filters: defaultListReportsCurrent }));
			hideActions();
		},
		quality: () => {
			dispatch(setFiltersReportsQuality({ filters: defaultListReportsQuality }));
			hideActions();
		},
		planning: () => {
			dispatch(setFiltersReportsPlanning({ filters: defaultListReportsPlanning }));
			hideActions();
		},
	};

	return (
		<div onClick={onClose} className={styles.reportsList}>
			{links.map(({ id, text, link }) => (
				<Link to={link} onClick={mapClick[id as keyof TFunc]} key={id} className={styles.list_link}>
					{text}
				</Link>
			))}
		</div>
	);
};
