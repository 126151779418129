import React from 'react';
import styles from './scrollbar.module.scss';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';

interface IScrollBarProps {
	children: React.ReactNode;
}
export const ScrollBar = ({ children }: IScrollBarProps) => {
	const activeGoods = useSelector((state: IStoreState) => state.goods.activeGoods);
	const activeOrders = useSelector((state: IStoreState) => state.orders.activeOrders);
	const isActiveItems = activeGoods.length === 0 && activeOrders.length === 0;
	const style = isActiveItems ? { minHeight: window.innerHeight + 70 } : { minHeight: window.innerHeight };

	return (
		<div className={styles.scrollbar} style={style}>
			{children}
		</div>
	);
};
