import { useEffect, useState } from 'react';
import { Nullable, TStickyType } from '../store/interfaces';
import { useIsAdminSession } from './useIsAdminSession';

const offsetHeight = 0;

export function useIsSticky(type?: TStickyType) {
	const [isSticky, setIsSticky] = useState<Nullable<boolean>>(null);
	const [scrollHeight, setScrollHeight] = useState<number>(0);
	const [isFast, setIsFast] = useState(true);
	const [isAdminSession] = useIsAdminSession();

	const isReports = ['allGoods', 'current', 'quality'].includes(type || '');
	const isPlanning = type === 'planning';

	const listener = () => {
		const header = document.querySelector('[class^="HeaderWithAuth"]');
		if (!header) return;

		const bounding = header.getBoundingClientRect();
		const scrollHeight = document.documentElement.offsetHeight;

		setIsFast(true);
		setScrollHeight(0);

		setTimeout(() => {
			const addSum = isReports ? 75 : 0;

			setScrollHeight(
				isAdminSession
					? document.documentElement.scrollTop + 15
					: isPlanning
					? 85
					: document.documentElement.scrollTop + 15 - addSum
			);

			setIsFast(false);
		}, 1000);

		let resultHeight = offsetHeight ? scrollHeight - offsetHeight : 0;
		if (resultHeight < 0) resultHeight = resultHeight * -1;

		const addHeightScroll = isPlanning ? 16 : 0;
		const result = window.pageYOffset - resultHeight - addHeightScroll > bounding.height;

		if (result !== isSticky) setIsSticky(result);
	};

	useEffect(() => {
		document.addEventListener('scroll', listener);

		return () => document.removeEventListener('scroll', listener);
	});

	return [isSticky, scrollHeight, isFast];
}
