import React from 'react';

export const Clock = ({ active }: { active: boolean }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.5">
				<path
					d="M14 8C14 11.312 11.312 14 8 14C4.688 14 2 11.312 2 8C2 4.688 4.688 2 8 2C11.312 2 14 4.688 14 8Z"
					stroke={active ? '#ffffff' : '#D0D0DD'}
					strokeWidth="1.3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M10.2258 9.90866L8.36583 8.79866C8.04183 8.60666 7.77783 8.14466 7.77783 7.76666V5.30664"
					stroke={active ? '#ffffff' : '#D0D0DD'}
					strokeWidth="1.3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};
