import React, { Fragment, useEffect, PropsWithChildren, FC } from 'react';
import styles from './events.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { fetchGetCalendarEvents } from 'services/store/events';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { Pagination } from 'components/Common/Pagination';
import { fill } from 'services/utils/js/fill';
import { Message } from 'components/Common/Message';
import { FilterList } from 'components/Filter/FilterList';
import { Filter } from 'components/Filter';
import { defaultListEvents, setFiltersEvents } from 'services/store/filter';
import { Loading } from 'components/Common/Loading';
import { EventsAll } from './EventsAll';
import { fetchGetNotifications } from 'services/store/notifications';

type TContentProps = {
	isShow: boolean;
	text: string;
};

type TEventProps = {
	type: 'events' | 'notifications';
	count: number;
};

const title = {
	events: 'События',
	notifications: 'Уведомления',
};
const Content: FC<PropsWithChildren<TContentProps>> = ({ isShow, children, text }) =>
	isShow ? (
		<Fragment>
			<EventsAll />

			{children}
		</Fragment>
	) : (
		<Message text={text} />
	);

export const Events: FC<TEventProps> = ({ type, count }) => {
	const messageEvents = useSelector((state: IStoreState) => state.events.message);
	const messageNotifications = useSelector((state: IStoreState) => state.notifications.message);

	const limitEvents = useSelector((state: IStoreState) => state.filter.listEvents.Limit);
	const limitNotifications = useSelector((state: IStoreState) => state.filter.listMessages.Limit);

	const isLoadingEvents = useSelector((state: IStoreState) => state.events.isLoading);
	const isLoadingNotifications = useSelector((state: IStoreState) => state.notifications.isLoading);

	const filtersEvents = useSelector((state: IStoreState) => state.filter.listEvents);
	const filtersNotifications = useSelector((state: IStoreState) => state.filter.listMessages);
	const isFiltersEvents = useSelector((state: IStoreState) => state.filter.isShowEvents);

	const filters = {
		events: filtersEvents,
		notifications: filtersNotifications,
	};

	const message = {
		events: messageEvents,
		notifications: messageNotifications,
	};

	const isFilters = {
		events: isFiltersEvents,
		notifications: isFiltersEvents,
	};

	const limit = {
		events: limitEvents,
		notifications: limitNotifications,
	};

	const isLoading = {
		events: isLoadingEvents,
		notifications: isLoadingNotifications,
	};

	const setFilters = {
		events: () => dispatch(setFiltersEvents({ filters: defaultListEvents })),
		notifications: () => dispatch(setFiltersEvents({ filters: defaultListEvents })),
	};

	const pages = fill(Math.ceil(count / (limit[type] || 30)));
	const dispatch = useDispatch();
	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');

	const isShowEvents = isMobile ? !isFilters[type] && count > 0 : count > 0;

	useEffect(() => {
		const fetchData = (type: 'events' | 'notifications') => {
			type === 'events'
				? dispatch(fetchGetCalendarEvents(filters[type]))
				: dispatch(fetchGetNotifications(filters[type]));
		};

		fetchData(type);
	}, [JSON.stringify(filters[type]), type]);

	return (
		<Fragment>
			{isMobile && (
				<Fragment>
					<div className={styles.active} onClick={setFilters[type]}>
						{`${title[type]} (${count})`}

						<Filter type={type} />
					</div>

					{isFilters[type] && <FilterList type={type} />}
				</Fragment>
			)}

			{isLoading[type] ? (
				<Loading />
			) : (
				<Content isShow={isShowEvents} text={message[type]}>
					<Pagination pages={pages} items={count} type={type} />
				</Content>
			)}
		</Fragment>
	);
};
