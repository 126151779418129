import { useState, useEffect } from 'react';
import { title } from 'components/Mass/data';
export const useWithHelp = () => {
	const [isHelp, setIsHelp] = useState(false);

	const setHelp = (e: MouseEvent) => {
		setIsHelp((e.target as HTMLDivElement).innerText === title.confirm);
	};

	useEffect(() => {
		document.addEventListener('mouseover', setHelp);
	}, []);

	return isHelp;
};
