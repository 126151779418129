import React from 'react';
import styles from './loader.module.scss';
import classNames from 'classnames';
export const Loader = ({ isConfirmOrder, isLogin }: { isConfirmOrder?: boolean; isLogin?: boolean }) => {
	return (
		<div
			className={classNames(styles.loader, {
				[styles.confirm]: isConfirmOrder,
				[styles.login]: isLogin,
			})}
		/>
	);
};
