import React from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { NewEvents } from './NewEvents';
import { NotificationList } from './NotificationsList';

export const EventsAll = () => {
	const type = useSelector((state: IStoreState) => state.events.type);

	return type === 'events' ? <NewEvents /> : <NotificationList />;
};
