import React, { useEffect } from 'react';
import styles from './goods.module.scss';
import { Actions } from 'components/Actions';
import { GoodsTable } from './GoodsTable';
import { Pagination } from 'components/Common/Pagination';
import { fill } from 'services/utils/js/fill';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { fetchGetContractorGoods } from 'services/store/goods';
import { ScrollBar } from 'components/Common/ScrollBar';
import { Loading } from 'components/Common/Loading';

export const Goods = () => {
	const count = useSelector((state: IStoreState) => state.goods.count);
	const filters = useSelector((state: IStoreState) => state.filter.listGoods);
	const isLoading = useSelector((state: IStoreState) => state.goods.isLoading);
	const goods = useSelector((state: IStoreState) => state.goods.goodsList);

	const limit = useSelector((state: IStoreState) => state.filter.listGoods.Limit);
	const pages = fill(Math.ceil(count / (limit || 30)));
	const dispatcher = useDispatch();

	useEffect(() => {
		const fetchData = async () => {
			await dispatcher(fetchGetContractorGoods(filters));
		};

		fetchData().then(() => {});
	}, [filters]);

	return (
		<div className={styles.goods}>
			<h1 className={styles.header}>Мои товары</h1>

			<Actions itemsCount={count} type="goods" />

			{!isLoading ? (
				<ScrollBar>
					<GoodsTable />
					{goods.length > 0 && <Pagination pages={pages} items={count} type="goods" />}
				</ScrollBar>
			) : (
				<Loading />
			)}
		</div>
	);
};
