import React from 'react';
import styles from './adminSession.module.scss';
import { ReactComponent as AttentionSVG } from 'assets/images/admin/attention.svg';
import { ReactComponent as EndSVG } from 'assets/images/admin/end.svg';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setSuperToken } from 'services/store/jwt';
import { IStoreState } from 'services/store/interfaces';
import { useIsAdminSession } from 'services/hooks/useIsAdminSession';

export const AdminSession = () => {
	const [isAdminSession] = useIsAdminSession();
	const dispatch = useDispatch();

	const adminToken = useSelector((state: IStoreState) => state.support.adminJWT);
	const endSession = async () => {
		await dispatch(setSuperToken(adminToken));

		localStorage.removeItem('activeSession');
		location.replace('/home');
	};

	return (
		<div className={classNames(styles.adminSession, { [styles.adminSession__hidden]: !isAdminSession })}>
			<div className={styles.adminSession__attention}>
				<AttentionSVG />

				<p className={styles.adminSession__text}>Вы находитесь в режиме техподдержки</p>
			</div>

			<div className={classNames(styles.adminSession__action)} onClick={endSession}>
				<EndSVG />

				<p className={styles.adminSession__close}>
					Завершить <span className={styles.adminSession__little}>сессию</span>
				</p>
			</div>
		</div>
	);
};
