import React, { FC } from 'react';
import styles from './parentRow.module.scss';
import { TItemGroup } from 'services/store/interfaces/ordersGrouping';
import { motion } from 'framer-motion';

type TParentRowProps = {
	isActive: boolean;
	onActive: (value: boolean) => void;
	order: TItemGroup;
};
export const ParentRow: FC<TParentRowProps> = ({ order, isActive, onActive }) => {
	return (
		<motion.div initial={{ x: -0.5 }} animate={{ x: 0 }} transition={{ duration: 0.3 }} className={styles.main}>
			<div className={styles.check} onClick={() => onActive(!isActive)}>
				<span>{isActive ? '-' : '+'}</span>
			</div>

			<div className={styles.title}>{order.Title}:</div>
			<div className={styles.value}>{order.Valuex}</div>
			<div className={styles.count}>Всего: {order.CountOrders}</div>
		</motion.div>
	);
};
