import { request } from 'services/utils/request';
import { TUpdateOrderBuyMultiPayload, TUpdateOrderBuyGroupResponse } from 'services/store/interfaces';
export const updateOrderBuyMulti = async (
	payload: TUpdateOrderBuyMultiPayload
): Promise<TUpdateOrderBuyGroupResponse[]> => {
	return await request.post(
		'SupplierPortal.UpdateOrderBuyMulti',
		{
			...payload,
		},
		localStorage.token
	);
};
