import React from 'react';
import styles from './UserHome/userHome.module.scss';
import { AdminHome } from './AdminHome';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { Loading } from 'components/Common/Loading';
import { UserHome } from './UserHome';

export const Home = () => {
	const admin = useSelector((state: IStoreState) => state.support.isAdmin);
	const isLoading = useSelector((state: IStoreState) => state.support.loading);

	const isAdmin = admin === true;
	const isUser = admin === false;

	return isLoading ? (
		<Loading />
	) : (
		<div className={styles.homepage}>
			{isUser && <UserHome />}
			{isAdmin && <AdminHome />}
		</div>
	);
};
