import React, { FC, useState, useEffect, useRef, MouseEvent } from 'react';
import styles from './inputSelect.module.scss';
import { GenericList } from 'components/Common/GenericList';
import { merge } from 'services/utils/js/merge';
import { useDispatch } from 'react-redux';
import { ReactComponent as Arrow } from 'assets/images/actions/arrow_down.svg';
import { ReactComponent as SearchSVG } from 'assets/images/address/search.svg';
import { ReactComponent as ClearSVG } from 'assets/images/address/clear.svg';
import { ReactComponent as TrashSVG } from 'assets/images/address/trash.svg';
import { TResultBlock, TKeys } from 'services/store/interfaces';
import { setAddress } from 'services/store/address';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import useBreakpoint from 'services/hooks/useBreakpoint';
interface ISelectType {
	initial: TSelectField;
	title?: string;
	onChange?: (value: TSelectField[]) => void;
	withSearch?: boolean;
	options?: TSelectField[];
	values?: TSelectField[];
	error?: string;
	onResultBlock?: (value: TResultBlock) => void;
	onSetHidden?: (value: boolean | string) => void;
	isEmpty?: boolean;
}

export type TSelectField = {
	id: string;
	text: string;
};

const toFilterList = (list: TSelectField[]) => {
	return (property: keyof TSelectField) => {
		return (letters: string) => {
			const result = list.filter((item) => item[property]?.toLowerCase().includes(letters));

			return result.length > 0 ? result : [{ id: '-1', text: 'Совпадений нет' }];
		};
	};
};

const getValue = (value: TSelectField[], initial: TSelectField) => {
	const count = value.length;
	return count === 0 ? initial.text : count === 1 ? value[0].text : 'Выбрано несколько';
};

export const InputSelect: FC<ISelectType> = ({
	initial,
	options = [],
	values = [],
	onChange = () => {},
	onSetHidden,
	withSearch,
	title,
	onResultBlock = () => {},
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [value, setValue] = useState<TSelectField[]>(values);
	const [search, setText] = useState({ text: 'Начните печатать...' });
	const [items, setList] = useState<TSelectField[]>([]);
	const [isClean, setIsClean] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();

	const breakpoints = useBreakpoint();
	const isDesktop = !['', 'xs', 'xxs'].includes(breakpoints);
	const isResult = !isOpen && value.length > 1;

	useEffect(() => {
		setValue(values);
	}, [JSON.stringify(values)]);

	useEffect(() => {
		if (isOpen) return;

		onResultBlock({ [title as TKeys]: ref.current ? ref.current.offsetHeight : false });
	}, [isOpen, ref]);

	useEffect(() => {
		setList([]);
		setIsClean(false);
		setText({ text: 'Начните печатать...' });

		if (isDesktop && onSetHidden) onSetHidden(isOpen ? 'hidden' : value.length === 0);
	}, [isOpen]);

	const handleChange = (letters: string) => {
		setIsClean(true);
		setText({ ...search, text: letters });
		const res = toFilterList(options)('text')(letters.toLowerCase());
		setList(res);
	};

	const setValueActive = (val: string) => {
		if (val === '-1') return;
		const values = value.filter(({ id }) => id === val);

		if (values.length === 0) {
			const item = options.filter(({ id }) => id === val);

			setValue([...value, item[0]]);
			onChange([...value, item[0]]);
			dispatch(setAddress({ id: title, value: [...value, item[0]] }));

			return;
		}

		const items = value.filter(({ id }) => id !== val);

		setValue([...items]);
		onChange([...items]);
		dispatch(setAddress({ id: title, value: [...items] }));
	};

	const onClear = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();

		setIsClean(false);
		setText({ text: 'Начните печатать...' });
		setList(options);
	};

	const isActive = (val: string) => value.filter(({ id }) => val === id).length > 0;

	return (
		<OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
			<div className={styles.select}>
				<div
					className={classNames(styles.select__header, {
						[styles.select__header_closed]: !isOpen && value && value.length > 0,
						[styles.select__header_opened]: isOpen,
					})}
					id="type"
				>
					<div
						className={styles.select__header_left}
						onClick={(e) => {
							e.stopPropagation();
							setIsOpen(true);
						}}
					>
						{withSearch ? (
							<>
								{isOpen && !isClean && <SearchSVG />}

								{isOpen && isClean && (
									<div className={styles.select__clear} onClick={onClear}>
										<ClearSVG />
									</div>
								)}

								<input
									type="text"
									onClick={() => setText({ text: '' })}
									className={styles.select__input}
									onChange={(e) => handleChange(e.target.value)}
									value={isOpen ? search.text : getValue(value, initial)}
								/>
							</>
						) : (
							getValue(value, initial)
						)}
					</div>

					<div onClick={() => setIsOpen(!isOpen)}>
						<Arrow className={classNames(styles.arrow, { [styles.rotate]: isOpen })} />
					</div>
				</div>

				{isOpen && (
					<div
						className={classNames(styles.select__body, {
							[styles.select__body_opened]: isOpen,
						})}
					>
						<GenericList
							list={(withSearch && items.length !== 0 ? items : options).map(
								merge({
									className: styles.select__item,
									isActive,
									onClick: setValueActive,
									type: 'checkbox',
								})
							)}
						>
							<div
								className={classNames(styles.select__clean, {
									[styles.select__clean_visible]: value.length > 0,
								})}
								onClick={(e) => {
									e.stopPropagation();
									onChange([]);
									setValue([]);
								}}
							>
								<TrashSVG />
								<span className={styles.select__body_trash}>Очистить отмеченные</span>
							</div>
						</GenericList>
					</div>
				)}
			</div>

			{isResult && (
				<div className={styles.select__result} ref={ref}>
					{value.map(({ text }) => text).join(', ')}
				</div>
			)}
		</OutsideClickHandler>
	);
};
