import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IStateUser } from './interfaces';
import { getContractorInfo } from '../api/getContractorInfo';

const initialState: IStateUser = {
	value: null,
	loading: false,
	clipboard: { text: '' },
	message: { type: '', text: '' },
	error: '',
};

export const profileFields = [
	{ type: 'ContractorId', value: 'Уникальный ID контрагента', editable: false },
	{ type: 'ContractorName', value: 'Полное наименование контрагента', editable: false },
	{ type: 'ContractorUNN', value: 'УНН контрагента', editable: false },
	{ type: 'ContractorAddress', value: 'Юридический адрес', editable: false },
	{ type: 'ContractorTelefon1', value: 'Контактный телефон', editable: false },
	{ type: 'LicenseNumber', value: 'Уникальный ID лицензии', editable: false },
	{ type: 'LicenseDateBegin', value: 'Дата лицензии (от)', editable: false },
	{ type: 'LicenseDateEnd', value: 'Дата окончания лицензии', editable: false },
	{ type: 'LicenseType', value: 'Тип лицензии', editable: false },
	{ type: 'BankName', value: 'Наименование банка', editable: false },
	{ type: 'BankAccountNumber', value: 'Расчетный счет контрагента', editable: false },
	{ type: 'BankBIC', value: 'Уникальный BIC банка', editable: false },
	{ type: 'ContractNumber', value: 'Номер договора контрагента', editable: false },
	{ type: 'ContractDateBegin', value: 'Договор от', editable: false },
	{ type: 'ContractDateEnd', value: 'Договор до', editable: false },
	{ type: 'CertificateNumber', value: 'Номер свидетельства о государственной регистрации', editable: false },
	{ type: 'CertificateDate', value: 'Дата выдачи сведельства о рег.', editable: false },
	{ type: 'Name1', value: 'Фамилия представителя контрагента', editable: false },
	{ type: 'Name2', value: 'Имя представителя контрагента', editable: false },
	{ type: 'Name3', value: 'Отчество представителя контрагента', editable: false },
	{ type: 'Phone1', value: 'Мобильный телефон', editable: false },
	{ type: 'EMail', value: 'E-mail', editable: false },
	{ type: 'MarkerKPI', value: 'KPI показатели активности контрагента', editable: false },
];

export const fetchContractorInfo = createAsyncThunk('users/fetchContractor', async (token: string) => {
	return await getContractorInfo(token);
});

const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		killUser: (state: IStateUser) => {
			state.value = null;
			localStorage.removeItem('userName');
			localStorage.removeItem('userId');
		},
		setClipboard: (state, action) => {
			state.clipboard = { ...state.clipboard, ...action.payload };
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchContractorInfo.pending, (state) => {
				state.loading = true;
				state.error = '';
			})
			.addCase(fetchContractorInfo.fulfilled, (state, action) => {
				const result = action.payload[0];

				if (result) {
					localStorage.setItem('userName', String(result.ContractorName));
					localStorage.setItem('userId', String(result.ContractorId));
					state.value = result;
				}

				if (!result) {
					localStorage.setItem('userName', 'Админ');
					localStorage.setItem('userId', '---');

					state.message = { type: 'empty', text: 'Данные отсутствуют ...' };
				}

				state.loading = false;
			})
			.addCase(fetchContractorInfo.rejected, () => {});
		// .addCase(fetchContractorIsAllowedDemandPlan.pending, () => {})
		// .addCase(fetchContractorIsAllowedDemandPlan.fulfilled, (_, action) => {
		// 	const result = action.payload[0].Result;
		//
		// 	if (result === '1') {
		// 		localStorage.setItem('userIsAllowedDemandPlan', '1');
		// 	}
		//
		// 	if (!result || result === '0') {
		// 		localStorage.removeItem('userIsAllowedDemandPlan');
		// 	}
		// })
		// .addCase(fetchContractorIsAllowedDemandPlan.rejected, () => {
		// 	localStorage.removeItem('userIsAllowedDemandPlan');
		// });
	},
});

export const { setClipboard } = user.actions;
export default user.reducer;
