import styles from 'components/Content/DownTimes/CalendarPanel/MonthCalendar/Down/down.module.scss';

export const getStyles = (
	isOne: boolean,
	Days: number[],
	height: number,
	days: number[],
	currCount: number,
	sizeWidth: number,
	order: number,
	prevIndex: number
) => {
	const bottom = prevIndex * (height || 0) + 2 * prevIndex;
	const leftOne =
		order < 5
			? 7 - days.length + days.indexOf(Days[0]) > 0
				? (7 - days.length + days.indexOf(Days[0])) * sizeWidth
				: 0
			: days.indexOf(Days[0]) * sizeWidth;
	const width = currCount * sizeWidth - 8;
	const left = order === 5 ? (days.indexOf(Days[0]) < 0 ? 0 : days.indexOf(Days[0]) * sizeWidth) : leftOne;

	return { bottom: bottom + 8, left: left + 8, width: isOne ? width - 8 : width };
};

export const getPeriod = (DateStart: string, DateEnd: string) => {
	const first = DateStart.split('-')[2];
	const monthFirst = DateStart.split('-')[1];

	const last = DateEnd.split('-')[2];
	const monthLast = DateEnd.split('-')[1];

	const yearFirst = DateStart.split('-')[0];
	const yearLast = DateEnd.split('-')[0];
	const yearStart = yearLast !== yearFirst ? `.${yearFirst}` : '';
	const yearEnd = yearLast !== yearFirst ? `.${yearLast}` : '';

	return `${first}.${monthFirst}${yearStart} - ${last}.${monthLast}${yearEnd}`;
};

export const setStyle = (DayId: string) => {
	const elems = document.querySelectorAll(`[data-id="${DayId}"]`);
	Array.from(elems).map((elem) => elem.classList.add(styles.hover));
};

export const removeStyle = (DayId: string) => {
	const elems = document.querySelectorAll(`[data-id="${DayId}"]`);
	Array.from(elems).map((elem) => elem.classList.remove(styles.hover));
};
