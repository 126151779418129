import React, { Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { CheckOffline } from './components/CheckOffline';
import { CheckMessage } from './components/CheckOffline/CheckMessage';
import { useHandlerMetrics } from './services/hooks/useHandlerMetrics';
import {
	ProfilePage,
	ErrorPage,
	LoginPage,
	OrdersPage,
	ReportsPage,
	GoodsPage,
	EventsPage,
	CookiesPage,
	HomePage,
	PolicyPage,
} from './pages';
import { useReportsPage } from './services/hooks/useBreakpoint';
import { useHiddenScroll } from './services/hooks/useHiddenScroll';
import { IStoreState } from './services/store/interfaces';
import { useSelector } from 'react-redux';

const App = () => {
	const isVisibleReportsPage = useReportsPage();
	const jwt = useSelector((state: IStoreState) => state.jwt.value);
	const token = localStorage.token || jwt;

	useHiddenScroll();
	useHandlerMetrics();

	return (
		<Fragment>
			<ScrollToTop />
			<CheckOffline />

			<Switch>
				<Route exact={true} path="/">
					{token ? <Redirect to="/home" /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/profile">
					{token ? <ProfilePage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/login">
					{token ? <Redirect to="/home" /> : <LoginPage />}
				</Route>

				{/*<Route exact={true} path="/registration">*/}
				{/*	{jwt ? <Redirect to="/home" /> : <Registration />}*/}
				{/*</Route>*/}

				<Route exact={true} path="/orders">
					{token ? <OrdersPage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/orders/:id">
					{token ? <OrdersPage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/reports/:id">
					{token ? isVisibleReportsPage ? <ReportsPage /> : <Redirect to="/orders" /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/goods">
					{token ? <GoodsPage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/events">
					{token ? <EventsPage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/home">
					{token ? <HomePage /> : <Redirect to="/login" />}
				</Route>

				<Route exact={true} path="/cookies">
					<CookiesPage />
				</Route>

				<Route exact={true} path="/policy">
					<PolicyPage />
				</Route>

				<Route path="*">
					<ErrorPage />
				</Route>
			</Switch>

			<CheckMessage text="Проверьте соединение интернета ..." />
		</Fragment>
	);
};

export default App;
