import React, { FC, useContext, memo } from 'react';
import GroupsContext from 'services/contexts/GroupsContext';
import { TGetOrderBuyGroupResponse } from 'services/store/interfaces';
import { getStyleTd } from 'services/utils/js/getStyleTd';
import styles from './orderItem.module.scss';
import classnames from 'classnames';
import { OrderProp } from 'components/InfoTable/OrderProp';

type TOrderIdProps = {
	id: string;
	order: TGetOrderBuyGroupResponse;
};

export const OrderItem: FC<TOrderIdProps> = ({ id, order }) => {
	const { coordinates } = useContext(GroupsContext);
	const isOrderId = id === 'OrderBuyGroupId';

	return (
		<div
			className={classnames(styles.orderItem_td, {
				[styles.orderItem_td__id]: isOrderId,
			})}
			style={{ ...getStyleTd(coordinates, id) }}
		>
			<OrderProp id={id} key={id} item={order} isShow isGroupMode />
		</div>
	);
};

export default memo(OrderItem);
