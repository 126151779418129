import { request } from 'services/utils/request';
import { TDemandPlanResponse } from 'services/store/interfaces/reports';
import { TReportFilterPlanning } from 'services/store/interfaces';

export const getContractorDemandPlan = async (payload: TReportFilterPlanning): Promise<TDemandPlanResponse> => {
	return await request.post(
		'SupplierPortal.DemandPlanGet',
		{
			...payload,
		},
		localStorage.token
	);
};
