import React, { Fragment } from 'react';
import styles from './actions.module.scss';
import { Filter } from 'components/Filter';
import { DropButton } from 'components/Common/DropButton';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';
import { Settings } from 'components/Settings';
import { Units } from 'components/Units';
import { Left } from './Left';
import { Desktop } from 'components/HOCS/Desktop';
import { Mobile } from 'components/HOCS/Mobile';
import { Right } from './Right';
import { Slider } from '../Common/Slider';
import { ActiveOrders } from 'components/Content/Orders/ActiveOrders';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllActive, setAllActive, setCurrent } from 'services/store/orders';
import { removeAllGoodsActive, setAllGoodsActive } from 'services/store/goods';
import { IStoreState } from 'services/store/interfaces';
import classNames from 'classnames';
import { BackItems } from '../BackItems';
import { EditUnits } from '../Content/Orders/EditMany/EditUnits';
import { EventsActions } from '../Content/Events/EventsActions';
import GroupOrders from '../Common/ActionButton/GroupToggle';
import { Mass } from '../Mass';
import { AddAction } from '../Content/DownTimes/AddAction';

interface IActionsProps {
	itemsCount?: number;
	type: 'goods' | 'orders' | 'events' | 'order' | 'multiOrders' | 'notifications' | 'divide' | 'downTimes';
}

export const Actions = ({ itemsCount = 0, type }: IActionsProps) => {
	const ordersList = useSelector((state: IStoreState) => state.orders.ordersList);
	const goodsList = useSelector((state: IStoreState) => state.goods.goodsList);
	const activeList = useSelector((state: IStoreState) => state.orders.activeOrders);
	const activeGoodsList = useSelector((state: IStoreState) => state.goods.activeGoods);

	const isEnableOrdersActions = useSelector((state: IStoreState) => state.multiOrders.isEnableActions);

	const isEvents = ['events', 'notifications', 'downTimes'].includes(type);
	const isOrders = ['orders', 'order', 'multiOrders'].includes(type);
	const isMultiDivide = ['divide', 'multiOrders'].includes(type);
	const isOrdersGroup = type === 'orders';
	const isOrder = type === 'order';
	const isMulti = type === 'multiOrders';
	const isDivide = type === 'divide';
	const isDowntimes = type === 'downTimes';
	const isCommercial = !isEvents;
	const isOrderMulti = isOrder || isMulti || isDivide;
	const isEnableOrdersEdit = isOrders && isEnableOrdersActions && activeList.length > 1;

	const dispatch = useDispatch();

	const mapActiveList = {
		orders: activeList,
		goods: activeGoodsList,
		order: [],
		events: [],
		notifications: [],
		downTimes: [],
		multiOrders: [],
		divide: [],
	};

	const mapListType = {
		orders: ordersList,
		goods: goodsList,
		order: [],
		events: [],
		multiOrders: [],
		notifications: [],
		downTimes: [],
		divide: [],
	};

	const mapSetAllActive = {
		orders: () => dispatch(setAllActive()),
		goods: () => dispatch(setAllGoodsActive()),
		order: () => {},
		events: () => {},
		notifications: () => {},
		downTimes: () => {},
		multiOrders: () => {},
		divide: () => {},
	};

	const mapRemoveAllActive = {
		orders: () => dispatch(removeAllActive()),
		goods: () => dispatch(removeAllGoodsActive()),
		order: () => {},
		events: () => {},
		notifications: () => {},
		downTimes: () => {},
		multiOrders: () => {},
		divide: () => {},
	};

	const isActiveCheck =
		mapActiveList[type].length === mapListType[type].length &&
		mapActiveList[type].length !== 0 &&
		mapListType[type].length !== 0;

	return (
		<div
			className={classNames(styles.actions, {
				[styles.actions_events]: isEvents,
				[styles.actions_multiEdit]: isMultiDivide,
			})}
		>
			<Desktop>
				<Left events={isEvents}>
					{isCommercial && <Settings type={type} />}
					{!isMultiDivide && <Filter type={type} />}
					{type === 'orders' && <Mass />}
					{isOrdersGroup && <GroupOrders />}
					{isEvents && <EventsActions />}
				</Left>

				<Slider>
					{isCommercial && !isDowntimes && <Units type={type} count={itemsCount} />}
					{isEnableOrdersEdit && !isDowntimes && <EditUnits type={type} />}
					{isOrders && <ActiveOrders />}
					{isDowntimes && <AddAction />}
				</Slider>
			</Desktop>

			<Mobile>
				{isEvents && (
					<div className={styles.events}>
						<EventsActions />
					</div>
				)}

				{isCommercial && !isDowntimes && (
					<Fragment>
						<Left>
							{isOrderMulti && (
								<BackItems title={`Назад к заказам`} handler={() => dispatch(setCurrent({ id: '0' }))} />
							)}

							{!isOrderMulti && <DropButton text={`Найдено ${itemsCount} заказов`} />}
						</Left>

						{!isMultiDivide && (
							<Right>
								<Settings type={type} />
								<Filter type={type} />

								<InputCheckbox
									isActive={isActiveCheck}
									onChange={
										mapActiveList[type].length < mapListType[type].length
											? mapSetAllActive[type]
											: mapRemoveAllActive[type]
									}
								/>
							</Right>
						)}
					</Fragment>
				)}
			</Mobile>
		</div>
	);
};

export default React.memo(Actions);
