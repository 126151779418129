import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface IProps {
	name: string;
	value: string | number | undefined;
	editable?: boolean;
}

const Row = ({ name, value, editable = false }: IProps) => {
	return (
		<div
			className={classNames(styles.property, {
				[styles.property_editable]: editable,
			})}
		>
			<div className={styles.property_name}>{name}</div>
			<div className={styles.property_value}>{value}</div>

			{editable && (
				<div className={styles.property_edit}>
					<img src="/assets/icons/edit.svg" alt="редактирование" />
				</div>
			)}
		</div>
	);
};

export default Row;
