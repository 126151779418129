import React from 'react';
import styles from './index.module.scss';
import { IconsDesktop } from './IconsDesktop';
import { IconsMobile } from './IconsMobile';

export const Icons = () => (
	<div className={styles.icons}>
		<IconsMobile />

		<IconsDesktop />
	</div>
);
