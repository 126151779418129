import React from 'react';
import styles from '../UserMenu/userMenu.module.scss';

export const HelpMenu = () => {
	return (
		<div className={styles.userMenu}>
			<div className={styles.header}>
				<div className={styles.title}>Здесь может быть что-нибудь полезное...</div>
			</div>
		</div>
	);
};
