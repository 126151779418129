import React, { useEffect } from 'react';
import sts from './divideOrder.module.scss';
import { fetchGetOrderBuy } from 'services/store/divideOrders';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { OrderDetail } from '../EditMany/OrderDetail';
import { GoodsDetail } from '../EditMany/GoodsDetail';
import { Desktop } from 'components/HOCS/Desktop';
import { setCurrent } from 'services/store/orders';
import { Redirect } from 'react-router-dom';

export const DivideOrder = () => {
	const activeOrders = useSelector((state: IStoreState) => state.orders.activeOrders);
	const isEnable = useSelector((state: IStoreState) => state.multiOrders.isEnableActions);
	const isEnableDivide = activeOrders.length === 1 && isEnable;

	const dispatch = useDispatch();
	const isEmptyOrder = activeOrders.length < 1;

	useEffect(() => {
		const setInfo = () =>
			isEmptyOrder
				? dispatch(setCurrent({ id: '0' }))
				: dispatch(fetchGetOrderBuy({ OrderBuyGroupId: activeOrders, Limit: 300 }));

		setInfo();
	}, [activeOrders]);

	return isEnableDivide ? (
		<div className={sts.divideBlock}>
			<OrderDetail type="divide" />

			<Desktop>
				<GoodsDetail type="divide" />
			</Desktop>
		</div>
	) : (
		<Redirect to="/orders" />
	);
};
