import React, { useEffect, useState } from 'react';
import styles from './input.module.scss';
import classNames from 'classnames';
import { getNumberWithStep } from 'services/utils/js/getNumberWithStep';
import OutsideClickHandler from 'react-outside-click-handler';

interface IInputProps {
	name: string;
	min?: number;
	max?: number;
	value?: string;
	required?: boolean;
	onChange: (value: string) => void;
	label?: string;
	placeholder?: string;
	error?: string;
	mix?: object | string;
	focused?: boolean;
	isDivide?: boolean;
	step?: number;
}

export const Input: React.FC<IInputProps> = ({
	focused,
	name,
	value,
	required,
	onChange,
	max,
	label,
	error,
	placeholder,
	isDivide,
	step,
	mix,
}) => {
	const [valueItem, setValue] = useState(value);

	useEffect(() => {
		setValue(value);
	}, [value]);

	const getValueWithStep = (value: string) => {
		if (value === '') {
			onChange('');
			setValue('');
			return;
		}

		const numValue = Number(value);
		const numStep = Number(step);
		const maxValue = Number(max);

		const res = numValue > numStep ? getNumberWithStep(numValue, numStep, maxValue) : numValue === 0 ? '0' : value;

		setValue(res);
		setQuantityValue(res, numStep, maxValue);
	};

	const setQuantityValue = (res: string, step: number, max: number) =>
		onChange(getNumberWithStep(Number(res), step, max));

	const stepChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const eValue = e.target.value;
		const text = eValue.replace(/[^.\d]/g, '');
		const isValidValue = Number(text) < Number(max) || Number(text) === Number(max);

		if (isValidValue) setValue(text);
	};

	useEffect(() => {
		onChange(valueItem || '');
	}, [valueItem]);

	return (
		<div
			className={classNames(styles.block, {
				[styles.divide]: isDivide,
			})}
		>
			{label && (
				<label className={styles.block__label} htmlFor={name}>
					{label} {required && <span className={styles.block__required}>*</span>}
				</label>
			)}

			<OutsideClickHandler onOutsideClick={() => (isDivide && step ? getValueWithStep(valueItem || '') : {})}>
				<input
					className={classNames(styles.block__input, mix)}
					id={name}
					value={valueItem}
					onChange={stepChangeHandler}
					placeholder={placeholder}
					autoFocus={focused}
				/>
			</OutsideClickHandler>

			{error && <div className={styles.block__error}>{error}</div>}
		</div>
	);
};
