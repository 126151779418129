import React from 'react';
import { fetchMergeOrderBuy } from 'services/store/orders';
import { ActionButton } from 'components/Common/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';

export const ActionMerge = () => {
	const activeOrders = useSelector((state: IStoreState) => state.orders.activeOrders);
	const dispatch = useDispatch();

	return (
		<ActionButton
			text="Объединить"
			type="merge"
			onClick={() => dispatch(fetchMergeOrderBuy({ OrderBuyGroupId: activeOrders }))}
		/>
	);
};
