import React, { Fragment } from 'react';
import { IStoreState } from 'services/store/interfaces';
import styles from '../infoTable.module.scss';
import { TReportsCurrentItem } from 'services/store/interfaces/reports';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

export const ReportsCurrent = () => {
	const { listCurrent, listCurrentActive } = useSelector((state: IStoreState) => state.reports);
	const { listReportsCurrent } = useSelector((state: IStoreState) => state.setting);

	return (
		<Fragment>
			{listCurrent.map((good, inx) => {
				return (
					<tr
						className={classNames(styles.tr, {
							[styles.active]: listCurrentActive?.includes(good.GoodsId),
						})}
						key={inx}
					>
						{/*<td className={styles.td}>*/}
						{/*	<InputCheckbox*/}
						{/*		isActive={listCurrentActive?.includes(good.GoodsId)}*/}
						{/*		onChange={(active) => onChange(active)(good.GoodsId)}*/}
						{/*	/>*/}
						{/*</td>*/}

						{listReportsCurrent.map(({ id, isShow }) => {
							const value = good[id as keyof TReportsCurrentItem];

							return isShow ? (
								<td className={classNames(styles.td, styles.td__reports)} key={id}>
									{value}
								</td>
							) : null;
						})}
					</tr>
				);
			})}
		</Fragment>
	);
};
