import React, { useState } from 'react';
import styles from './modal.module.scss';
import classNames from 'classnames';
import { ReactComponent as IconSVG } from 'assets/images/cookies/cookie.svg';
import { ReactComponent as CloseSVG } from 'assets/images/cookies/close.svg';
import { ReactComponent as CookieSVG } from 'assets/images/cookies/cookieXS.svg';
import { Link } from 'react-router-dom';
import data from '../data.json';
import { Portal } from '../../Modals';
import { useDispatch } from 'react-redux';
import { setDateCookie, setSettings } from 'services/store/cookies';
import { UseIsCookies } from 'services/hooks/useIsCookies';

export const CookiesModal = () => {
	const { isVisible } = UseIsCookies();
	const dispatch = useDispatch();
	const [isHide, setIsHide] = useState(false);

	const setParams = (analytical: '0' | '1') => () => {
		dispatch(setSettings({ analytical }));
		dispatch(setDateCookie());

		if (analytical === '1') setTimeout(() => location.reload());
	};

	return (
		<Portal>
			<div
				className={classNames(styles.cookies, {
					[styles.cookies_visible]: isVisible,
					[styles.cookies_hided]: !isVisible || isHide,
				})}
			>
				<div className={styles.cookies_image}>
					<IconSVG />
				</div>

				<div className={styles.cookies_info}>
					<div className={styles.cookies_title}>
						<div className={styles.cookies_icon}>
							<CookieSVG />
						</div>
						<div className={styles.cookies_header}>{data.title}</div>
						<div className={styles.cookies_close} onClick={() => setIsHide(true)}>
							<CloseSVG />
						</div>
					</div>

					<div className={styles.cookies_content}>
						<div className={styles.cookies_text}>
							Для обеспечения удобства работы с сайтом и его взаимодействия с пользователем, используются{' '}
							<span className={styles.cookies_text__file}>{data.file}</span>. Нажимая «Принять», вы даёте согласие на
							обработку файлов cookie в соответствии с{' '}
							<span className={styles.cookies_text__link}>
								<Link to="/cookies">{data.politics}</Link>
							</span>
							.
						</div>

						<div className={styles.cookies_actions}>
							<div
								className={classNames(styles.cookies_button, styles.cookies_button__disable)}
								onClick={setParams('0')}
							>
								{data.disable}
							</div>

							<div
								className={classNames(styles.cookies_button, styles.cookies_button__confirm)}
								onClick={setParams('1')}
							>
								{data.confirm}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
};
