import { request } from 'services/utils/request';
import { TGetJWT } from './interfaces';

export const getJWT: TGetJWT = async (payload, token) => {
	return await request.post(
		'GetJWT',
		{
			...payload,
		},
		token
	);
};
