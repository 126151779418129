import React, { Fragment } from 'react';
import { IStoreState } from 'services/store/interfaces';
import styles from '../infoTable.module.scss';
import { TReportsQualityItem } from 'services/store/interfaces/reports';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

export const ReportsQuality = () => {
	const { listQuality, listQualityActive } = useSelector((state: IStoreState) => state.reports);
	const { listReportsQuality } = useSelector((state: IStoreState) => state.setting);

	return (
		<Fragment>
			{listQuality.map((good, inx) => {
				return (
					<tr
						className={classNames(styles.tr, {
							[styles.active]: listQualityActive?.includes(good.OrderBuyGroupId),
						})}
						key={inx}
					>
						{/*<td className={styles.td}>*/}
						{/*	<InputCheckbox*/}
						{/*		isActive={listQualityActive?.includes(good.OrderBuyGroupId)}*/}
						{/*		onChange={(active) => onChange(active)(good.OrderBuyGroupId)}*/}
						{/*	/>*/}
						{/*</td>*/}

						{listReportsQuality.map(({ id, isShow }) => {
							const value = good[id as keyof TReportsQualityItem];

							return isShow ? (
								<td className={classNames(styles.td, styles.td__reports)} key={id}>
									{value}
								</td>
							) : null;
						})}
					</tr>
				);
			})}
		</Fragment>
	);
};
