import React from 'react';
import styles from './actualDate.module.scss';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import classNames from 'classnames';

export const ActualDate = () => {
	const { isPlanningTable, dateActualPlan, listTablePlanning } = useSelector((state: IStoreState) => state.reports);

	return listTablePlanning.length > 0 ? (
		<div
			className={classNames(styles.actual, {
				[styles.actual_schedule]: !isPlanningTable,
			})}
		>
			План закупок актуален на {dateActualPlan}
		</div>
	) : null;
};
