import React, { Fragment, useEffect } from 'react';
import { DetailOrder } from './DetailOrder';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { DetailGoods } from './DetailGoods';
import {
	addOrder,
	fetchOrderBuy,
	removeAllActive,
	setConfirmOrder,
	setMergedOrders,
	setReviseOrder,
} from 'services/store/orders';
import { Desktop } from 'components/HOCS/Desktop';
import { Mobile } from 'components/HOCS/Mobile';
import { FilterList } from 'components/Filter/FilterList';
import { SettingList } from 'components/Settings/SettingList';
import { InfoCard } from 'components/InfoCard';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { Pagination } from 'components/Common/Pagination';
import { fill } from 'services/utils/js/fill';
import { Message } from 'components/Common/Message';
import { Loading } from 'components/Common/Loading';
import { setDivided } from 'services/store/divideOrders';

export const OrderGroup = () => {
	const breakpoint = useBreakpoint();
	const isMobile = ['xs', 'xxs'].includes(breakpoint);

	const { current, currentInfo, isLoading } = useSelector((state: IStoreState) => state.orders);
	const isDivided = useSelector((state: IStoreState) => state.divideOrders.isDivided);
	const isMerged = useSelector((state: IStoreState) => state.orders.isMergedOrders);
	const message = useSelector((state: IStoreState) => state.orders.messageOrderGroup);

	const limit = useSelector((state: IStoreState) => state.filter.listOrderGoods.Limit);
	const count = useSelector((state: IStoreState) => state.orders.countOrderGoods);
	const filters = useSelector((state: IStoreState) => state.filter.listOrderGoods);

	const isFilter = useSelector((state: IStoreState) => state.filter.isShowOrder);
	const isSettings = useSelector((state: IStoreState) => state.setting.isShowOrder);
	const isNotEmpty = count > 0;

	const isShowGoods = !isFilter && !isSettings && isNotEmpty;
	const isShowDetail = isMobile ? !isFilter && !isSettings : true;
	const pages = fill(Math.ceil(count / (limit || 30)));

	const dispatch = useDispatch();

	useEffect(() => {
		const fetchData = () => dispatch(fetchOrderBuy({ OrderBuyGroupId: [current], ...filters }));
		fetchData().then(() => {});
	}, [filters.GoodsName, filters.GoodsId]);

	useEffect(() => {
		if (!isDivided && !isMerged) return;

		dispatch(removeAllActive());
		dispatch(addOrder({ id: current }));
		dispatch(setDivided(false));
		dispatch(setMergedOrders(false));
	}, [isDivided, isMerged]);

	const revised = useSelector((state: IStoreState) => state.orders.revisedOrder);
	const confirm = useSelector((state: IStoreState) => state.orders.confirmOrder);

	const isNeedRevise = revised[current];
	const isNeedConfirm = confirm?.includes(current);

	useEffect(() => {
		if (!isNeedRevise) return;

		dispatch(fetchOrderBuy({ OrderBuyGroupId: [current], ...filters }));
		setTimeout(() => dispatch(setReviseOrder()));
	}, [isNeedRevise]);

	useEffect(() => {
		if (!isNeedConfirm) return;

		dispatch(fetchOrderBuy({ OrderBuyGroupId: [current], ...filters }));
		setTimeout(() => dispatch(setConfirmOrder()));
	}, [isNeedConfirm]);

	return (
		<Fragment>
			{isShowDetail && <DetailOrder order={currentInfo} />}

			{isLoading ? (
				<Loading />
			) : (
				<Fragment>
					<Desktop type="order">
						{isNotEmpty ? <DetailGoods /> : <Message text={message} />}

						{isNotEmpty && <Pagination pages={pages} items={count} type="order" />}
					</Desktop>

					<Mobile>
						{isFilter && <FilterList type="order" />}
						{isSettings && <SettingList type="order" />}
						{isShowGoods ? <InfoCard type="order" /> : <Message text={message} />}
					</Mobile>
				</Fragment>
			)}
		</Fragment>
	);
};
