import React from 'react';
import styles from './homeDocs.module.scss';
import { Doc } from './Doc';

const docs = [
	{
		id: '1',
		title: 'Как пользоваться порталом?',
		text: 'PDF-документ с рекомендациями',
		img: 'pdf.svg',
		link: `/PDFUser.pdf?=${new Date().getTime()}`,
	},
];

export const HomeDocs = () => {
	const src = '/assets/images/homepage/';

	return (
		<div className={styles.homeDocs}>
			{docs.map(({ id, title, text, img, link }) => (
				<Doc key={id} title={title} text={text} img={`${src}${img}`} link={link} />
			))}
		</div>
	);
};
