import React, { FC, Fragment } from 'react';
import styles from '../infoTable.module.scss';
import { Status } from 'components/Common/StatusRow';
import classNames from 'classnames';
import { TCurrentOrder } from 'services/store/interfaces';
import { is10Correct, isCorrected, isUpdate } from 'services/utils/js/getOrderStatus';
import { Link } from 'react-router-dom';
import { isAvaibleDate } from 'services/utils/helpers/date';
import { UpdateDate } from '../../UpdateDate';

export type TOrderProps = {
	item: TCurrentOrder;
	id: string;
	isShow: boolean;
	isGroupMode?: boolean;
};

export const OrderProp: FC<TOrderProps> = ({ item, id, isShow, isGroupMode = false }) => {
	const field = item?.[id as keyof TCurrentOrder];

	const isDatex = id === 'Datex';
	const isConfirm = id === 'NextOrderDate';
	const isEdited = isDatex && is10Correct(item) && isAvaibleDate(item.Datex || '');
	const isUpdated = isDatex && isUpdate(item) && isAvaibleDate(item.Datex || '');
	const isCorrect = isDatex && (isCorrected(item) || is10Correct(item)) && isAvaibleDate(item.Datex || '');

	const dateStart = String(item?.Datex);
	const dateEnd = String(item?.ShipmentDateLast);
	const orderId = String(item?.OrderBuyGroupId);

	const isStatus = id === 'Checkx';
	const isId = id === 'OrderBuyGroupId';
	const isOthers = !isStatus && !isCorrect && !isEdited && !isUpdated && !isId && !isConfirm;

	const isPrice = id === 'OrderSum';
	const isWarehouse = id === 'DepartmentAddress';

	return isShow ? (
		!isGroupMode ? (
			<td
				className={classNames(styles.td, {
					[styles.id]: isId,
					[styles.warehouse]: isWarehouse,
					[styles.status]: isStatus,
				})}
			>
				{isId && <Link to={`orders/${orderId}`}>{orderId}</Link>}

				{isUpdated && <UpdateDate id={orderId} dateStart={dateStart} dateEnd={dateEnd} type="update" />}

				{isCorrect && <UpdateDate id={orderId} dateStart={dateStart} dateEnd={dateEnd} type="correct" />}

				{isStatus && <Status order={item} />}

				{isConfirm && <span className={styles.td_confirm}>{field}</span>}

				{isOthers && (isPrice ? `${field} BYN` : field)}
			</td>
		) : (
			<Fragment>
				{isId && <Link to={`orders/${orderId}`}>{orderId}</Link>}

				{isUpdated && <UpdateDate id={orderId} dateStart={dateStart} dateEnd={dateEnd} type="update" />}

				{isCorrect && <UpdateDate id={orderId} dateStart={dateStart} dateEnd={dateEnd} type="correct" />}

				{isStatus && <Status order={item} />}

				{isConfirm && <span className={styles.td_confirm}>{field}</span>}

				{isOthers && (isPrice ? `${field} BYN` : field)}
			</Fragment>
		)
	) : null;
};
