import React, { useEffect, useState } from 'react';
import { IStoreState, TCurrentOrder } from 'services/store/interfaces';
import classNames from 'classnames';
import styles from '../infoTable.module.scss';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';
import { useSelector, useDispatch } from 'react-redux';
import { OrderProp } from '../OrderProp';
import { fetchUpdateOrdersAfterConfirm, fetchUpdateOrdersAfterRewise } from 'services/store/grouping';

interface IOrdersRowProps {
	order: TCurrentOrder;
	onChange: (value: boolean) => (value: string) => void;
}
export const OrdersRow = ({ order, onChange }: IOrdersRowProps) => {
	const [item, setItem] = useState<TCurrentOrder>(order);
	const { cancelOrder, activeOrders, confirmOrder, revisedOrder } = useSelector((state: IStoreState) => state.orders);
	const settingOrders = useSelector((state: IStoreState) => state.setting.listOrders);
	const dispatch = useDispatch();

	useEffect(() => {
		setItem(order);
	}, [order.Checkx, order.IsRevised]);

	useEffect(() => {
		setTimeout(() => {
			if (!(confirmOrder.length > 0 && confirmOrder.includes(String(item?.OrderBuyGroupId)))) return;

			dispatch(fetchUpdateOrdersAfterConfirm(String(item?.OrderBuyGroupId))).then((res: any) => setItem(res.payload));
		}, 1000);
	}, [confirmOrder.length]);

	useEffect(() => {
		setTimeout(() => {
			if (!(Object.keys(revisedOrder).length > 0 && Object.keys(revisedOrder).includes(String(item?.OrderBuyGroupId))))
				return;

			dispatch(fetchUpdateOrdersAfterRewise(String(item?.OrderBuyGroupId))).then((res: any) => setItem(res.payload));
		}, 1000);
	}, [JSON.stringify(revisedOrder)]);

	return (
		<tr
			className={classNames(styles.tr, {
				[styles.hidden]: cancelOrder.includes(String(item?.OrderBuyGroupId)),
				[styles.active]: activeOrders.includes(String(item?.OrderBuyGroupId)),
			})}
		>
			<td className={styles.td}>
				<InputCheckbox
					isActive={activeOrders?.includes(String(item?.OrderBuyGroupId))}
					onChange={(isActive) => onChange(isActive)(String(item?.OrderBuyGroupId))}
				/>
			</td>

			{settingOrders.map(({ id, isShow }) => (
				<OrderProp id={id} key={id} item={item} isShow={isShow} />
			))}
		</tr>
	);
};
