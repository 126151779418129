import styles from './tutorial.module.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as LightSVG } from 'assets/images/sidebar/light.svg';
import React from 'react';

export const Tutorial = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.tutorial}>
				<Link to={`/PDFUser.pdf?=${new Date().getTime()}`} target="_blank">
					<div className={styles.tutorial__top}>
						<div>Как пользоваться порталом?</div>
						<LightSVG />
					</div>

					<div className={styles.tutorial__bottom}>PDF-документ с рекомендациями</div>
				</Link>
			</div>
		</div>
	);
};
