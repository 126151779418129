import React, { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import styles from './dropButton.module.scss';
import classNames from 'classnames';

interface IDropButtonProps {
	text?: string;
	icon?: ReactElement | ReactNode;
	mix?: string;
	onClick?: () => void;
}

export const DropButton: FC<PropsWithChildren<IDropButtonProps>> = ({ text, icon, mix, onClick }) => {
	return (
		<div className={classNames(styles.dropButton, mix)} onClick={onClick}>
			{icon && <div className={styles.icon}>{icon}</div>}
			{text && <div className={styles.text}>{text}</div>}
		</div>
	);
};
