import React, { Fragment, useEffect, useState } from 'react';
import { ReactComponent as Edit } from 'assets/images/actions/edit-order.svg';
import styles from './quantityGood.module.scss';
import { Input } from 'components/Common/Input';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { setErrors } from 'services/store/multiorders';
import { Counter } from '../../DivideOrder/DivideCounter';

interface IQuantityGoodProps {
	value: string;
	onChange: (valueId: string, valueQuantity: string, OrderId: string) => void;
	endQuantity?: string;
	lineId: string;
	orderId: string;
	isActive?: boolean;
	inPack?: string;
	isEdit?: boolean;
	isControlled?: boolean;
	isMobile?: boolean;
}

export const QuantityGood = ({
	lineId,
	orderId,
	value,
	endQuantity,
	onChange,
	isActive,
	inPack,
	isEdit,
	isControlled,
	isMobile,
}: IQuantityGoodProps) => {
	const [editMode, setEditMode] = useState(isActive);
	const [quantity, setQuantity] = useState(value);
	const errors = useSelector((state: IStoreState) => state.multiOrders.errors);

	const dispatch = useDispatch();
	const change = (value: string) => (lineId: string) => {
		if (!isControlled) {
			const pattern = String(value.match(/^$|\d+(?:[.]\d+)?/)?.join(''));
			dispatch(setErrors({ [lineId]: pattern.length !== value.length }));
		}

		setQuantity(value);

		if (inPack) onChange(lineId, value, orderId);
	};

	useEffect(() => {
		setEditMode(isActive);
	}, [isActive]);

	return !editMode ? (
		<Fragment>
			{inPack && !isEdit && <p className={styles.quantity_default}>{isMobile ? quantity : ''}</p>}

			{inPack && isEdit && (
				<div
					className={classNames(styles.quantity, {
						[styles.error]: errors[lineId],
					})}
					onClick={() => setEditMode(true)}
				>
					<Input name="quantity" onChange={() => {}} mix={styles.goodQuantity} value={quantity} />

					<p className={styles.quantity_edit}>
						<Edit />
					</p>
				</div>
			)}
		</Fragment>
	) : (
		<OutsideClickHandler
			onOutsideClick={() => {
				if (inPack && !isEdit) return;

				setEditMode(false);
				onChange(lineId, quantity, orderId);
			}}
		>
			<div
				className={classNames(styles.edit, {
					[styles.error]: errors[lineId],
				})}
			>
				{inPack && isControlled && (
					<Counter
						start={quantity}
						end={endQuantity}
						step={inPack}
						onChange={(value) => change(value)(lineId)}
						mix={styles.goodQuantity}
						isEdit={isEdit}
						isMobile={isMobile}
					/>
				)}

				{!isControlled && (
					<Fragment>
						<Input
							name="quantity"
							onChange={(value) => change(value)(lineId)}
							mix={styles.goodQuantity}
							max={Number(endQuantity)}
							value={quantity}
							focused={true}
						/>

						<p className={styles.quantity_edit} />
					</Fragment>
				)}
			</div>
		</OutsideClickHandler>
	);
};
