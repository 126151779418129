import React, { FC, PropsWithChildren } from 'react';
import { Portal } from '../../Modals';
import styles from './modal.module.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as CloseSVG } from 'assets/images/modal/close.svg';

type TMassModal = {
	title: string;
	description: string;
	onClose: () => void;
};
export const Modal: FC<PropsWithChildren<TMassModal>> = ({ title, description, children, onClose }) => {
	return (
		<Portal>
			<div className={styles.modal}>
				<OutsideClickHandler onOutsideClick={onClose}>
					<div className={styles.modal_body}>
						<div className={styles.modal_title}>{title}</div>
						<div className={styles.modal_description}>{description}</div>

						<div className={styles.modal_actions}>{children}</div>

						<div className={styles.close} onClick={onClose}>
							<CloseSVG />
						</div>
					</div>
				</OutsideClickHandler>
			</div>
		</Portal>
	);
};
