import React from 'react';
import styles from './addAction.module.scss';
import { Plus } from 'assets/images/downTimes/Plus';
import { useDispatch } from 'react-redux';
import { setIsOpenCreate } from 'services/store/downTimes';

export const AddAction = () => {
	const dispatch = useDispatch();
	const onCreate = () => dispatch(setIsOpenCreate(true));

	return (
		<div className={styles.addAction} onClick={onCreate}>
			<Plus width="16" height="16" color="black" />

			<div className={styles.addAction_text}>Добавить</div>
		</div>
	);
};
