import dayjs from 'dayjs';
import { getValidDate } from '../js/getValidDate';
import { TNumDay, TNumRequired } from '../../store/interfaces/downTimes';

export const endPoint = (dateStart: string, days = 2) => {
	return dayjs(new Date(getValidDate(dateStart)).setDate(new Date(getValidDate(dateStart)).getDate() + days)).format(
		'YYYY-MM-DD'
	);
};

export const startPoint = (dateStart: string, days = 2) => {
	return dayjs(new Date(getValidDate(dateStart)).setDate(new Date(getValidDate(dateStart)).getDate() - days)).format(
		'YYYY-MM-DD'
	);
};
export const isAvaibleDate = (dateStart: string, days = 2) => {
	return dayjs(endPoint(dateStart, days)) > dayjs(new Date()) || dayjs(endPoint(dateStart, days)) === dayjs(new Date());
};

export const isAvaibleDayDate = (dateStart: string, days = 14) => {
	return (
		dayjs(dateStart) > dayjs(new Date().setDate(new Date().getDate() + days)) ||
		dayjs(dateStart) == dayjs(new Date().setDate(new Date().getDate() + days))
	);
};

export const getIsFuture = (day: TNumDay | TNumRequired | undefined, countDays = 14) => {
	if (!day) return false;

	const dateDay = new Date(day.year, day.month, day.num).toDateString();
	const currentDate = Date.parse(new Date().toDateString());
	const days = (currentDate - Date.parse(dateDay)) / 86400000;
	const endPoint = dayjs(new Date().setDate(new Date().getDate() + countDays));

	return Math.round(days) < 0 && dayjs(dateDay) > dayjs(endPoint.toString());
};

export const getIsToday = (day: TNumDay | TNumRequired | undefined) => {
	if (!day) return false;

	const dToday = new Date().getDate();
	const mToday = new Date().getMonth();
	const yToday = new Date().getFullYear();

	return dToday === day.num && mToday === day.month && yToday === day.year;
};
