import React, { FC, useState, useEffect } from 'react';
import styles from './week.module.scss';
import classNames from 'classnames';
import { TDemandScheduleItemData } from 'services/store/interfaces/reports';
import { motion } from 'framer-motion';

type TWeekSchedule = {
	isBlue: boolean;
	isGreen: boolean;
	TableData: TDemandScheduleItemData[];
	WEEK_DATEX: string;
	baseHeight: number | undefined;
	step: number;
	setX: (value: number) => void;
	setY: (value: number) => void;
	setItem: (value: TDemandScheduleItemData | null) => void;
	itemTooltip: TDemandScheduleItemData | null | undefined;
	setIsTooltip: (value: boolean) => void;
	y: number | undefined;
};

export const Week: FC<TWeekSchedule> = ({
	setIsTooltip,
	setX,
	setY,
	y,
	setItem,
	step,
	itemTooltip,
	baseHeight,
	isBlue,
	isGreen,
	TableData,
	WEEK_DATEX,
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const [isTime, setIsTime] = useState(0);

	useEffect(() => {
		setIsTooltip(isTime > 20);
	}, [isTime]);

	return (
		<div className={styles.week} onMouseOver={() => setIsVisible(true)} onMouseOut={() => setIsVisible(false)}>
			<div className={styles.goods}>
				{TableData.map((good, inxGood) => {
					return (
						<div key={`${inxGood}-${good.SUM_ORDER_QTY}`}>
							<div className={styles.quantity} onMouseEnter={() => setIsTooltip(false)}>
								{good.SUM_ORDER_QTY}
							</div>

							<div
								className={classNames(styles.good, {
									[styles.good__blue]: isBlue,
									[styles.good__green]: isGreen,
								})}
								onMouseMove={(e) => {
									setItem(good);
									const tooltips = document.querySelectorAll('[class*="tooltip"]');
									const tooltip = Array.from(tooltips)[0] as HTMLDivElement;
									setY(e.clientY + ((y || 0) < e.clientY ? -20 : 20));

									if (tooltip) {
										const res = window.innerWidth - (e.clientX + tooltip.offsetWidth);
										setX(e.clientX + tooltip.offsetWidth < window.innerWidth ? e.clientX - 10 : e.clientX - 10 + res);
									} else {
										setX(e.clientX - 10);
									}

									if (JSON.stringify(itemTooltip) === JSON.stringify(good)) {
										setIsTime(isTime + 1);
									}

									if (JSON.stringify(itemTooltip) !== JSON.stringify(good)) {
										setIsTime(0);
									}
								}}
								style={{
									height: baseHeight
										? baseHeight * good.SUM_ORDER_QTY - 2 < 21
											? 20
											: baseHeight * good.SUM_ORDER_QTY - 2
										: 0,
									width: 40,
								}}
							>
								{good.SUM_ORDER_QTY > step && (
									<div className={styles.good_barcode}>
										<span>SCU</span> {good.ITEM_CODE}
									</div>
								)}

								{good.SUM_ORDER_QTY > step && <div className={styles.good_name}>{good.ITEM_DESCR}</div>}
							</div>
						</div>
					);
				})}
			</div>

			<motion.div
				layoutId="underline"
				className={styles.border}
				animate={isVisible ? { opacity: 1 } : { opacity: 0 }}
				transition={{ duration: 0.3 }}
				onMouseEnter={() => setIsTooltip(false)}
			>
				<div className={styles.border__add} />
			</motion.div>

			<div className={styles.dates}>{WEEK_DATEX}</div>
		</div>
	);
};
