export const REPORTS = [
	{ id: 'allGoods', text: 'Все товары в заказах', link: '/reports/allGoods' },
	{ id: 'quality', text: 'Качество поставок', link: '/reports/quality' },
	{ id: 'current', text: 'Товары в текущих заказах', link: '/reports/current' },
	{ id: 'planning', text: 'Планирование закупок', link: '/reports/planning' },
];

export const Titles = {
	allGoods: 'Все товары в заказах',
	quality: 'Качество поставок',
	current: 'Товары в текущих заказах',
	planning: 'Планирование закупок',
};
