import React, { FC } from 'react';
import styles from './oferta.module.scss';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

export const Oferta: FC = () => {
	const { isPlanningTable } = useSelector((state: IStoreState) => state.reports);

	return (
		<div
			className={classNames(styles.public, {
				[styles.public_schedule]: !isPlanningTable,
			})}
		>
			<div className={styles.public_title}>Информация на странице не является публичной офертой.</div>

			<div className={styles.public_text}>
				Обращаем ваше внимание на то, что данная информация носит исключительно информационный характер и ни при каких
				условиях не является публичной офертой, определяемой положениями ст. 405 Гражданского кодекса Республики
				Беларусь.
			</div>
		</div>
	);
};
