import { request } from 'services/utils/request';

export type TGetContractorResponse = {
	LoginId: string;
	ContractorId?: string;
	ContractorName?: string;
	ContractorUNN?: string;
	ContractorAddress?: string;
	ContractorTelefon1?: string;
	LevelId?: string;
	LicenseNumber?: string;
	LicenseDateBegin?: string;
	LicenseDateEnd?: string;
	LicenseName?: string;
	LicenseType?: string;
	BankName?: string;
	BankAccountNumber?: string;
	BankBIC?: string;
	ContractNumber?: string;
	ContractDateBegin?: string;
	ContractDateEnd?: string;
	CertificateNumber?: string;
	CertificateDate?: string;
	Name1?: string;
	Name2?: string;
	Name3?: string;
	Phone1?: string;
	EMail?: string;
	NonResident?: string;
	MarkerKPI?: string;
};

export type TGetContractor = (token?: string) => Promise<[TGetContractorResponse]>;

export const getContractorInfo: TGetContractor = async (token?: string) => {
	return await request.post('SupplierPortal.GetContractorInfo', {}, token);
};
