import React, { useEffect, useMemo } from 'react';
import { MainLayout } from 'layouts/Main';
import { useLocation } from 'react-router-dom';
import { AllGoodsScreen, CurrentScreen, PlanningScreen, QualityScreen } from 'components/Content/Reports/Entities';
import { Titles } from 'components/Content/Reports/data';
import { TReportEntity } from 'components/Content/Reports/types';
import { Settings } from 'components/Settings';
import { Filter } from 'components/Filter';
import { IStoreState, TReportFilterPlanning, TReportsFilter } from 'services/store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import className from 'classnames';
import {
	fecthGetPlanningData,
	fetchGetReportsAllGoods,
	fetchGetReportsCurrent,
	fetchGetReportsQuality,
} from 'services/store/reports';
import styles from './reports.module.scss';
import { Loading } from 'components/Common/Loading';
import { Export } from 'components/Content/Reports/Export';
import { TypesPlanning } from 'components/Content/Reports/Entities/Planning/Types';
import { ErrorPage } from '../ErrorPage';
import { getDataReportsForExport } from 'services/api/getDataReportsForExport';
import { getContractorDemandPlanForExport } from 'services/api/GetContractorDemandPlanForExport';

const paths = ['allGoods', 'current', 'quality', 'planning'];

enum ReportsType {
	allGoods = '1',
	current = '3',
	quality = '2',
	planning = '1',
}

const Reports: TReportEntity = {
	allGoods: <AllGoodsScreen />,
	quality: <QualityScreen />,
	current: <CurrentScreen />,
	planning: <PlanningScreen />,
};

const text = {
	allGoods: 'Экспорт всех товаров',
	quality: 'Экспорт всех заказов',
	current: 'Экспорт всех товаров',
	planning: 'Экспорт всех товаров',
};

const fetchFuncs = {
	allGoods: fetchGetReportsAllGoods,
	current: fetchGetReportsCurrent,
	quality: fetchGetReportsQuality,
	planning: fecthGetPlanningData,
};

const exportFuncs = {
	allGoods: getDataReportsForExport,
	current: getDataReportsForExport,
	quality: getDataReportsForExport,
	planning: getContractorDemandPlanForExport,
};

export const ReportsPage = () => {
	const { pathname } = useLocation();
	const ContractorId = useSelector((state: IStoreState) => state.user.value?.ContractorId);

	const reportName = pathname.split('/')[2] as keyof TReportEntity;
	const isPlanning = reportName === 'planning';

	const isAvaiblePage = isPlanning ? localStorage.userIsAllowedDemandPlan === 'yes' : paths.includes(reportName);
	const { loading, countPlanning, countAllGoods, countQuality, countCurrent } = useSelector(
		(state: IStoreState) => state.reports
	);
	const { listReportsAllGoods, listReportsCurrent, listReportsQuality, listReportsPlanning } = useSelector(
		(state: IStoreState) => state.filter
	);

	const dispatch = useDispatch();
	const isEmptyResult =
		(reportName === 'planning' && countPlanning === 0) ||
		(reportName === 'current' && countCurrent === 0) ||
		(reportName === 'quality' && countQuality === 0) ||
		(reportName === 'allGoods' && countAllGoods === 0);

	const filters = useMemo(
		() => ({
			allGoods: listReportsAllGoods,
			current: listReportsCurrent,
			quality: listReportsQuality,
			planning: listReportsPlanning,
		}),
		[
			JSON.stringify(listReportsAllGoods),
			JSON.stringify(listReportsCurrent),
			JSON.stringify(listReportsQuality),
			JSON.stringify(listReportsPlanning),
		]
	);

	useEffect(() => {
		if (!paths.includes(reportName)) return;

		if (isPlanning) {
			dispatch(
				fetchFuncs[reportName]({
					...filters[reportName],
					ContractorId: localStorage.getItem('userId'),
				} as TReportFilterPlanning)
			);
		} else {
			dispatch(
				fetchFuncs[reportName]({ ...filters[reportName], ReportNumber: ReportsType[reportName] } as TReportsFilter)
			);
		}
	}, [JSON.stringify(filters[reportName])]);

	return isAvaiblePage ? (
		<MainLayout>
			<div className={styles.reports}>
				<div className={styles.reports_header}>
					<span className={styles.reports_sub}>Отчеты / </span>
					<span className={styles.reports_title}>{Titles[reportName]}</span>
				</div>

				<div className={styles.reports_actions}>
					<div className={styles.reports_actions__left}>
						<Settings type={reportName} />

						<Filter type={reportName} />
					</div>

					{isPlanning && <TypesPlanning />}

					<div className={styles.reports_actions__right}>
						<Export
							text={text[reportName]}
							filters={
								isPlanning
									? { ...filters[reportName], ContractorId: localStorage.getItem('userId') || ContractorId }
									: filters[reportName]
							}
							filename={Titles[reportName]}
							onExport={exportFuncs[reportName]}
							disabled={isEmptyResult}
						/>
					</div>
				</div>

				<div
					className={className(styles.reports_table, {
						[styles.reports_table__noResult]: isEmptyResult,
					})}
				>
					{loading ? <Loading /> : Reports[reportName]}
				</div>
			</div>
		</MainLayout>
	) : localStorage.userIsAllowedDemandPlan === 'no' ? (
		<ErrorPage />
	) : null;
};
