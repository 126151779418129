import { Nullable } from 'services/store/interfaces';

enum METHOD {
	GET = 'GET',
	POST = 'POST',
}

/* eslint-disable @typescript-eslint/no-explicit-any */
type TRequest = <T>(methodName: string, data?: any, token?: Nullable<string>, signal?: AbortSignal) => Promise<T>;

const transport = async (
	method: string,
	methodName: string,
	data: Record<string, unknown> = {},
	token?: string
): Promise<Response> => {
	const cookieToken = token || null;
	const body = {
		CRC: '',
		Packet: {
			MethodName: methodName,
			JWT: token || cookieToken,
			ServiceNumber: process.env.REACT_APP_SERVICE_NUMBER,
			Data: data,
		},
	};

	const path = `${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_PORT}/Json`;

	return await fetch(path, { method, body: JSON.stringify(body) });
};

export const requestFile = {
	get: transport.bind(this, METHOD.GET) as TRequest,
	post: transport.bind(this, METHOD.POST) as TRequest,
};
