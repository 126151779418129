import React, { useEffect } from 'react';
import styles from './orders.module.scss';
import { Actions } from 'components/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { OrdersGroup } from './OrdersGroup';
import { OrderGroup } from './OrderGroup';
import { EditManyGroup } from './EditMany';
import classNames from 'classnames';
import { DivideOrder } from './DivideOrder';
import { useLocation } from 'react-router-dom';
import { removeAllActiveItem } from 'services/store/divideOrders';

export const Orders = () => {
	const current = useSelector((state: IStoreState) => state.orders.current);
	const countOrders = useSelector((state: IStoreState) => state.orders.count);
	const { isGroupingOrders } = useSelector((state: IStoreState) => state.grouping);

	const dispatch = useDispatch();
	const { pathname } = useLocation();

	useEffect(() => {
		dispatch(removeAllActiveItem());
	}, [pathname]);

	const isDefault = current === '0';
	const isManyEdit = current === 'edit';
	const isDivide = current === 'divide';
	const isOneEdit = !['0', 'edit', 'divide'].includes(current);

	const title = isDefault
		? 'Мои заказы'
		: isOneEdit
		? `Заказ № ${current}`
		: isManyEdit
		? 'Мультиредактирование'
		: 'Разделить заказ';

	return (
		<div className={classNames(styles.orders, { [styles.orders__grouping]: isGroupingOrders })}>
			<h1 className={classNames({ [styles.visible]: isManyEdit || isDivide }, styles.header)}>{title}</h1>

			<Actions
				itemsCount={countOrders}
				type={isDefault ? 'orders' : isOneEdit ? 'order' : isManyEdit ? 'multiOrders' : 'divide'}
			/>

			{isDefault && <OrdersGroup />}

			{isOneEdit && <OrderGroup />}

			{isManyEdit && <EditManyGroup />}

			{isDivide && <DivideOrder />}
		</div>
	);
};
